import { Link } from "react-router-dom";
import NoResults from "../../components/NoResults";
import ImportPagesButton from "../../components/ImportPagesButton";
import Header from "../../components/Header";
import $f from "../../components/lib";
import Card from "../../components/Card";
import Table from "../../components/Table";
import { useHistory } from "react-router-dom";
import { fetchSites } from "../../apis/sites";
import { useQuery } from "react-query";
import LoadingOverlayScreen from "../../components/LoadingOverlayScreen";
import { useActions } from "../../hooks/useActions";
import AddNewSiteModal from "../../components/WithModal/AddNewSite";
import VerificationModal from "../../components/WithModal/Verification";
import GenerateScript from "../../components/WithModal/GenerateScript";
import { usePrivileges } from "../../hooks/usePrivileges";

function Sites() {
  const history = useHistory();

  const {
    data: sites,
    isLoading,
    isError,
    refetch,
  } = useQuery(["sites"], fetchSites);

  const { siteActions, isDownloading } = useActions();
  const { canIImportPages, canISeeShareButton } = usePrivileges();

  const createNewSite = () => {
    window["modal"].setState({
      show: true,
      body: <AddNewSiteModal />,
    });
  };

  const getColor = (role) => {
    if (role === "OWNER") {
      return { backgroundColor: "#4F50C7", color: "white" };
    } else if (role === "SITE_MANAGER") {
      return { backgroundColor: "#3090EA", color: "white" };
    } else {
      return { backgroundColor: "#E7E7E7", color: "black" };
    }
  };

  const getLabel = (role) => {
    if (role === "OWNER") {
      return "Owner";
    } else if (role === "SITE_MANAGER") {
      return "Site manager";
    } else {
      return "Site viewer";
    }
  };

  const tableHeaders = [
    {
      label: "Name",
      id: "name",
      th: { className: "ps-0" },
      td: { className: "align-middle ps-0 py-3" },
      type: "link",
      formatValue: (row) => {
        return (
          <div className="d-flex flex-row gap-2">
            <Link to={"/site/".concat(row.projectId)}>{row.name}</Link>
            <div>
              <span
                className="px-2"
                style={{
                  ...getColor(row?.projectUserRole),
                  borderRadius: 15,
                  paddingBottom: 1,
                  paddingTop: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {getLabel(row?.projectUserRole)}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      label: "Domain",
      id: "domain",
      th: { className: "ps-0" },
      td: { className: "align-middle ps-0 py-3" },
      formatValue: (row) => {
        return row.domain;
      },
    },
    {
      label: "Created",
      id: "created",
      th: { className: "ps-0 pe-5" },
      td: { className: "align-middle ps-0 pe-4 py-3" },
      formatValue: (row) => {
        return <span className="d-block">{$f.formatDate(row.created)}</span>;
      },
    },
    {
      label: "Middleware",
      id: "scriptEnabled",
      th: { className: "ps-0" },
      td: { className: "align-middle  ps-0 py-3 " },
      formatValue: (row) => {
        return row.scriptEnabled ? (
          <span>Installed</span>
        ) : (
          <span
            style={{ color: "#4F50C7", cursor: "pointer" }}
            onClick={() => {
              window["modal"].setState({
                show: true,
                body: <GenerateScript siteId={row.projectId} />,
              });
            }}
          >
            Install
          </span>
        );
      },
    },
    {
      label: "",
      id: "actions",
      td: { className: "align-middle text-end ps-5 pe-0 py-2 " },
      formatValue: (row) => {
        return (
          <div className="d-flex justify-content-end gap-2">
            {row?.verified ? (
              <ImportPagesButton
                siteId={row?.projectId}
                canISee={canIImportPages(row)}
              />
            ) : (
              canISeeShareButton(row) && (
                <button
                  type="button"
                  style={{ borderRadius: 10 }}
                  className="btn hoverable-lightgray text-grayblack border bg-white btn-sm"
                  onClick={() => {
                    window["modal"].setState({
                      show: true,
                      body: (
                        <VerificationModal
                          method="REDIRECT"
                          siteId={row.projectId}
                        />
                      ),
                    });
                  }}
                >
                  Verify
                </button>
              )
            )}
            <>
              <button
                type="button"
                style={{ borderRadius: 10 }}
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-2 py-1"
              >
                <i className="fas fa-ellipsis-h"></i>
              </button>
              <ul
                className="dropdown-menu overflow-hidden pt-0 pb-0 mt-3 mb-3 "
                aria-labelledby="dropdownMenuButton1"
              >
                <ul className="nav flex-column ">
                  {siteActions.map(
                    (item) =>
                      item.canISee(row) && (
                        <li
                          key={item.id}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            ...item.style,
                          }}
                          className="nav-item d-block action-links  small dropdown-item"
                          role="button"
                          onClick={() => {
                            item?.onClick({
                              ...item,
                              ...row,
                              action: "REFETCH",
                              refetch,
                            });
                          }}
                        >
                          {item.label}
                        </li>
                      )
                  )}
                </ul>
              </ul>
            </>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {isDownloading && <LoadingOverlayScreen />}
      <Header
        style={{ marginBottom: 30 }}
        title="Sites"
        description="In this page you can view your sites"
        actions={() => (
          <button
            type="button"
            style={{ borderRadius: 10, paddingLeft: 10, paddingRight: 10 }}
            class="btn btn-primary btn-sm"
            onClick={createNewSite}
          >
            New site
          </button>
        )}
      />
      <div className="mt-4">
        <div className="row">
          <div className="col">
            <Card
              style={{
                minHeight: "80vh",
                borderRadius: 15,
              }}
              pagesError
              loading={isLoading}
              error={isError}
              bodyClassName="card-body d-flex flex-column flex-fill h-100"
            >
              {sites?.length > 0 ? (
                <Table headers={tableHeaders} data={sites} />
              ) : (
                <NoResults
                  subtitle={
                    <div className="d-flex align-items-start justify-content-start  flex-column gap-1">
                      <p className="m-0" style={{ fontSize: 18 }}>
                        <strong>There are not any sites yet</strong>
                      </p>
                      <div>Add your sites and import their pages</div>
                      <button
                        type="button"
                        style={{
                          borderRadius: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        class="btn btn-primary btn-sm mt-1"
                        onClick={createNewSite}
                      >
                        New site
                      </button>
                    </div>
                  }
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sites;
