import React, { useState } from "react";
import $f from "../../../components/lib";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import Table from "../../../components/Table";
import NoResults from "../../../components/NoResults";
import {
  fetchMissingTagsAndSummaryReports,
  fetchVersions,
} from "../../../apis/reports";
import { useQuery } from "react-query";

export default function MissingTags() {
  const [version, setVersion] = useState();
  const [allVersions, setAllVersions] = useState([]);
  const { siteId, pageId } = useParams();

  const {
    isLoading: versionsLoading,
    isError: versionsError,
    error: versionsErrorMessage,
  } = useQuery(["versions", { siteId }], fetchVersions, {
    onSuccess: (response) => {
      const current = response?.versions.reduce((a, b) => {
        return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
      });
      setAllVersions(response?.versions);
      setVersion(current?.ingestVersion);
    },
  });

  const {
    data,
    isLoading: tagsLoading,
    isError: tagsError,
    error: tagsErrorMessage,
  } = useQuery(
    ["missingTagsAndSummaryReports", { version, siteId }],
    fetchMissingTagsAndSummaryReports,
    {
      enabled: version !== undefined,
    }
  );

  const tableHeaders = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Added on",
      id: "exist",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        const percentage = (row.exist / row.total) * 100;
        const is100 = percentage === 100;
        return (
          <div className="d-flex justify-content-between">
            {!is100 ? (
              <img src="/images/caution.svg" height="30px"></img>
            ) : (
              <div></div>
            )}
            <div>
              {row?.exist}/{row.total} pages
            </div>
          </div>
        );
      },
    },
    {
      label: "%",
      id: "percentage",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        const percentage = (row.exist / row.total) * 100;
        return <div>{percentage.toFixed()} %</div>;
      },
    },
  ];

  const _totalMissing =
    data?.tagsReports?.reduce?.(
      (accumulator, currentValue) =>
        accumulator + (currentValue.total - currentValue.exist),
      0
    ) ?? 0;

  const showLoader = tagsLoading || versionsLoading;
  const showError = tagsError || versionsError;
  const showErrorMessage = tagsErrorMessage || versionsErrorMessage;

  return (
    <Card
      style={{ minHeight: 500, maxHeight: 500, borderRadius: 15 }}
      title="Meta tag status"
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      subtitle={"Total Missing:" + "  " + _totalMissing}
      errorMessage={showErrorMessage?.error}
      loading={showLoader}
      error={showError}
      actions={() => (
        <div>
          <select
            onChange={(e) => {
              setVersion(e.target.value);
            }}
            value={version}
            className="form-select"
          >
            {allVersions?.map((item) => {
              return (
                <option
                  key={item.ingestVersion}
                  value={item.ingestVersion}
                  className="form-control"
                >
                  {$f.formatDate(item.updatedOn)}
                </option>
              );
            })}
          </select>
        </div>
      )}
    >
      {data?.tagsReports?.length > 0 ? (
        <Table headers={tableHeaders} data={data?.tagsReports ?? []} />
      ) : (
        <NoResults
          title="There are no results yet"
          subtitle="Meta tags status info will appear here"
        />
      )}
    </Card>
  );
}
