import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addNewKeyword,
  deleteKeyword,
  fetchKeywords,
} from "../../../apis/reports";
import Table from "../../../components/Table";
import Card from "../../../components/Card";
import $f from "../../../components/lib";
import { _countriesCodes } from "../../../components/utils";
import NoResults from "../../../components/NoResults";
import ModalDialog from "../../../components/ModalDialog";

export default function Keywords() {
  const [keyword, setKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [action, setAction] = useState(null);
  const { siteId } = useParams();

  const {
    data: keywords,
    error,
    isError,
    isLoading,
  } = useQuery(["keywords", { siteId }], fetchKeywords);

  const queryClient = useQueryClient();

  const actionFn = action === "DELETE" ? deleteKeyword : addNewKeyword;

  const { mutate: submit, status } = useMutation(actionFn, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
    },
    onSuccess: () => {
      $f.createNotification({
        message:
          action === "DELETE"
            ? "Delete was successful"
            : "Create was successful!",
        type: "success",
      });
      closeModal();
      queryClient.invalidateQueries({ queryKey: ["keywords"] });
      return;
    },
  });

  const isSubmitting = status === "loading";

  const closeModal = () => {
    setOpen(false);
    setKeyword("");
    setAction(null);
    setSelectedKeyword(null);
    setConfirmDelete(null);
  };

  const createHandler = () => {
    const payload = [
      {
        keyword,
        projectId: siteId,
      },
    ];

    submit(payload);
  };

  const deleteHandler = () => {
    if (confirmDelete == "delete") {
      submit(selectedKeyword);
    }
  };

  const tableHeaders = [
    {
      label: "Keyword",
      id: "keyword",
    },
    {
      label: "Country",
      id: "country",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        const _country = _countriesCodes?.find(
          (x) =>
            x?.alternateCode?.toLocaleLowerCase() ===
            row?.country?.toLocaleLowerCase()
        );
        return _country?.name ?? "-";
      },
    },

    {
      label: "Actions",
      id: "actions",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        return (
          <div class="d-flex  justify-content-center align-items-center gap-2">
            <button
              onClick={() => {
                setOpen(true);
                setSelectedKeyword(row);
                setAction("DELETE");
              }}
              type="button"
              class="btn btn-danger btn-sm m-2"
            >
              <i class="fas fa-trash fa-fw "></i>
            </button>
            <Link to={`/site/${siteId}/keywords/${row?.id}`}>
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ModalDialog
        open={open}
        title={action === "DELETE" ? "Delete keyword" : "Add keyword"}
        buttons={[
          {
            title: action === "DELETE" ? "Delete keyword" : "Create",
            onClick: action === "DELETE" ? deleteHandler : createHandler,
            className: "btn btn-primary btn-sm me-2",
            loading: isSubmitting,
          },
          {
            title: "Cancel",
            onClick: closeModal,
            className:
              "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
          },
        ]}
      >
        {action === "DELETE" ? (
          <div className="mb-2">
            This action will delete this keyword"
            <strong>{selectedKeyword?.keyword}</strong>"<br></br>
            <br></br>
            <div className="mt-2">
              <small className="text-secondary">
                If you are sure type "delete" below and press "Delete keyword"
              </small>
              <input
                type="text"
                className="form-control mt-2"
                placeholder='If you are sure type "delete" and press "Delete keyword"'
                onChange={(e) => {
                  setConfirmDelete(e.target.value);
                }}
              ></input>
            </div>
          </div>
        ) : (
          <div>
            <label className="form-label me-2">Keyword: </label>
            <input
              className="form-control"
              type="text"
              placeholder="Set a keyword"
              value={keyword}
              name="keyword"
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        )}
      </ModalDialog>
      <Card
        style={{ minHeight: 400, maxHeight: 400, borderRadius: 15 }}
        title="Keyword Competition"
        className="card h-100"
        bodyClassName="card-body d-flex flex-column flex-fill h-100"
        subtitle="Results shown every 4 weeks"
        loading={isLoading}
        error={isError}
        errorMessage={error?.error}
        actions={() => (
          <button
            onClick={() => {
              setOpen(true);
              setAction("CREATE");
            }}
            type="button"
            className="btn btn-primary btn-sm m-2"
          >
            Add keyword
          </button>
        )}
      >
        {keywords?.length > 0 ? (
          <Table headers={tableHeaders} data={keywords} />
        ) : (
          <NoResults title="There are no results yet" />
        )}
      </Card>
    </>
  );
}
