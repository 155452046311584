import React from "react";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import NoResults from "../../../components/NoResults";
import AddNewSiteModal from "../../../components/WithModal/AddNewSite";
import { useQuery } from "react-query";
import { fetchSites } from "../../../apis/sites";

export default function Sites() {
  const { data, isLoading, isError } = useQuery(["sites"], fetchSites);

  const projects = data?.slice(-3);

  const openCreateSiteModal = () => {
    window["modal"].setState({
      show: true,
      body: <AddNewSiteModal />,
    });
  };

  return (
    <Card
      style={{ borderRadius: 15 }}
      loading={isLoading}
      error={isError}
      title="Sites"
      subtitle={"Total sites:".concat(` ${data?.length ?? 0}`)}
      className="card ps-2 pe-2 pt-1 pb-2 h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      actions={() => (
        <div className="d-flex flex-row gap-3 align-items-center">
          <button
            type="button"
            style={{ borderRadius: 15 }}
            className="btn hoverable-lightgray text-grayblack border bg-white btn-sm"
            onClick={openCreateSiteModal}
          >
            Add new
          </button>

          <div className="text-primary">
            <Link className="hoverable-text-primary" to="/sites">
              View all
            </Link>
          </div>
        </div>
      )}
    >
      {data?.length > 0 ? (
        <div className="d-flex flex-fill mt-3">
          <ul className="list-group list-group-flush d-flex flex-fill">
            {projects?.map((item) => {
              return (
                <li className="list-group-item ps-0 py-3">
                  <div className="d-flex flex-row align-items-center">
                    <Link to={`site/${item?.projectId}`}>
                      <div>{item.name}</div>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <NoResults
          title="You don’t have any sites yet"
          subtitle={
            <div className="d-flex align-items-center justify-content-center mt-2">
              <button
                type="button"
                style={{ borderRadius: 10 }}
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-4 py-1"
                onClick={openCreateSiteModal}
              >
                Add new site
              </button>
            </div>
          }
        />
      )}
    </Card>
  );
}
