import React from "react";
import NoResults from "../../../components/NoResults";
import Card from "../../../components/Card";
import { useParams, Link } from "react-router-dom";
import Table from "../../../components/Table";

export default function Queries({
  data,
  error,
  errorMessage,
  loading,
  hasGoogleApiKey,
}) {
  const { siteId, pageId } = useParams();

  const warning = "Google Api key is mandatory";

  const tableHeaders = [
    {
      label: "Query",
      id: "keyword",
    },
    {
      label: "Impressions",
      id: "impressions",
      th: { className: "text-center" },
      td: { className: "text-center" },
    },
    {
      label: "CTR%",
      id: "ctr",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        return row?.ctr?.toFixed(2).concat(" %");
      },
    },
  ];

  const redirectLink = pageId
    ? `/site/${siteId}/${pageId}/reports/queries`
    : window.location.pathname.concat("/queries");

  return (
    <Card
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      style={{ maxHeight: 500, minHeight: 500, borderRadius: 15 }}
      title="Queries"
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      subtitle={"Number of queries:".concat(` ${data?.length ?? 0}`)}
      actions={() => (
        <div className="text-primary">
          {data?.length > 0 ? (
            <Link className="hoverable-text-primary" to={redirectLink}>
              View all
            </Link>
          ) : null}
        </div>
      )}
    >
      {data?.length > 0 ? (
        <Table headers={tableHeaders} data={data?.slice(0, 10)} />
      ) : (
        <NoResults
          title="There are no results yet"
          subtitle={!hasGoogleApiKey ? warning : "Top Queries will appear here"}
        />
      )}
    </Card>
  );
}
