import React from "react";
import Card from "../../../../components/Card";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function Reporting() {
  const { siteId } = useParams();

  return (
    <Card
      style={{
        minHeight: 180,
        maxHeight: 180,
        borderRadius: 15,
        paddingLeft: 8,
        paddingBottom: 15,
        paddingRight: 8,
      }}
      title="Reporting"
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100 ps-1 pe-1 pb-0"
      actions={() => (
        <div className="text-primary">
          <Link
            className="hoverable-text-primary"
            to={`/site/${siteId}/reports`}
          >
            View all
          </Link>
        </div>
      )}
    >
      <div className="mt-4">
        <div style={{ color: "#5E5E5E", lineHeight: 1.2 }}>
          Check your meta tag status, visits, keyword competition and more
          {/* <br /> */}
        </div>
      </div>
    </Card>
  );
}
