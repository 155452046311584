import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { routes } from "../routes";
import ModalWidget from "./modal";
import AseoRoute from "./AseoRoute";

function Index() {
  return (
    <>
      <Switch>
        {routes.map((item) => {
          return <AseoRoute key={item.name} {...item} />;
        })}
      </Switch>
      <ModalWidget
        ref={(refComponent) => {
          window["modal"] = refComponent;
        }}
      ></ModalWidget>
    </>
  );
}

export default Index;
