import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";

export default function LoadingOverlayScreen({ label }) {
  return (
    <LoadingOverlay
      active={true}
      spinner
      text={label ? label : "Loading..."}
      styles={{
        overlay: (base) => ({
          ...base,
          zIndex: 2000,
          position: "fixed",
        }),
      }}
    />
  );
}
