import { EMAIL, REGISTER_PASS, URL_REGEX } from "./regex";

export const isValidEmail = (email) => {
  return EMAIL.test(email);
};

export const isRegisterPassValid = (pass) => {
  return REGISTER_PASS.test(pass);
};

export const isValidUrl = (url) => {
  return URL_REGEX.test(url);
};
