import React from "react";

export default function Button(props) {
  const canIsee = props.canIsee !== undefined ? props.canIsee : true;
  const type = props.type !== undefined ? props.type : "link border bg-white";
  const btnType = props.btnType !== undefined ? props.btnType : "button";
  const className =
    props?.className !== undefined ? props?.className : "btn-sm m-2";

  return (
    <React.Fragment>
      {canIsee ? (
        <>
          <button
            onClick={props.onClick}
            type={btnType}
            {...props.attributes}
            className={`btn btn-${type} ${className}`}
          >
            {props.icon} {props.title}
          </button>
          {props.render}
        </>
      ) : null}
    </React.Fragment>
  );
}
