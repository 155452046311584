import React, { useState } from "react";
import ModalDialog from "../../ModalDialog";
import $f from "../../lib";
import { useMutation } from "react-query";
import { uploadCSVBulkPages } from "../../../apis/sites";

const EditBulkPages = ({ cb = () => {} }) => {
  const [file, setFile] = useState(null);

  const { mutate: submit, status } = useMutation(uploadCSVBulkPages, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Upload was successful!",
        type: "success",
      });
      window["modal"].setState({ show: false });
      cb();
    },
  });

  const handleImport = (e) => {
    if (e.target.files.length === 0) {
      setFile(null);
    } else {
      setFile(e.target.files);
    }
  };

  function submitFile() {
    submit({ file });
  }

  const isSubmitting = status === "loading";

  return (
    <ModalDialog
      open={true}
      title="Edit bulk pages"
      buttons={[
        {
          title: "Import",
          onClick: submitFile,
          className: "btn btn-primary btn-sm me-2",
          disabled: file === null,
          loading: isSubmitting,
        },
        {
          title: "Cancel",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      <div>
        <label className="form-label me-2">Upload csv file: </label>
        <input
          type="file"
          className="form-control"
          placeholder="Select csv file..."
          onChange={handleImport}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        ></input>
      </div>
    </ModalDialog>
  );
};

export default EditBulkPages;
