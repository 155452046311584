import React, { useEffect, useState } from "react";
import { useHistory, Route, Link } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import $f from "./lib";
import LeftNavigation from "./common/leftNavigation";
import Profile from "./WithModal/Profile";

const options = [
  {
    id: "PROFILE",
    label: "Profile",
    onClick: () => {
      window["modal"].setState({
        show: true,
        body: <Profile />,
      });
    },
  },
  {
    id: "LOGOUT",
    label: "Logout",
    onClick: () => {
      $f.logout();
    },
  },
];
const WrapperTemplate = ({ children, hasSideBar, authUser }) => {
  return hasSideBar ? (
    <>
      <header>
        <nav
          className="navbar navbar-expand p-2 pe-4 ps-md-4 border-bottom-1 border bg-white "
          draggable="false"
        >
          <div className="viewport-padding">
            <span className="mb-0">
              <Link to="/">
                <img src="/images/purple.png" height="40px" />
              </Link>
            </span>
          </div>

          <div className="nav-item ms-auto ">
            <div
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              role="button"
              className="avatar"
            >
              {authUser?.name?.charAt(0)?.toUpperCase()}
            </div>
            <ul
              className="dropdown-menu position-right overflow-hidden float-right pt-0 pb-0 mt-0 mb-3 "
              aria-labelledby="dropdownMenuButton1"
            >
              <ul className="nav flex-column ">
                {options.map((item) => (
                  <li
                    key={item.id}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      ...item.style,
                    }}
                    className="nav-item hover-purple d-block action-links  small dropdown-item"
                    role="button"
                    onClick={() => {
                      item.onClick({ ...item });
                    }}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </ul>
          </div>
          <div className="display-lt-md-block">
            <span
              className="d-block display-5 strong ms-2"
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <i className="fas fa-bars fa-fw me-1"></i>
            </span>
          </div>
        </nav>
      </header>
      <div className="container-fluid">
        <div className="row flex-wrap">
          <LeftNavigation panel={$f.projectsPanel} />
          <div className="col p-4 min-vh-100">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>{children}</>
  );
};

export default function AseoRoute(props) {
  const [loading, setLoading] = useState(false);
  const { isAuth, authUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (props.beforeEnter) {
      props.beforeEnter({ isAuth, authUser, history });
    }
    setLoading(true);
  }, []);

  const _route = (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );

  if (loading) {
    return isAuth ? (
      <WrapperTemplate
        authUser={authUser}
        hasSideBar={props?.hasSideBar ?? true}
      >
        {_route}
      </WrapperTemplate>
    ) : (
      _route
    );
  } else {
    return "";
  }
}
