import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { activateUser, resendEmail, resendLink } from "../../../apis/auth";
import SuccessRegistration from "./Success";
import FailedRegistration from "./Fail";
import LoadingOverlayScreen from "../../../components/LoadingOverlayScreen";
import $f from "../../../components/lib";
import InboxEmail from "./InboxEmail";

export default function Activate() {
  const [status, setStatus] = useState(null);
  const { token } = useParams();
  const [email, setEmail] = useState("");

  const { mutate: mutateLink, status: resendLinkStatus } = useMutation(
    resendLink,
    {
      throwOnError: true,
      onError: () => {
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      },
      onSuccess: (res) => {
        setStatus("RESEND");
        setEmail(res.email);
      },
    }
  );

  const { mutate: mutateEmail, status: resendEmailStatus } = useMutation(
    resendEmail,
    {
      throwOnError: true,
      onError: () => {
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      },
      onSuccess: () => {
        $f.createNotification({
          message:
            "New link was sent successfully! Please check your email inbox.",
          type: "success",
        });
      },
    }
  );

  const { isLoading } = useQuery(["activation", { token }], activateUser, {
    onSuccess: () => {
      setStatus("SUCCESS");
    },
    onError: (err) => {
      if (err.ERROR_TAG === "USER_IS_ACTIVATED") {
        setStatus("USER_IS_ACTIVATED");
      } else if (err.ERROR_TAG === "NOT_FOUND") {
        setStatus("NOT_FOUND");
      } else if (err.ERROR_TAG === "EXPIRED") {
        setStatus("EXPIRED");
      } else {
        setStatus("GENERAL_ERROR");
      }
    },
  });

  const resendLinkHandler = () => {
    mutateLink({ token });
  };

  const resendEmailHandler = () => {
    mutateEmail({ email });
  };

  if (isLoading) {
    return <LoadingOverlayScreen />;
  }

  if (status === "SUCCESS") {
    return <SuccessRegistration />;
  }

  if (status === "EXPIRED") {
    return (
      <FailedRegistration
        resendStatus={resendLinkStatus}
        type="EXPIRED"
        resendLink={resendLinkHandler}
      />
    );
  }

  if (status === "NOT_FOUND") {
    return <FailedRegistration type="NOT_FOUND" />;
  }

  if (status === "USER_IS_ACTIVATED") {
    return <FailedRegistration type="USER_IS_ACTIVATED" />;
  }

  if (status === "GENERAL_ERROR") {
    return <FailedRegistration type="GENERAL_ERROR" />;
  }

  if (status === "RESEND") {
    return (
      <>
        {resendEmailStatus === "loading" && <LoadingOverlayScreen />}
        <InboxEmail email={email} resendLink={resendEmailHandler} />;
      </>
    );
  }
}
