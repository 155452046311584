import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";

export default function Header(props) {
  return (
    <React.Fragment>
      <div
        className={`d-flex flex-column ${props.className}`}
        style={{ ...props?.style }}
      >
        {props?.navigationLink ? (
          <Link className="my-2" to={props?.navigationLink}>
            <span>
              {"< "} {props?.title}
            </span>
          </Link>
        ) : (
          <div className="d-flex justify-content-between align-items-start flex-wrap gap-2">
            <div>
              <b style={{ fontSize: 28 }}>{props?.title}</b>
              <div>
                <small
                  className={`text-muted  text-medium ${
                    props?.navigationLink ? "my-2" : ""
                  }`}
                >
                  {props?.description}
                </small>
              </div>
            </div>

            <div className="d-flex">{props?.actions?.()}</div>
          </div>
        )}
        {/* {props?.breadcrumbPath && <Breadcrumb path={props?.breadcrumbPath} />} */}
      </div>
    </React.Fragment>
  );
}
