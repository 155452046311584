import React from "react";
import {
  fetchGoogleReportsForPage,
  fetchGoogleReportsForSite,
} from "../../../apis/reports";
import Table from "../../../components/Table";
import Card from "../../../components/Card";
import NoResults from "../../../components/NoResults";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import Header from "../../../components/Header";
import { _countriesCodes } from "../../../components/utils";
import { fetchPageInfo, fetchSite } from "../../../apis/sites";

export default function GoogleReports(props) {
  const { siteId, pageId } = useParams();
  const { type } = props;
  const googlefetch = pageId
    ? fetchGoogleReportsForPage
    : fetchGoogleReportsForSite;

  const {
    data: site,
    isSuccess,
    isLoading: siteLoading,
  } = useQuery(["site", { siteId }], fetchSite);

  const hasGoogleApiKey = isSuccess && site?.googleJsonFileName !== null;

  const {
    data,
    isLoading: googleLoading,
    isError,
    error,
  } = useQuery(
    [pageId ? "googlePageReports" : "googleSiteReports", { siteId, pageId }],
    googlefetch,
    {
      enabled: hasGoogleApiKey,
    }
  );

  const { data: page } = useQuery(["pageInfo", { pageId }], fetchPageInfo, {
    enabled: pageId !== undefined,
  });

  const warning = "Google Api key is mandatory";

  const tableHeadersQueries = [
    {
      label: "Query",
      id: "keyword",
    },
    {
      label: "Impressions",
      id: "impressions",
      th: { className: "text-center" },
      td: { className: "text-center" },
    },
    {
      label: "CTR%",
      id: "ctr",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        return row?.ctr?.toFixed(2).concat(" %");
      },
    },
  ];

  const tableHeadersVisits = [
    {
      label: "Country",
      id: "country",
      th: { className: "text-start" },
      td: { className: "text-start" },
      formatValue: (row) => {
        const _country = _countriesCodes?.find(
          (x) =>
            x?.code?.toLocaleLowerCase() === row?.country?.toLocaleLowerCase()
        );

        return (
          <div className="d-flex">
            <img
              width={35}
              height={35}
              style={{
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={_country?.flag}
            />
            <p className="ms-3">{_country?.name}</p>
          </div>
        );
      },
    },
    {
      label: "Impressions",
      id: "impression",
      th: { className: "text-center" },
      td: { className: "text-center" },
    },
  ];

  const goBackLink = pageId
    ? `/site/${siteId}/page/${pageId}/reports`
    : `/site/${siteId}/reports`;

  const description =
    type === "queries"
      ? "Queries for ".concat(pageId ? page?.pageURL?.slice(-50) : site?.domain)
      : "Visits by Country for ".concat(
          pageId ? page?.pageURL?.slice(-50) : site?.domain
        );

  const googleData = data?.[type] ?? [];
  const isLoading = siteLoading || googleLoading;
  return (
    <>
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={
          <>
            <Link to={goBackLink}>
              <span style={{ color: "#4F50C7" }}>Go to reports </span>
            </Link>
            | {description}
          </>
        }
      />
      <div className="row">
        <div className="col">
          <Card
            loading={isLoading}
            error={isError}
            errorMessage={error?.error}
            style={{ borderRadius: 15, minHeight: 500 }}
            title="Queries"
            className="d-flex card h-100"
            bodyClassName="card-body d-flex flex-column flex-fill h-100"
            subtitle={"Number of queries:".concat(
              ` ${googleData?.length ?? 0}`
            )}
          >
            {googleData.length > 0 ? (
              <Table
                headers={
                  type === "queries" ? tableHeadersQueries : tableHeadersVisits
                }
                data={googleData}
              />
            ) : (
              <NoResults
                title="There are no results yet"
                subtitle={
                  !hasGoogleApiKey ? warning : "Top Queries will appear here"
                }
              />
            )}
          </Card>
        </div>
      </div>
    </>
  );
}
