import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import Card from "../../../components/Card";
import { useForm } from "react-hook-form";
import { isValidEmail } from "../../../const/utils";

export default function Login() {
  const [tooglePass, setTooglePass] = useState(false);
  const { register, formState, getValues, watch } = useForm({
    mode: "all",
  });

  const { onLogin } = useAuth();

  const handleLogin = (e) => {
    e.preventDefault();
    onLogin({ username: getValues("email"), password: getValues("password") });
  };

  const canISeePassEye =
    watch("password") !== undefined && watch("password")?.trim() !== "";

  useEffect(() => {
    if (!canISeePassEye) {
      setTooglePass(false);
    }
  }, [canISeePassEye]);

  return (
    <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100 my-2 ">
      <Card style={{ minWidth: 400, maxWidth: 400, borderRadius: 10 }}>
        <div className="h-75 d-flex align-items-center justify-content-center ">
          <div className="d-flex px-3 flex-fill flex-column">
            <div className="d-flex  align-items-center justify-content-center ">
              <img src="/images/purple.png" height={80} width={80} />
            </div>
            <div className="p-3 text-center">
              <h4 className="fw-bold mb-0">Login to your account</h4>
              <small className="text-muted text-medium">
                Type your email and password
              </small>
            </div>

            <form className="d-flex flex-column mt-4" onSubmit={handleLogin}>
              <div className="mb-4">
                <label className="form-label me-2">Email </label>
                <input
                  {...register("email", {
                    required: "Email is required",
                    validate: (value) => isValidEmail(value) || "Wrong email",
                  })}
                  placeholder="Type your email"
                  className={"form-control  form-control-sm".concat(
                    formState.errors["email"] ? " is-invalid" : ""
                  )}
                ></input>
                <small className="error text-danger">
                  {formState?.errors?.email?.message}
                </small>
              </div>
              <div className="mb-2 ">
                <label className="form-label me-2">Password </label>
                <div style={{ position: "relative" }}>
                  <input
                    {...register("password", {
                      required: "Password is required",
                    })}
                    type={tooglePass ? "text" : "password"}
                    placeholder="Type your password"
                    className={"form-control  form-control-sm"
                      .concat(formState.errors["password"] ? " is-invalid" : "")
                      .concat(canISeePassEye ? " padding-right-eye" : "")}
                  />
                  {canISeePassEye && (
                    <div
                      className="eye-icon"
                      onClick={() => {
                        setTooglePass(!tooglePass);
                      }}
                    >
                      {tooglePass ? (
                        <i className="fas fa-eye-slash" />
                      ) : (
                        <i className="fas fa-eye" />
                      )}
                    </div>
                  )}
                </div>
                <small className="error text-danger">
                  {formState?.errors?.password?.message}
                </small>
              </div>
              <button
                className="btn btn-primary btn-sm mt-2"
                style={{ borderRadius: 10, paddingTop: 5, paddingBottom: 5 }}
              >
                Login
              </button>
            </form>
            <div className="p-3 d-flex flex-column text-center text-medium">
              <small className="text-muted">New to ASEO?</small>
              <small style={{ color: "#4F50C7" }}>
                <Link to="/register">Create an account</Link>
              </small>
            </div>

            <div className="text-muted d-flex  pt-5 flex-fill flex-row justify-content-center text-medium ">
              <small>
                © 2023 ASEO, Inc. All rights reserved. |{" "}
                <span style={{ color: "#4F50C7" }}>
                  <a target="_blank" href="/policy.html">
                    Privacy
                  </a>
                </span>
              </small>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
