import React from "react";
import ModalDialog from "../../ModalDialog";
import { useMutation, useQuery } from "react-query";
import { fetchPageInfo, updatePage } from "../../../apis/sites";
import { useForm } from "react-hook-form";
import {
  customHtmlFieds,
  twitterFields,
  basicMetaFields,
  ogFields,
} from "./utils";
import $f from "../../lib";
const _ = require("lodash");

export default function EditPageModal({ open, closeModal, pageId }) {
  const { register, formState, setValue, getValues } = useForm({ mode: "all" });

  const { isLoading, isFetching } = useQuery(
    ["pageInfo", { pageId }],
    fetchPageInfo,
    {
      enabled: open,
      onSuccess: (data) => {
        const payload = {
          pageAttributes: {
            metaTitle: data?.pageAttributes?.metaTitle ?? "",
            metaDescription: data?.pageAttributes?.metaDescription ?? "",
            metaKeywords: data?.pageAttributes?.metaKeywords ?? "",
            metaRobots: data?.pageAttributes?.metaRobots ?? "",
            canonical: data?.pageAttributes?.canonical ?? "",
          },
          ogTagsPageAttributes: {
            ogTitle: data?.ogTagsPageAttributes?.ogTitle ?? "",
            ogDescription: data?.ogTagsPageAttributes?.ogDescription ?? "",
            ogImage: data?.ogTagsPageAttributes?.ogImage ?? "",
            ogSiteName: data?.ogTagsPageAttributes?.ogSiteName ?? "",
            ogType: data?.ogTagsPageAttributes?.ogType ?? "",
            ogUrl: data?.ogTagsPageAttributes?.ogUrl ?? "",
          },
          twitterPageAttributes: {
            twitterTitle: data?.twitterPageAttributes?.twitterTitle ?? "",
            twitterDescription:
              data?.twitterPageAttributes?.twitterDescription ?? "",
            twitterSite: data?.twitterPageAttributes?.twitterSite ?? "",
            twitterImage: data?.twitterPageAttributes?.twitterImage ?? "",
            twitterCard: data?.twitterPageAttributes?.twitterCard ?? "",
          },
          customHeadHTMLCode: data?.customHeadHTMLCode ?? "",
        };

        Object.keys(payload).map((item) => {
          setValue(item, payload[item], { shouldValidate: true });
        });
      },
    }
  );

  const { mutate: submit, status } = useMutation(updatePage, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Update was successful!",
        type: "success",
      });
      closeModal();
      return;
    },
  });

  const update = () => {
    const payload = {
      id: pageId,
      ...getValues(),
    };

    submit(payload);
  };

  const isSubmitting = status === "loading";

  const itemField = (item) => {
    const { label, name, validations, className, ...rest } = item;
    const hasError = _.get(formState.errors, name) !== undefined;
    const _className = className.concat(hasError ? " is-invalid" : "");

    return (
      <div key={name} className="mb-2 ">
        <label className="form-label me-2">{label} </label>
        <item.type
          className={_className}
          {...register(name, validations)}
          {...rest}
        />
      </div>
    );
  };

  const basicTabHasError =
    _.get(formState.errors, "pageAttributes") !== undefined;

  const ogTabHasError =
    _.get(formState.errors, "ogTagsPageAttributes") !== undefined;

  const twitterTabHasError =
    _.get(formState.errors, "twitterPageAttributes") !== undefined;

  const htmlTabHasError =
    _.get(formState.errors, "customHeadHTMLCode") !== undefined;

  const navTagWarningStyle = (hasError) => {
    return hasError
      ? {
          borderBottom: "solid",
          borderColor: "red",
          borderWidth: 2,
        }
      : {};
  };

  return (
    <ModalDialog
      loading={isLoading || isFetching}
      title="Edit Page"
      open={open}
      size="lg"
      buttons={[
        {
          title: "Update",
          onClick: update,
          className: "btn btn-primary btn-sm me-2",
          loading: isSubmitting,
        },
        {
          title: "Cancel",
          onClick: closeModal,
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      <div>
        <nav className="mt-0 pt-0 p-3">
          <div className="nav nav-tabs">
            <span
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#nav-basic"
              role="button"
              style={{
                ...navTagWarningStyle(basicTabHasError),
              }}
            >
              Basic Metadata
            </span>
            <span
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-og"
              role="button"
              style={{
                ...navTagWarningStyle(ogTabHasError),
              }}
            >
              OG Metadata
            </span>
            <span
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-twitter"
              role="button"
              style={{
                ...navTagWarningStyle(twitterTabHasError),
              }}
            >
              Twitter Metadata
            </span>
            <span
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-custom"
              role="button"
              style={{
                ...navTagWarningStyle(htmlTabHasError),
              }}
            >
              Custom HTML
            </span>
          </div>
        </nav>
        <div className="tab-content mt-2 p-3" style={{ minHeight: "30rem" }}>
          <div className="tab-pane fade show active" id="nav-basic">
            {basicMetaFields.map((item) => itemField(item))}
          </div>
          <div className="tab-pane fade" id="nav-og">
            {ogFields.map((item) => itemField(item))}
          </div>
          <div className="tab-pane fade" id="nav-twitter">
            {twitterFields.map((item) => itemField(item))}
          </div>
          <div className="tab-pane fade" id="nav-custom">
            {customHtmlFieds.map((item) => itemField(item))}
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
