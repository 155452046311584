import React, { useState } from "react";
import $f from "../../../components/lib";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import Table from "../../../components/Table";
import NoResults from "../../../components/NoResults";
import { fetchTagsInfo, fetchVersions } from "../../../apis/reports";
import { useQuery } from "react-query";

export default function TagInfo() {
  const [version, setVersion] = useState();
  const [allVersions, setAllVersions] = useState([]);
  const { siteId, pageId } = useParams();

  const {
    isLoading: versionsLoading,
    isError: versionsError,
    error: versionsErrorMessage,
  } = useQuery(["versions", { siteId }], fetchVersions, {
    onSuccess: (response) => {
      const current = response?.versions.reduce((a, b) => {
        return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
      });
      setAllVersions(response?.versions);
      setVersion(current?.ingestVersion);
    },
  });

  const {
    data: tagsInfoData,
    isError: tagsInfoError,
    isLoading: tagsInfoLoading,
    error: tagsInfoErrorMessage,
    isSuccess,
  } = useQuery(["tagsInfo", { version, pageId, siteId }], fetchTagsInfo, {
    enabled: version !== undefined,
  });

  const tagsInfoFormatted = () => {
    if (isSuccess) {
      const report = tagsInfoData[0].report;
      const _formattedReport =
        Object.keys?.(report)?.map?.((item) => {
          const _nameFormatter = item.replace(/([A-Z])/g, " $1");
          const _name =
            _nameFormatter.charAt(0).toUpperCase() + _nameFormatter.slice(1);

          return {
            tag: _name,
            tagId: item,
          };
        }) ?? [];

      return _formattedReport;
    } else {
      return [];
    }
  };

  const headers = [
    {
      label: "Tag",
      id: "tag",
    },
    {
      label: "More",
      id: "more",
      th: { className: "text-center" },
      td: { className: "text-center" },
      formatValue: (row) => {
        return (
          <Link
            to={{
              pathname: `/site/${siteId}/page/${pageId}/tag/${row?.tagId}/${version}`,
            }}
          >
            <i className="fas fa-arrow-right"></i>
          </Link>
        );
      },
    },
  ];

  const showLoader = tagsInfoLoading || versionsLoading;
  const showError = tagsInfoError || versionsError;
  const showErrorMessage = tagsInfoErrorMessage || versionsErrorMessage;

  return (
    <Card
      style={{ minHeight: 500, maxHeight: 500, borderRadius: 15 }}
      errorMessage={showErrorMessage?.error}
      loading={showLoader}
      error={showError}
      title="Meta tag info"
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      actions={() => (
        <div>
          <select
            onChange={(e) => {
              setVersion(e.target.value);
            }}
            className="form-select"
            value={version}
          >
            {allVersions?.map((item) => {
              return (
                <option
                  key={item.ingestVersion}
                  value={item.ingestVersion}
                  className="form-control"
                >
                  {$f.formatDate(item.updatedOn)}
                </option>
              );
            })}
          </select>
        </div>
      )}
    >
      {tagsInfoFormatted().length > 0 ? (
        <Table headers={headers} data={tagsInfoFormatted()} />
      ) : (
        <NoResults title="There are no results yet" />
      )}
    </Card>
  );
}
