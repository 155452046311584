import React from "react";
import ModalDialog from "../../ModalDialog";
import { useMutation } from "react-query";
import $f from "../../lib";
import { reIndex } from "../../../apis/sites";

export default function ReIndex({ pageId }) {
  const { mutate: submit, status } = useMutation(reIndex, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Reindex sent successfully!",
        type: "success",
      });
      window["modal"].setState({ show: false });
    },
  });

  const reindexHandler = () => {
    submit({ pageId });
  };
  const isSubmitting = status === "loading";

  return (
    <ModalDialog
      open={true}
      title="Ingest project"
      buttons={[
        {
          title: "Reindex",
          onClick: reindexHandler,
          className: "btn btn-primary btn-sm me-2",
          loading: isSubmitting,
        },
        {
          title: "Cancel",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      <div className="p-3">
        This action will trigger Google reindex of the page.<br></br>
      </div>
    </ModalDialog>
  );
}
