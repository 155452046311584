import React from "react";
import ModalDialog from "../../ModalDialog";
import { deleteSite } from "../../../apis/sites";
import { useMutation } from "react-query";
import $f from "../../lib";
import { useHistory } from "react-router-dom";

export default function DeleteModal({
  action = "REDIRECT",
  siteId,
  name,
  refetch,
}) {
  const history = useHistory();
  const { mutate, status } = useMutation(deleteSite, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Delete was successful!",
        type: "success",
      });
      window["modal"].setState({ show: false });

      if (action === "REFETCH") {
        refetch();
      } else {
        history.push("/sites");
      }
    },
  });

  const onDelete = () => {
    mutate({ siteId });
  };

  const isSubmitting = status === "loading";

  return (
    <ModalDialog
      open={true}
      title="Delete Site?"
      buttons={[
        {
          title: "Cancel",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm me-2",
        },
        {
          title: "Delete",
          onClick: onDelete,
          className: "btn btn-danger btn-sm",
          loading: isSubmitting,
        },
      ]}
    >
      <div className="p-2">
        By deleting the site "<strong>{name}</strong>" all data, including
        reporting, imported pages and meta tags will be deleted permanently.
      </div>
    </ModalDialog>
  );
}
