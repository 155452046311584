import React, { useState } from "react";
import ModalDialog from "../../ModalDialog";
import { useMutation, useQuery } from "react-query";
import $f from "../../lib";
import Table from "../../Table";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { isValidEmail } from "../../../const/utils";
import {
  fetchSiteMembers,
  resendInvite,
  share,
  updateMember,
} from "../../../apis/sites";
import { useAuth } from "../../../providers/AuthProvider";
import LoadingOverlayScreen from "../../LoadingOverlayScreen";
import Button from "../../Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const formatRoleLabel = (role) => {
  if (role === "OWNER") {
    return "Owner";
  } else if (role === "SITE_VIEWER") {
    return "Site viewer";
  } else {
    return "Site manager";
  }
};

const getColor = (role, status) => {
  if (status === "INVITED") {
    return "#E7E7E7";
  } else {
    if (role === "OWNER") {
      return "#4F50C7";
    }
    return "#5E5E5E";
  }
};

export default function Invitation({ siteName, siteId }) {
  const [invalidTags, setInvalidTags] = useState([]);
  const [selectedInvitationRole, setSelectedInvitationRole] =
    useState("SITE_VIEWER");
  const [tags, setTags] = useState([]);
  const { authUser } = useAuth();

  const {
    data: members,
    isLoading,
    isError,
    refetch,
  } = useQuery(["members", { siteId: siteId }], fetchSiteMembers);

  const { mutate: shareMutate, status: shareStatus } = useMutation(share, {
    throwOnError: true,
    onError: (err) => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
    },
    onSuccess: () => {
      setTags([]);
      refetch();
    },
  });

  const { mutate: resendInviteMutate, status: resendInviteStatus } =
    useMutation(resendInvite, {
      throwOnError: true,
      onError: (err) => {
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      },
      onSuccess: () => {
        $f.createNotification({
          message: "Invitation resent successfully",
          type: "success",
        });
      },
    });

  const { mutate: editMember, status: editMemberStatus } = useMutation(
    updateMember,
    {
      throwOnError: true,
      onError: (err) => {
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      },
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleChange = (_tags) => {
    const emailsThatExist = members?.map((item) => item.email) ?? [];
    const invalidTags = _tags.filter(
      (x) => !isValidEmail(x) || emailsThatExist.includes(x)
    );
    setTags(_tags);
    setInvalidTags(invalidTags);
  };

  const roles = [
    {
      id: "SITE_MANAGER",
      label: "Site manager",
      canISee: () => true,
      onClick: (row) => {
        if (row.type === "NEW") {
          setSelectedInvitationRole("SITE_MANAGER");
        } else {
          if (row.role !== "SITE_MANAGER") {
            const payload = {
              emails: [row.email],
              projectId: siteId,
              role: "SITE_MANAGER",
            };
            setSelectedInvitationRole("SITE_MANAGER");
            editMember(payload);
          }
        }
      },
    },
    {
      id: "SITE_VIEWER",
      label: "Site viewer",
      canISee: () => true,
      onClick: (row) => {
        if (row.type === "NEW") {
          setSelectedInvitationRole("SITE_VIEWER");
        } else {
          if (row.role !== "SITE_VIEWER") {
            const payload = {
              emails: [row.email],
              projectId: siteId,
              role: "SITE_VIEWER",
            };
            setSelectedInvitationRole("SITE_VIEWER");
            editMember(payload);
          }
        }
      },
    },
  ];

  const options = [
    ...roles,
    {
      id: "RESEND_INVITE",
      label: "Resend invite",
      canISee: (row) => {
        return row.status === "INVITED";
      },
      onClick: (row) => {
        const payload = {
          emails: [row.email],
          projectId: siteId,
        };
        resendInviteMutate(payload);
      },
    },
    {
      id: "REMOVE_SITE",
      label: "Remove from site",
      style: { color: "red" },
      canISee: () => true,
      onClick: (row) => {
        const payload = {
          emails: [row.email],
          projectId: siteId,
          role: null,
        };
        editMember(payload);
      },
    },
  ];

  const tableHeaders = [
    {
      label: "Members",
      id: "name",
      th: {
        className: "ps-0 ",
        style: {
          fontWeight: "normal",
          color: "#5E5E5E",
        },
      },
      td: { className: "align-middle ps-0 py-0" },
      formatValue: (row) => {
        const { name, surname, email, role, status, id } = row;
        const extra = authUser?.userId === id ? " (you)" : "";
        const text = name ? name + " " + surname : email;
        const member = text + extra;
        const initials = $f.getInitials({ name, surname });

        return (
          <div className="member-wrapper">
            <div
              className="initials-avatar"
              style={{
                backgroundColor: getColor(role, status),
              }}
            >
              {initials}
            </div>
            <div className={status === "INVITED" ? "text-grayblack" : ""}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    <div>{email}</div>
                  </Tooltip>
                )}
              >
                <span>{member}</span>
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      label: "",
      id: "role",
      th: {
        className: "text-end",
      },
      td: { className: "text-end ps-0 py-2" },
      formatValue: (row) => {
        const { role, status } = row;
        const attrs =
          role !== "OWNER"
            ? {
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false",
              }
            : {};
        const _className = role === "OWNER" ? "" : "cursor-pointer";

        return (
          <>
            <span className={_className} id="dropdownMenuButton1 " {...attrs}>
              {status === "INVITED" && (
                <span className="me-2 invited-badge">Invited</span>
              )}
              {formatRoleLabel(role)}
              {role !== "OWNER" && (
                <img
                  className="ms-1"
                  src="/images/downArrow.png"
                  style={{ width: 15, height: 15 }}
                />
              )}
            </span>
            {role !== "OWNER" && (
              <ul
                className="dropdown-menu overflow-hidden position-fixed pt-0 pb-0 mt-3 mb-3 "
                aria-labelledby="dropdownMenuButton1"
              >
                <ul className="nav flex-column ">
                  {options.map(
                    (item) =>
                      item.canISee(row) && (
                        <li
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            ...item.style,
                            ...(row.role === item.id && {
                              color: "#4F50C7",
                              fontWeight: "bold",
                              cursor: "not-allowed",
                            }),
                          }}
                          className="nav-item d-block action-links  small dropdown-item"
                          role="button"
                          onClick={() => {
                            item?.onClick({ ...item, ...row, type: "EDIT" });
                          }}
                        >
                          {item.label}
                        </li>
                      )
                  )}
                </ul>
              </ul>
            )}
          </>
        );
      },
    },
  ];

  const defaultRenderTag = (props) => {
    let { tag, key, disabled, onRemove, getTagDisplayValue } = props;
    const emailsThatExist = members?.map((item) => item.email) ?? [];

    const tagIsValid = isValidEmail(tag) && !emailsThatExist.includes(tag);

    const className = tagIsValid
      ? "react-tagsinput-tag"
      : "react-tagsinput-tag-invalid";

    const classNameRemove = tagIsValid
      ? "react-tagsinput-remove"
      : "react-tagsinput-remove-invalid";

    return (
      <span className={className} key={key}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        )}
      </span>
    );
  };

  const defaultRenderInput = (props) => {
    let { onChange, value, addTag, ...other } = props;
    return (
      <input
        type="text"
        style={{ display: "flex", flex: 1, color: "#000000" }}
        onChange={onChange}
        value={value}
        {...other}
      />
    );
  };

  const defaultRenderLayout = (tagElements, inputElement) => {
    return (
      <div className="render-tag-layout-container">
        {tagElements}
        {inputElement}
      </div>
    );
  };
  const invitationRole = roles.find((x) => x.id === selectedInvitationRole);
  const isLoadingMutation =
    editMemberStatus === "loading" || resendInviteStatus === "loading";

  return (
    <ModalDialog
      onHide={() => {
        window["modal"].setState({ show: false });
      }}
      closeButton={true}
      dialogClassName="medium-modal"
      containerStyle={{ paddingLeft: 20, paddingRight: 20 }}
      open={true}
      title={"Share ".concat(siteName)}
    >
      {isLoadingMutation && <LoadingOverlayScreen />}
      <div style={{ color: "#5E5E5E" }}>
        <div>
          <label className="text-grayblack mb-1">Invite with email</label>
          <div className="d-flex flex-row gap-2 align-items-center">
            <div className="invitation-tags-container text-black">
              <div className="tags-area">
                <TagsInput
                  addKeys={[9, 13, 188]}
                  onlyUnique={true}
                  className="tags-input"
                  value={tags}
                  onChange={handleChange}
                  renderTag={defaultRenderTag}
                  renderInput={defaultRenderInput}
                  renderLayout={defaultRenderLayout}
                  inputProps={{
                    placeholder: "Add emails, comma separated",
                  }}
                />
              </div>
              <div
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="role-selection cursor-pointer"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>{invitationRole?.label}</div>
                  <div>
                    <img
                      className="ms-1"
                      src="/images/downArrow.png"
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </div>
              </div>
              <ul
                className="dropdown-menu position-fixed overflow-hidden pt-0 pb-0 mt-3 mb-3 "
                aria-labelledby="dropdownMenuButton1"
              >
                <ul className="nav flex-column ">
                  {roles.map((item) => (
                    <li
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        ...item.style,
                      }}
                      key={item.id}
                      className="nav-item d-block action-links  small dropdown-item"
                      role="button"
                      onClick={() => {
                        item?.onClick({ ...item, type: "NEW" });
                      }}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
            <div>
              <Button
                loading={shareStatus === "loading"}
                title="Invite"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  const payload = {
                    emails: tags,
                    projectId: siteId,
                    role: selectedInvitationRole,
                  };
                  shareMutate(payload);
                }}
                disabled={
                  invalidTags.length > 0 ||
                  tags.length == 0 ||
                  shareStatus === "loading"
                }
              />
            </div>
          </div>
          <small className="red-color">
            {invalidTags.length > 0 &&
              "Please remove the wrong mails or the mails that already exist"}
          </small>
        </div>
        <div className="mt-4">
          <div className="disable-table-border">
            <Table headers={tableHeaders} data={members ?? []} />
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
