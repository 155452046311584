import React from "react";

export default function NoResults(props) {
  return (
    <div className="d-flex h-100 justify-content-center align-items-center flex-fill">
      <div>
        <p className="m-0 text-center">
          <strong className="text-medium">{props.title}</strong>
        </p>
        <small className="d-flex flex-fill justify-content-center">
          {props.subtitle}
        </small>
      </div>
    </div>
  );
}
