import React from "react";
import ModalDialog from "../../ModalDialog";
import { useHistory } from "react-router-dom";
import { useActions } from "../../../hooks/useActions";
import Button from "../../Button";
import { useMutation } from "react-query";
import { verifySite } from "../../../apis/sites";
import $f from "../../lib";

export default function VerificationModal({ method, siteId, refetch }) {
  const history = useHistory();
  const { downloadVerificationFile, isDownloading } = useActions();

  const { mutate: verify, status } = useMutation(verifySite, {
    throwOnError: true,
    onError: (err) => {
      window["modal"].setState({ show: false });
      $f.createNotification({
        message:
          "We cannot verify the ownership of the site yet. Please make sure you followed the steps correctly.",
        type: "danger",
      });
    },
    onSuccess: (res) => {
      window["modal"].setState({ show: false });

      if (method === "REDIRECT") {
        history.replace(`/import-pages/${siteId}`);
      }

      if (method === "REFETCH_DATA") {
        refetch();
      }
    },
  });

  return (
    <ModalDialog
      open={true}
      size="lg"
      title="Site verification"
      buttons={[
        {
          title: "Cancel",
          loading: status === "loading",
          disabled: status === "loading",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm me-2",
        },
        {
          title: "Verify",
          onClick: () => {
            verify({ siteId });
          },
          className: "btn btn-success btn-sm",
          loading: status === "loading",
        },
      ]}
    >
      <div>Here are the basic steps for site verification:</div>
      <div className="tab-content mt-2">
        <div className="tab-pane fade show active" id="nav-htaccess">
          <div className="alert alert-secondary border" role="alert">
            <div className="mb-2">{`1. Download the following file:`}</div>
            <Button
              loading={isDownloading}
              title={
                <div>
                  <i class="fas fa-download me-2"></i>
                  <span>Download</span>
                </div>
              }
              className="btn btn-primary btn-sm"
              onClick={() => {
                downloadVerificationFile(siteId);
              }}
              disabled={isDownloading}
            />
          </div>
          <div className="alert alert-secondary border" role="alert">
            <div>{`2. Paste this file to your site root folder`}</div>
          </div>
          <div className="alert alert-secondary border" role="alert">
            <div>
              {`3. Click `}
              <b style={{ color: "black" }}>Verify</b>
              {` below`}
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
