import React from "react";
import { useHistory } from "react-router-dom";

export default function ImportPagesButton({ canISee, siteId }) {
  const history = useHistory();
  return canISee ? (
    <button
      type="button"
      style={{ borderRadius: 10 }}
      className="btn hoverable-lightgray text-grayblack border bg-white btn-sm"
      onClick={() => {
        history.push(`/import-pages/${siteId}`);
      }}
    >
      Import pages
    </button>
  ) : null;
}
