import React from "react";
import Card from "../../../../components/Card";
import { Link } from "react-router-dom";

export default function SuccessRegistration() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100  mx-3">
      <Card style={{ borderRadius: 10, marginTop: 15, marginBottom: 15 }}>
        <div>
          <img src="/images/purple.png" height={40} />
        </div>
        <div className="d-flex flex-row flex-wrap">
          <div className="d-flex mx-5">
            <img src="/images/success.png" height={300} width={300} />
          </div>
          <div className="d-flex flex-column justify-content-center px-4 me-5">
            <div>
              <div>
                <b style={{ fontSize: 30, lineHeight: 1.3 }}>
                  Registration succesful!
                </b>
              </div>
              <div
                className="d-flex flex-column mt-2"
                style={{ color: "#5E5E5E", lineHeight: 1.3, fontSize: 14 }}
              >
                <div>You have succesfully registered to ASEO. Click</div>
                <div> the button below to login.</div>
              </div>
              <div>
                <Link
                  style={{
                    borderRadius: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 15,
                  }}
                  to="/login"
                  class="btn btn-primary py-1 mt-4"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
