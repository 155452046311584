import React from "react";
import { useHistory } from "react-router-dom";
import SiteName from "./SiteName";

export default function AddNewSite() {
  const history = useHistory();

  return (
    <div className="d-flex flex-column min-vh-100">
      <header>
        <nav
          className="navbar navbar-expand p-2 pe-4 ps-md-4 border-bottom-1 border bg-white "
          draggable="false"
        >
          <div className="viewport-padding">
            <div className="d-flex flex-row ">
              <span className="mb-0">
                <img src="/images/purple.png" height="44px" />
              </span>
              <div className="d-flex flex-column ms-4">
                <div>
                  <b>Sites</b>
                </div>
                <div>
                  Site name
                  {/* <img src="/images/arrow.png" className="mx-2" height="9px" />
                  Import pages
                  <img src="/images/arrow.png" className="mx-2" height="9px" />
                  Finalize import */}
                </div>
              </div>
            </div>
          </div>
          <div className="nav-item ms-auto ">
            <button
              type="button"
              style={{ borderRadius: 15 }}
              className="btn btn-outline-dark p-1 px-3"
              onClick={() => {
                history.goBack();
              }}
            >
              Exit
            </button>
          </div>
        </nav>
      </header>
      <SiteName />
    </div>
  );
}
