import $f, { jwt } from "../components/lib";

export const fetchSites = async () => {
  return $f.httpApiCall({ url: `/api/projects/0/100` }).then((res) => res.data);
};

export const fetchOwnerSites = async ({ queryKey }) => {
  const [_key] = queryKey;
  return $f
    .httpApiCall({ url: `/api/projects-user-owner` })
    .then((res) => res.data);
};

export const fetchSite = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;
  return $f
    .httpApiCall({ url: `/api/project/${siteId}` })
    .then((res) => res.data);
};

export const checkSitemap = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;
  return $f
    .httpApiCall({ url: `/api/check-sitemap/${siteId}` })
    .then((res) => res);
};

export const verification = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;
  return $f
    .httpApiCall({ url: `/api/verificationFile/${siteId}` })
    .then((res) => res);
};

export const fetchSitePages = async ({ queryKey }) => {
  const [_key, { siteId, page, pageUrl }] = queryKey;

  const hasQueryParam = pageUrl !== undefined && pageUrl !== "";
  const url = hasQueryParam
    ? "/api/page/"
        .concat(siteId)
        .concat("/" + page + "/20")
        .concat("?pageURL=")
        .concat(pageUrl)
    : "/api/page/".concat(siteId).concat("/" + page + "/20");

  return $f
    .httpApiCall({
      url: url,
    })
    .then((res) => res);
};

export const fetchPageInfo = async ({ queryKey }) => {
  const [_key, { pageId }] = queryKey;
  return $f
    .httpApiCall({
      url: "/api/page/".concat(pageId),
    })
    .then((res) => res.data[0]);
};

export const updatePage = async (payload) => {
  return $f
    .httpApiCall({
      url: "/api/page",
      method: "PUT",
      body: JSON.stringify(payload),
    })

    .then((res) => res.data);
};

export const download = async ({ siteId, type }) => {
  const url = {
    GOOGLE_JSON: "/api/project/jsonFile?projectId=".concat(siteId),
    SITE_PAGES: "/api/export/excel/".concat(siteId),
    SITEMAP: "/api/project/sitemap?projectId=".concat(siteId),
    VERIFICATION: "/api/verificationFile/".concat(siteId),
  };

  return $f
    .httpApiCall({
      url: url[type],
      responseType: "blob",
      rawResponse: type === "VERIFICATION" || type === "SITEMAP",
      headers: {
        authorization: jwt(),
      },
    })
    .then((res) => res);
};

export const uploadGoogleJSON = async ({ file, siteId }) => {
  let postData = new FormData();

  postData.set("projectId", siteId);
  postData.set("image ", file[0]);

  return $f
    .httpApiCall({
      url: "/api/project/jsonFile",
      method: "PUT",
      headers: {
        authorization: jwt(),
      },
      body: postData,
    })
    .then((res) => res);
};

export const uploadCSVBulkPages = async ({ file }) => {
  let postData = new FormData();
  postData.set("file ", file[0]);

  return $f
    .httpApiCall({
      url: "/api/import/excel",
      method: "POST",
      headers: {
        authorization: jwt(),
      },
      body: postData,
    })
    .then((res) => res);
};

export const ingest = async ({ siteId }) => {
  return $f
    .httpApiCall({
      url: "/api/project/ingest/".concat(siteId),
      method: "POST",
      body: JSON.stringify([]),
    })
    .then((res) => res);
};

export const deleteSite = async ({ siteId }) => {
  return $f
    .httpApiCall({
      url: "/api/project/".concat(siteId),
      method: "DELETE",
      // body: JSON.stringify([]),
    })
    .then((res) => res);
};

export const reIndex = async ({ pageId }) => {
  return $f
    .httpApiCall({
      url: "/api/google/index/".concat(pageId),
      method: "POST",
      body: JSON.stringify([]),
    })
    .then((res) => res);
};

export const fetchSiteMembers = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;

  return $f
    .httpApiCall({
      url: `/api/project/members/${siteId}`,
    })
    .then((res) => res);
};

export const share = async (payload) => {
  return $f
    .httpApiCall({
      url: "/api/project/share",
      method: "PUT",
      body: JSON.stringify(payload),
    })

    .then((res) => res);
};

export const updateMember = async (payload) => {
  return $f
    .httpApiCall({
      url: "/api/project/member-role",
      method: "PUT",
      body: JSON.stringify(payload),
    })

    .then((res) => res);
};

export const resendInvite = async (payload) => {
  return $f
    .httpApiCall({
      url: "/api/project/share/resend",
      method: "PUT",
      body: JSON.stringify(payload),
    })

    .then((res) => res);
};

export const addNewSite = async (payload) => {
  const url = "/api/project";
  return $f
    .httpApiCall({
      url,
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then((res) => res);
};

export const updateSite = async (payload) => {
  const url = "/api/project";
  return $f
    .httpApiCall({
      url,
      method: "PUT",
      body: JSON.stringify(payload),
    })
    .then((res) => res);
};

export const verifySite = async (payload) => {
  const { siteId } = payload;
  return $f
    .httpApiCall({
      url: `/api/project/verifySite/${siteId}`,
    })
    .then((res) => res);
};

export const finalizeImportPages = async (payload) => {
  const { siteId, ...rest } = payload;
  return $f
    .httpApiCall({
      url: `/api/project/ingest/pages/${siteId}`,
      method: "POST",
      body: JSON.stringify(rest),
      rawResponse: true,
    })
    .then((res) => res);
};
