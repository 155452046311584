import React from "react";
import Card from "../../../../components/Card";
import VerificationModal from "../../../../components/WithModal/Verification";
import { useHistory, useParams } from "react-router-dom";
import UploadGoogleJsonFileModal from "../../../../components/WithModal/UploadGoogleJsonFile";
import GenerateScript from "../../../../components/WithModal/GenerateScript";

export default function Checklist({ data, refetch }) {
  const history = useHistory();
  const { siteId } = useParams();
  const methodText = () => {
    return data?.sitemapMethod ? "Using sitemap method" : "";
  };
  const steps = [
    {
      label: "Verify site",
      completed: data?.verified,
      canISeeButton: !data?.verified,
      canISeeItem: () => {
        return data?.projectUserRole === "OWNER";
      },
      disabledButton: false,
      action: () => {
        window["modal"].setState({
          show: true,
          body: <VerificationModal method="REDIRECT" siteId={siteId} />,
        });
      },
    },
    {
      label: "Import pages",
      info: data?.verified
        ? methodText()
        : "Verify site before importing pages",
      completed: data?.totalPages > 0,
      canISeeButton: data?.totalPages === 0,
      disabledButton: !data?.verified,
      canISeeItem: () => {
        const allowedRoles = ["OWNER", "SITE_MANAGER"];
        return allowedRoles.includes(data?.projectUserRole);
      },
      action: () => {
        history.push(`/import-pages/${siteId}`);
      },
    },
    {
      label: "Install middleware",
      completed: data?.scriptEnabled,
      canISeeButton: !data?.scriptEnabled,
      disabledButton: false,
      canISeeItem: () => {
        const allowedRoles = ["OWNER", "SITE_MANAGER"];
        return allowedRoles.includes(data?.projectUserRole);
      },
      action: () => {
        window["modal"].setState({
          show: true,
          body: <GenerateScript siteId={siteId} />,
        });
      },
    },
    {
      label: "Google API is needed for the reporting",
      completed: data?.googleJsonFileName !== null,
      canISeeButton: data?.googleJsonFileName === null,
      disabledButton: false,
      canISeeItem: () => {
        const allowedRoles = ["OWNER", "SITE_MANAGER"];
        return allowedRoles.includes(data?.projectUserRole);
      },
      action: () => {
        window["modal"].setState({
          show: true,
          body: (
            <UploadGoogleJsonFileModal
              cb={() => {
                refetch();
              }}
              siteId={siteId}
            />
          ),
        });
      },
    },
    // {
    //   label: "Set up billing",
    //   completed: false,
    //   canISeeItem: () => {
    //     const allowedRoles = ["OWNER", "SITE_MANAGER"];
    //     return allowedRoles.includes(data?.projectUserRole);
    //   },
    //   action: () => {
    //     history.push(`/import-pages/${siteId}`);
    //   },
    // },
  ];

  return (
    <Card
      className="card ps-2 pe-2 pt-1 pb-2 h-100"
      style={{ maxHeight: 500, borderRadius: 15 }}
      title="Checklist"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
    >
      <div className="d-flex flex-fill mt-2">
        <ul className="list-group list-group-flush d-flex flex-fill">
          {steps.map((item) => {
            const _classNameIcon = item.completed
              ? "fas fa-check-circle text-success"
              : "fas fa-exclamation-triangle text-warning";

            return (
              item.canISeeItem() && (
                <li className="list-group-item ps-0 pe-0 py-3">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex " style={{ flex: 1 }}>
                      <i className={_classNameIcon} />
                      <span className="ms-2">{item.label}</span>
                      <span className="ms-3 text-grayblack">{item.info}</span>
                    </div>
                    {item.canISeeButton && (
                      <div className="d-flex ">
                        <button
                          type="button"
                          style={{ borderRadius: 15 }}
                          disabled={item.disabledButton}
                          onClick={item.action}
                          className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-2 py-1"
                        >
                          Let's go
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </Card>
  );
}
