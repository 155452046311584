import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { addNewSite } from "../../../apis/sites";
import $f from "../../../components/lib";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button";
import { isValidUrl } from "../../../const/utils";
import VerificationModal from "../../../components/WithModal/Verification";

export default function SiteName() {
  const { register, formState, getValues, watch } = useForm({
    mode: "all",
  });

  const { mutate: submit, status } = useMutation(addNewSite, {
    throwOnError: true,
    onError: (err) => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
    },
    onSuccess: (res) => {
      window["modal"].setState({
        show: true,
        body: (
          <VerificationModal
            method="ADD_NEW_SITE"
            siteId={res.data.projectId}
          />
        ),
      });
    },
  });

  const createHandler = () => {
    window["modal"].setState({
      show: true,
      body: (
        <VerificationModal
          method="ADD_NEW_SITE"
          siteId={"657d60c40c70f30e7d9d0d3d"}
        />
      ),
    });
    // submit({
    //   jobName: getValues("siteName"),
    //   domainName: getValues("url"),
    //   // administratorId: $f.getUserProperty("userId")
    // });
  };

  const hasErrors = !$f.isObjectEmpty(formState.errors);
  const valuesIsEmptyObject = $f.isObjectEmpty(watch());

  const disabledBtn =
    hasErrors ||
    valuesIsEmptyObject ||
    watch("siteName").trim() === "" ||
    watch("url").trim() === "";

  return (
    <div className="d-flex flex-column justify-content-center flex-fill bg-white">
      <div className="d-flex flex-column gap-3">
        <div className="text-center ">
          <b style={{ fontSize: 28 }}>Add new site</b>
          <div className="mt-2 text-medium text text-grayblack">
            Type your site’s name and url
          </div>
        </div>
        <div className="d-flex flex-fill mt-4 flex-row px-5 justify-content-center">
          <div
            className="d-flex flex-fill flex-column px-md-5 mx-md-5"
            style={{ maxWidth: 800 }}
          >
            <div className="mb-4">
              <label className="form-label me-2">Site name </label>
              <input
                {...register("siteName", {
                  required: "Site name is required",
                })}
                placeholder="ASEO"
                className={"form-control  form-control-md".concat(
                  formState.errors["siteName"] ? " is-invalid" : ""
                )}
              />
              <small className="error text-danger">
                {formState?.errors?.siteName?.message}
              </small>
            </div>
            <div className="mb-2 ">
              <label className="form-label me-2">Site's url </label>
              <input
                {...register("url", {
                  required: "Site's url is required",
                  validate: (url) =>
                    isValidUrl(url) || "Please enter a valid url",
                })}
                placeholder="https://aseo.com"
                className={"form-control  form-control-md".concat(
                  formState.errors["url"] ? " is-invalid" : ""
                )}
              />
              <small className="error text-danger">
                {formState?.errors?.url?.message}
              </small>
            </div>
            <div className="d-flex justify-content-end gap-2 mt-3">
              <button
                type="button"
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
                onClick={() => {}}
              >
                Cancel
              </button>
              <Button
                loading={status === "loading"}
                title="Save and Continue"
                className="btn btn-primary btn-sm"
                onClick={createHandler}
                disabled={status === "loading" || disabledBtn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
