import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import Card from "../../../../components/Card";
import $f from "../../../../components/lib";
import { useQuery } from "react-query";
import { fetchKeywordStats } from "../../../../apis/reports";
import { fetchSite } from "../../../../apis/sites";
import Header from "../../../../components/Header";
HighchartsExporting(Highcharts);

export default function KeywordsDetails() {
  const { keywordId, siteId } = useParams();

  const { data, isLoading, isError, error } = useQuery(
    ["keywordStats", { keywordId }],
    fetchKeywordStats
  );

  const { data: site } = useQuery(["site", { siteId }], fetchSite);

  const dates_group =
    data
      ?.map((item) => {
        return {
          executionTime: $f.formatDate(item.executionTime, {
            format: "DD MMM",
          }),
          keywordPositions: item.keywordPositions.slice(0, 7),
          keywordId: item.keywordId,
        };
      })
      ?.reduce((acc, item) => {
        const key = item.executionTime;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key] = [...acc[key], item];
        return acc;
      }, {}) ?? {};

  //format data with unique dates
  const formattedData =
    Object?.keys(dates_group)?.map((item) => dates_group[item][0]) ?? [];

  const _datasetLabels =
    formattedData
      ?.map((item) =>
        item.keywordPositions.map((element) => ({
          date: item.executionTime,
          domain: element.domain,
          position: element.position,
        }))
      )
      ?.flat()
      ?.reduce((acc, item) => {
        const key = item.domain;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key] = [...acc[key], item];
        return acc;
      }, {}) ?? {};

  const options = {
    chart: {
      renderTo: "container",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: formattedData?.map((item) => item.executionTime) ?? [],
    },
    yAxis: {
      title: {
        text: "",
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: "#808080",
        },
      ],
    },

    tooltip: {
      crosshairs: false,
      animation: true,
      shared: true,
      backgroundColor: "#0e0e2d",
      borderColor: "#0e0e2d",
      color: "white",
      style: {
        color: "white",
      },
    },

    plotOptions: {
      line: {
        allowPointSelect: false,
        cursor: "",
        events: {
          legendItemClick: " ",
        },
        showInLegend: true,
      },
    },
    series: Object.keys(_datasetLabels).map((item) => {
      return {
        name: item,
        type: "spline",
        pointPlacement: "on",
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 0,
        },
        data: _datasetLabels[item].map((i) => {
          return [i.date, i.position];
        }),
      };
    }),
  };

  return (
    <>
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={
          <>
            <Link to={`/site/${siteId}/reports`}>
              <span style={{ color: "#4F50C7" }}>Go to reports </span>
            </Link>
            | Keywords Competition
          </>
        }
      />
      <Card
        loading={isLoading}
        error={isError}
        errorMessage={error?.error}
        title="Keyword positioning"
        style={{ borderRadius: 15 }}
      >
        <div className="mt-4">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </Card>
    </>
  );
}
