import React, { useState, useEffect } from "react";
import Button from "../common/button";
import $f from "../lib";
import ModalDialog from "../ModalDialog";
import { fetchSite } from "../../apis/sites";
import { useQuery } from "react-query";

function GenerateScript({ siteId, closeModal }) {
  const { data: project, isLoading } = useQuery(
    ["site", { siteId }],
    fetchSite
  );

  const hide = () => {
    window["modal"].setState({ show: false });
  };

  return (
    <ModalDialog
      open={true}
      title="Project scripts"
      size={"lg"}
      onHide={hide}
      buttons={[
        {
          title: "Cancel",
          onClick: () => {
            hide();
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      {project && (
        <>
          <div className="pb-4">
            Please use one of the below methods to activate your subscription
          </div>
          <nav className="pb-2">
            <div className="nav nav-tabs">
              <span
                className="nav-link active"
                data-bs-toggle="tab"
                data-bs-target="#nav-htaccess"
                role="button"
              >
                .htaccess File
              </span>
              <span
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#nav-php"
                role="button"
              >
                PHP script
              </span>
              <span
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#nav-cloudflare"
                role="button"
              >
                Cloudflare
              </span>
            </div>
          </nav>
          <div className="tab-content mt-2">
            <div className="tab-pane fade show active" id="nav-htaccess">
              <div className="alert alert-warning border" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>This method
                requires that your apache server has the{" "}
                <strong>SSLProxyEngine On</strong> and allow the{" "}
                <strong>P</strong> flag to be executed in the{" "}
                <strong>.htaccess</strong> file
              </div>
              <div className="alert alert-warning border" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>The below
                code excpect that the default page of your website is{" "}
                <strong>index.html</strong> or <strong>index.php</strong> and
                all internal pages are redirected from these pages. If you don't
                know the default page please contact the support team.
              </div>
              <div className="alert alert-warning border" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>In case you
                are using this method in your website that is constructed using
                web frameworks such as Wordpress, Drupal etc., keep in mind that
                you have to ensure that this code exists, anytime you update
                your framework.
              </div>
              <div className="alert alert-info border" role="alert">
                <i className="fas fa-info me-2"></i>Please add the below
                configuration inside your htaccess file as higher as possible.
                Keep in mind that if your htaccess file contain rules that
                redirect the page to the appropriate path (such as non-www to
                www version or http to https version e.x.), then the below
                script should be placed under those rules.{" "}
                <strong>
                  Please ensure that your website is running correctly after you
                  made the changes! It is strongly advised to take a backup of
                  the specific file before you do any changes!
                </strong>
              </div>
              <div className="border p-2">
                <code>
                  &lt;IfModule mod_rewrite.c&gt; <br></br>
                  RewriteEngine On
                  <br></br>
                  &lt;IfModule mod_proxy_http.c&gt;<br></br>
                  RewriteCond %&#123;REQUEST_URI%&#125;{" "}
                  ^(?!.*?(\.js|\.css|\.xml|\.less|\.png|\.jpg|\.jpeg|\.gif|\.pdf|\.doc|\.txt|\.ico|\.rss|\.zip|\.mp3|\.rar|\.exe|\.wmv|\.doc|\.avi|\.ppt|\.mpg|\.mpeg|\.tif|\.wav|\.mov|\.psd|\.ai|\.xls|\.mp4|\.m4a|\.swf|\.dat|\.dmg|\.iso|\.flv|\.m4v|\.torrent|\.ttf|\.woff|\.svg))
                  <br></br>
                  RewriteCond %&#123;HTTP_USER_AGENT&#125;{" "}
                  ".*(googlebot|google|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora|lighthouse).*"
                  [NC] <br></br>
                  RewriteRule ^(index\.html|index\.php)?(.*)
                  https://api.12monkeys.io/public/utils/updatedUID?projectuid=
                  {project.uuid}
                  &pageURL=%&#123;REQUEST_SCHEME&#125;://%&#123;HTTP_HOST&#125;/$2
                  [P,END] <br></br>&lt;/IfModule&gt;<br></br> &lt;/IfModule&gt;
                </code>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-php">
              <div className="alert alert-warning border" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>Be careful
                the below code start and ends with the PHP tags{" "}
                <strong>"&lt;?php ... ?&gt;"</strong> and should be treated
                appropriately. Ask your website developer to add it.
              </div>
              <div className="alert alert-warning border" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>Ensure that
                your php version allows and runs{" "}
                <strong>PHP curl (curl_init)</strong> and the{" "}
                <strong>pathinfo</strong>
              </div>
              <div className="alert alert-warning border" role="alert">
                <i className="fas fa-exclamation-triangle me-2"></i>In case you
                are using this method in your website that is constructed using
                web frameworks such as Wordpress, Drupal etc., keep in mind that
                you have to ensure that this code exists, anytime you update
                your framework.
              </div>
              <div className="alert alert-info border" role="alert">
                <i className="fas fa-info me-2"></i> This method is more
                appropriate for PHP websites (e.x. <strong>Wordpress</strong>,{" "}
                <strong>Drupal</strong>) in case you cannot use the htaccess
                file. Please add the below php code on the top of your index.php
                file.{" "}
                <strong>
                  Please ensure that your website is running correctly after you
                  made the changes! It is strongly advised to take a backup of
                  the specific file before you do any changes!
                </strong>
              </div>
              <div className="border p-2">
                <code>
                  &lt;?php<br></br>
                  $urlApi =
                  "https://api.12monkeys.io/public/utils/updatedUID?projectuid=
                  {project.uuid}&pageURL=".(isset($_SERVER['HTTPS']) &&
                  $_SERVER['HTTPS'] === 'on' ? "https" :
                  "http")."://$_SERVER[HTTP_HOST]$_SERVER[REQUEST_URI]";
                  <br></br>
                  if
                  ((preg_match('/googlebot|google|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora|lighthouse/i',
                  $_SERVER['HTTP_USER_AGENT'])) &&
                  (!preg_match('/\.js|\.css|\.xml|\.less|\.png|\.jpg|\.jpeg|\.gif|\.pdf|\.doc|\.txt|\.ico|\.rss|\.zip|\.mp3|\.rar|\.exe|\.wmv|\.doc|\.avi|\.ppt|\.mpg|\.mpeg|\.tif|\.wav|\.mov|\.psd|\.ai|\.xls|\.mp4|\.m4a|\.swf|\.dat|\.dmg|\.iso|\.flv|\.m4v|\.torrent|\.ttf|\.woff|\.svg/i',pathinfo(parse_url($urlApi,
                  PHP_URL_PATH), PATHINFO_EXTENSION)))) &#123;<br></br>
                  $ch = curl_init();<br></br>
                  curl_setopt($ch, CURLOPT_URL, $urlApi);
                  <br></br>
                  curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
                  <br></br>
                  curl_setopt($ch, CURLOPT_TIMEOUT, 3);<br></br>
                  curl_setopt($ch, CURLOPT_CONNECTTIMEOUT, 100);
                  <br></br>
                  curl_setopt($ch, CURLOPT_HEADER, 0);<br></br>
                  $ch_resp = curl_exec($ch);<br></br>
                  if(curl_getinfo($ch, CURLINFO_HTTP_CODE)=="200")&#123;
                  <br></br>
                  echo $ch_resp;<br></br>
                  curl_close($ch);<br></br>
                  exit();<br></br>
                  &#125;else&#123;<br></br>
                  curl_close($ch);<br></br>
                  &#125;<br></br>
                  &#125;<br></br>
                  ?&gt;
                </code>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalDialog>
  );
}
export default GenerateScript;
