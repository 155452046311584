import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Checklist from "./Checklist";
import PageReport from "./PageReport";
import Summary from "./Summary";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchPageInfo, fetchSite } from "../../../apis/sites";
import EditPageModal from "../../../components/WithModal/EditPage";
import $f from "../../../components/lib";
import ReIndex from "../../../components/WithModal/ReIndex";
import Invitation from "../../../components/WithModal/Invitation";
import { usePrivileges } from "../../../hooks/usePrivileges";

function SitePage() {
  const [open, setOpen] = useState(false);
  const { siteId, pageId } = useParams();

  const {
    data: site,
    isSuccess,
    refetch,
  } = useQuery(["site", { siteId }], fetchSite);

  const hasGoogleApiKey = isSuccess && site?.googleJsonFileName !== null;

  const {
    data: page,
    isError: errorPage,
    error: errorMessagePage,
    isLoading: loadingPage,
  } = useQuery(["pageInfo", { pageId }], fetchPageInfo);
  const { canISeeShareButton, canIEditPage, canIDeletePage } = usePrivileges();

  const closeModal = () => {
    setOpen(false);
  };

  const moreActions = [
    {
      id: "OPEN_UPDATED_PAGE",
      label: "Open updated page",
      canISee: () => {
        return true;
      },
      onClick: () => {
        const newUrl = $f.baseURL(
          "/public/utils/updatedUID?projectuid="
            .concat(siteId)
            .concat("&pageURL=")
            .concat(page.pageURL)
        );
        window.open(newUrl, "_blank");
      },
    },
    {
      id: "GOOGLE_RE_INDEX",
      label: "Google re-index",
      canISee: () => {
        return true;
      },
      onClick: () => {
        window["modal"].setState({
          show: true,
          body: <ReIndex pageId={pageId}></ReIndex>,
        });
      },
    },
    // {
    //   id: "DELETE_PAGE",
    //   label: "Delete page",
    //   style: {
    //     color: "#FF0000",
    //   },
    //   canISee: () => {
    //     return canIDeletePage(site);
    //   },
    //   onClick: () => {},
    // },
  ];

  return (
    <>
      <EditPageModal pageId={pageId} open={open} closeModal={closeModal} />
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={
          <>
            <Link to={`/site/${siteId}/pages`}>
              <span style={{ color: "#4F50C7" }}>Go to all pages</span>
            </Link>{" "}
            | {page?.pageURL?.slice(-50)}
          </>
        }
        actions={() => {
          return (
            <div className="d-flex justify-content-end gap-2">
              {canISeeShareButton(site) && (
                <button
                  type="button"
                  style={{ borderRadius: 10 }}
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    window["modal"].setState({
                      show: true,
                      body: (
                        <Invitation siteId={siteId} siteName={site?.name} />
                      ),
                    });
                  }}
                >
                  <i className="fas fa-user-friends me-2"></i>
                  <span>Share</span>
                </button>
              )}
              {canIEditPage(site) && (
                <button
                  type="button"
                  style={{ borderRadius: 10 }}
                  className="btn hoverable-lightgray text-grayblack border bg-white btn-sm py-1"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Edit Page
                </button>
              )}
              <>
                <button
                  type="button"
                  style={{ borderRadius: 10 }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-3 py-1"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </button>
                <ul
                  className="dropdown-menu overflow-hidden pt-0 pb-0 mt-3 mb-3 "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <ul className="nav flex-column ">
                    {moreActions.map(
                      (item) =>
                        item.canISee() && (
                          <li
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              ...item.style,
                            }}
                            className="nav-item d-block action-links  small dropdown-item"
                            role="button"
                            onClick={item?.onClick}
                          >
                            {item.label}
                          </li>
                        )
                    )}
                  </ul>
                </ul>
              </>
            </div>
          );
        }}
      />
      <div className="mt-4">
        <div className="row g-3">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <Checklist site={site} refetch={refetch} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <PageReport hasGoogleApiKey={hasGoogleApiKey} />
          </div>
          <div className="col-12">
            <Summary
              data={page}
              loading={loadingPage}
              error={errorPage}
              errorMessage={errorMessagePage?.error}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SitePage;
