import React, { useState, useEffect } from "react";
import Button from "../common/button";
import $f from "../lib";
import ModalDialog from "../ModalDialog";
function Ingest({ siteId, closeModal }) {
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState([]);

  useEffect(() => {
    $f.fetch("/api/project/".concat(siteId), "GET", (resp) => {
      setProject(resp.data);
    });
  }, []);

  function ingest() {
    $f.fetch(
      "/api/project/ingest/".concat(project.projectId),
      "POST",
      (resp) => {
        $f.createNotification({
          message: "Ingest sent successfully!",
          type: "success",
        });
      },
      JSON.stringify([])
    );

    window["modal"].setState({ show: false });
  }

  return (
    <ModalDialog
      open={true}
      onHide={closeModal}
      buttons={
        <>
          <div className="mt-2 float-end">
            <Button
              className="btn btn-sm me-2"
              title="Ingest"
              type="primary"
              onClick={ingest}
            />
            <Button
              className="btn btn-sm btn-link  "
              title="Cancel"
              onClick={() => {
                window["modal"].setState({ show: false });
              }}
            />
          </div>
        </>
      }
    >
      <div>
        <div className="p-3">
          This action will:<br></br>- Start crawling all pages included in the
          project "<strong>{project.name}</strong>"<br></br>- Overwrite any
          changes made in the pages<br></br>
        </div>
      </div>
    </ModalDialog>
  );
}

export default Ingest;
