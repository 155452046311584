import React from "react";
import UploadGoogleJsonFileModal from "../components/WithModal/UploadGoogleJsonFile";
import IngestModal from "../components/WithModal/Ingest";
import DeleteModal from "../components/WithModal/DeleteModal";
import { useMutation } from "react-query";
import $f from "../components/lib";
import { download } from "../apis/sites";
import { usePrivileges } from "./usePrivileges";
import { useAuth } from "../providers/AuthProvider";
import Rename from "../components/WithModal/RenameSite";
import EditBulkPages from "../components/WithModal/EditBulkPages";

export const useActions = () => {
  const { mutateAsync: onDonwload, status } = useMutation(download, {
    throwOnError: true,
    onError: (e) => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
    },
  });
  const { authUser } = useAuth();

  const isDownloading = status === "loading";
  const {
    canIDeleteSite,
    canIIngest,
    canIUploadGoogleIndex,
    canISeeGoogleDownloadJson,
    canISeeDownloadSitemap,
    canIExportSitePages,
    canIRenameSite,
    canIEditBulkSitePages,
  } = usePrivileges();

  const downloadVerificationFile = (projectId) => {
    onDonwload({ siteId: projectId, type: "VERIFICATION" })
      .then(async (response) => {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1]
          .split(".");
        const _blob = await response?.blob();
        $f.downloadFile({
          response: _blob,
          title: filename[0],
          extension: filename[1],
        });
      })
      .catch((e) => e);
  };

  const siteActions = [
    {
      id: "RENAME",
      label: "Rename",
      canISee: (args) => {
        return canIRenameSite(args);
      },
      onClick: (row) => {
        window["modal"].setState({
          show: true,
          body: <Rename siteId={row?.projectId} />,
        });
      },
    },
    {
      id: "UPLOAD_GOOGLE_INDEX_SCRIPT",
      label: "Upload google index script",
      canISee: (args) => {
        return canIUploadGoogleIndex(args);
      },
      onClick: (row) => {
        window["modal"].setState({
          show: true,
          body: <UploadGoogleJsonFileModal siteId={row?.projectId} />,
        });
      },
    },
    {
      id: "DOWNLOAD_GOOGLE_JSON",
      label: "Download google json",
      canISee: (args) => {
        return canISeeGoogleDownloadJson(args);
      },
      onClick: (row) => {
        onDonwload({ siteId: row.projectId, type: "GOOGLE_JSON" })
          .then((response) => {
            $f.downloadFile({
              response,
              title: "google-json".concat(row.projectId),
              extension: "json",
            });
          })
          .catch((e) => e);
      },
    },
    {
      id: "INGEST",
      label: "Ingest",
      canISee: (args) => {
        // canIIngest(args);
        return authUser?.admin;
      },
      onClick: (row) => {
        window["modal"].setState({
          show: true,
          body: (
            <IngestModal name={row?.name} siteId={row?.projectId}></IngestModal>
          ),
        });
      },
    },
    {
      id: "EXPORT_SITE_PAGES",
      label: "Export site pages",
      canISee: (args) => {
        return canIExportSitePages(args);
      },
      onClick: (row) => {
        onDonwload({ siteId: row.projectId, type: "SITE_PAGES" })
          .then((response) => {
            $f.downloadFile({
              response,
              title: "site-pages".concat(row.projectId),
              extension: "csv",
            });
          })
          .catch((e) => e);
      },
    },
    {
      id: "EDIT_BULK_PAGES",
      label: "Update bulk pages",
      canISee: (args) => {
        return canIEditBulkSitePages(args);
      },
      onClick: (row) => {
        window["modal"].setState({
          show: true,
          body: <EditBulkPages />,
        });
      },
      // onClick: (row) => {
      //   onDonwload({ siteId: row.projectId, type: "SITE_PAGES" })
      //     .then((response) => {
      //       $f.downloadFile({
      //         response,
      //         title: "site-pages".concat(row.projectId),
      //         extension: "csv",
      //       });
      //     })
      //     .catch((e) => e);
      // },
    },
    {
      id: "DOWNLOAD_SITEMAP",
      label: "Download sitemap",
      canISee: (args) => {
        return canISeeDownloadSitemap(args);
      },
      onClick: (row) => {
        onDonwload({ siteId: row.projectId, type: "SITEMAP" })
          .then(async (response) => {
            const filename = response.headers
              .get("Content-Disposition")
              .split("filename=")[1]
              .split(".");

            const _blob = await response?.blob();

            $f.downloadFile({
              response: _blob,
              title: "sitemap-".concat(row.projectId),
              extension: filename[1],
            });
          })
          .catch((e) => e);
      },
    },
    {
      id: "DELETE_SITE",
      label: "Delete site",
      canISee: (args) => {
        return canIDeleteSite(args);
      },
      style: {
        color: "#FF0000",
      },
      onClick: (row) => {
        window["modal"].setState({
          show: true,
          body: (
            <DeleteModal
              action={row?.action}
              refetch={row?.refetch}
              name={row?.name}
              siteId={row?.projectId}
            ></DeleteModal>
          ),
        });
      },
    },
  ];

  return { siteActions, isDownloading, downloadVerificationFile };
};
