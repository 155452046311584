import React from "react";
import Card from "../../../../components/Card";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchGoogleReportsForPage } from "../../../../apis/reports";
import { useQuery } from "react-query";
import NoResults from "../../../../components/NoResults";

export default function PageReport({ hasGoogleApiKey }) {
  const { siteId, pageId } = useParams();

  const {
    data: googleReports,
    isError,
    error,
    isLoading,
  } = useQuery(
    ["googleReportsForPage", { siteId, pageId }],
    fetchGoogleReportsForPage,
    {
      enabled: hasGoogleApiKey,
    }
  );

  return (
    <Card
      loading={isLoading}
      error={isError}
      errorMessage={error?.error}
      className="card ps-2 pe-2 pt-1 pb-2 h-100"
      style={{ borderRadius: 15, minHeight: 250 }}
      title="Page report"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      actions={() =>
        googleReports && (
          <div className="text-primary">
            <Link
              className="hoverable-text-primary"
              to={`/site/${siteId}/page/${pageId}/reports`}
            >
              View all
            </Link>
          </div>
        )
      }
    >
      {!hasGoogleApiKey ? (
        <NoResults
          title="There are no results yet"
          subtitle="Google Api key is mandatory"
        />
      ) : (
        <div className="d-flex flex-fill mt-2">
          <ul className="list-group list-group-flush d-flex flex-fill">
            <li className="list-group-item ps-0 pe-0 py-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span className="ms-2">
                    {googleReports?.pageClicks} clicks
                  </span>
                </div>
              </div>
            </li>
            <li className="list-group-item ps-0 pe-0 py-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span className="ms-2">
                    {googleReports?.impressions} impressions
                  </span>
                </div>
              </div>
            </li>
            <li className="list-group-item ps-0 pe-0 py-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span className="ms-2">
                    {googleReports?.pageCTR?.toFixed(2)}% CTR
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </Card>
  );
}
