import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Loading from "../common/loading";
import ErrorMessage from "../ErrorMessage";

export default function Card(props) {
  const _className = props?.className ? props.className : "card p-2 h-100";
  const _bodyClassName = props?.bodyClassName
    ? props.bodyClassName
    : "card-body d-flex flex-column";
  const isLoading = props?.loading || false;
  const hasError = props?.error || false;

  const getTemplate = () => {
    if (hasError) {
      return (
        <div className="d-flex h-100 justify-content-center align-items-center flex-fill">
          <div>
            <p className="m-0 text-center text-danger">
              <strong>
                <>{props?.errorMessage ?? <ErrorMessage />}</>
              </strong>
            </p>
          </div>
        </div>
      );
    }
    if (isLoading) {
      return <Loading />;
    }
    return (
      <>
        {props?.body}
        {props?.children}
      </>
    );
  };

  return (
    <div
      className={_className}
      style={{ ...props?.style, borderColor: "#E7E7E7" }}
      onClick={props?.onClick}
    >
      <PerfectScrollbar>
        <div className={_bodyClassName}>
          <div className="">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <b className="text-medium">{props?.title}</b>
                <small className="text-grayblack">{props?.subtitle}</small>
              </div>
              <div className="d-flex justify-content-end align-items-center ">
                {props?.actions?.()}
              </div>
            </div>
          </div>
          {getTemplate()}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
