import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../common/button";
import $f from "../lib";
function DeleteProject(props) {
  const [project, setProject] = useState([]);
  const history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $f.fetch("/api/project/".concat(props.projectId), "GET", (resp) => {
      setProject(resp.data);
    });
  }, []);

  function deleteProject() {
    if (confirmDelete == "delete") {
      setLoading(true);
      $f.fetch(
        "/api/project/".concat(project.projectId),
        "DELETE",
        (resp) => {
          setLoading(false);
          if (resp.data) {
            if (resp.data.id) {
              $f.createNotification({
                message: "Delete was successful!",
                type: "success",
              });
              window["modal"].setState({ show: false });
              props.refreshData ? props.refreshData() : history.push("/");
              return;
            }
          }
          $f.createNotification({
            message: "Something went wrong!",
            type: "danger",
          });
        },
        JSON.stringify([])
      );
    }
  }

  return (
    <div className="p-3">
      <div className="mb-2">
        This action will delete all pages from the project "
        <strong>{project.name}</strong>"<br></br>
        <br></br>
        <strong>Attention!!</strong> This action may alter current search
        results in search engines.
        <div className="mt-2">
          <small className="text-secondary">
            If you are sure type "delete" below and press "Delete project"
          </small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder='If you are sure type "delete" and press "Delete project"'
            onChange={(e) => {
              setConfirmDelete(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className="mt-2 float-end">
        <Button
          className="btn btn-sm me-2"
          title={loading ? "Loading..." : "Delete project"}
          type="primary"
          onClick={deleteProject}
        />
        <Button
          className="btn btn-sm btn-link  "
          title="Cancel"
          onClick={() => {
            window["modal"].setState({ show: false });
          }}
        />
      </div>
    </div>
  );
}

export default DeleteProject;
