import React from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function NotFound404() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100  mx-3">
      <Card style={{ borderRadius: 10, marginTop: 15, marginBottom: 15 }}>
        <div>
          <img src="/images/purple.png" height={40} />
        </div>
        <div className="d-flex flex-row flex-wrap">
          <div className="d-flex mx-5">
            <img src="/images/error.png" height={300} width={300} />
          </div>
          <div className="d-flex flex-column justify-content-center px-4 me-5">
            <div className="">
              <b style={{ fontSize: 55, lineHeight: 1 }}>404</b>
              <div>
                <b style={{ fontSize: 30, lineHeight: 1.3 }}>Page Not Found!</b>
              </div>
              <div
                className="d-flex flex-column mt-2"
                style={{ color: "#5E5E5E", lineHeight: 1.3, fontSize: 14 }}
              >
                <div>We are sorry, the page you requested could</div>
                <div>not be found.Please go back to the </div>
                <div>homepage.</div>
              </div>
              <div>
                <Link
                  to="/"
                  style={{
                    borderRadius: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 15,
                  }}
                  class="btn btn-primary py-1 mt-4"
                >
                  Go to homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
