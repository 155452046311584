import React from "react";
import ModalDialog from "../../ModalDialog";
import { useMutation } from "react-query";
import { ingest } from "../../../apis/sites";
import $f from "../../lib";

export default function IngestModal({ siteId, name }) {
  const { mutate: submit, status } = useMutation(ingest, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Ingest was successful!",
        type: "success",
      });
      window["modal"].setState({ show: false });
    },
  });

  const onIngestHandler = () => {
    submit({ siteId });
  };

  const isSubmitting = status === "loading";

  return (
    <ModalDialog
      open={true}
      title="Ingest project"
      buttons={[
        {
          title: "Ingest",
          onClick: onIngestHandler,
          className: "btn btn-primary btn-sm me-2",
          loading: isSubmitting,
        },
        {
          title: "Cancel",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      <div className="p-2">
        This action will:<br></br>- Start crawling all pages included in the
        project "<strong>{name}</strong>"<br></br>- Overwrite any changes made
        in the pages<br></br>- Every time you perform an ingest action the month
        cost for "<strong>{name}</strong>" project will increase
      </div>
    </ModalDialog>
  );
}
