import React, { Component } from "react";

class Pagination extends Component {
  state = {
    start: 0,
    offset: 25,
    current: 0,
    totalRecords: 0,
    first: "First",
    last: "Last",
    previous: "Previous",
    next: "Next",
    loaded: false,
    replaceAttribute: "page",
  };

  constructor(props) {
    super(props);

    this.clickPage = this.clickPage.bind(this);

    this.state = {
      start: this.props.start ? this.props.start : 0,
      offset: this.props.offset ? this.props.offset : 25,
      current: this.props.current ? this.props.current : 0,
      totalRecords: this.props.totalRecords ? this.props.totalRecords : 0,
      totalPages:
        this.props.totalRecords < this.props.offset
          ? 1
          : Math.ceil(this.props.totalRecords / this.props.offset),
    };
  }

  clickPage(page) {
    this.props.onPageClick(page);
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    let output = [];

    if (this.state.loaded) {
      let pagination = [];
      if (this.state.totalPages > 1) {
        let from = this.state.current - 4 <= 0 ? 1 : this.state.current - 4;
        let to =
          from + 10 < this.state.totalPages ? from + 10 : this.state.totalPages;

        if (this.state.current > this.state.start) {
          pagination.push({ label: "First", value: this.state.start });
          pagination.push({ label: "Previous", value: this.state.current - 1 });
        }

        for (let i = from; i <= to; i++) {
          pagination.push({
            label: i,
            value: i - 1,
            isCurrent: this.state.current == i - 1 ? true : false,
          });
        }

        if (this.state.current < this.state.totalPages - 1) {
          pagination.push({ label: "Next", value: this.state.current + 1 });
          pagination.push({
            label: "Last (" + this.state.totalPages + ")",
            value: this.state.totalPages - 1,
          });
        }
      }

      pagination.map((itm, idx) => {
        let isCurrentClass = "";
        if (itm.isCurrent) {
          isCurrentClass = "page-link-active";
        }

        output.push(
          <li className={"small page-item"}>
            <span
              style={{ borderRadius: 5 }}
              className={
                "btn-link hoverable-lightgray text-grayblack p-2 border m-1 " +
                isCurrentClass
              }
              onClick={(e) => this.clickPage(itm.value)}
              role="button"
            >
              {itm.label}
            </span>
          </li>
        );
      });

      return <ul className="pagination float-end ">{output}</ul>;
    } else {
      return "";
    }
  }
}

export default Pagination;
