import React, { useState, useEffect } from "react";
import Button from "../common/button";
import $f from "../lib";
function ImportPages() {
  const [file, setFile] = useState(null);

  function handleImport(e) {
    setFile(e.target.files);
  }

  function submitFile() {
    $f.importPages(file);
  }

  return (
    <div>
      <div className="mb-2 ">
        <label className="form-label me-2">Upload csv file: </label>
        <input
          type="file"
          className="form-control"
          placeholder="Select csv file..."
          onChange={handleImport}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        ></input>
      </div>
      <div className="mt-2 float-end">
        <Button
          className="btn btn-sm me-2"
          title="Import"
          type="primary"
          onClick={submitFile}
        />
        <Button
          className="btn btn-sm btn-link  "
          title="Cancel"
          onClick={() => {
            window["modal"].setState({ show: false });
          }}
        />
      </div>
    </div>
  );
}

export default ImportPages;
