import React from "react";

export default function ErrorMessage() {
  return (
    <div className="d-flex flex-fill flex-column justify-content-center">
      <div className="d-flex flex-row justify-content-center">
        <div className="d-flex">
          <img src="/images/red-warning.png" height={40} />
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div>
            <b style={{ color: "#000000" }}>Oops! Something went wrong</b>
            <div
              style={{ position: "absolute", color: "#5E5E5E", fontSize: 13 }}
            >
              <b>Refresh the page or try again later</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
