import React from "react";
import ModalDialog from "../../ModalDialog";
import { useHistory } from "react-router-dom";
import Button from "../../Button";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import $f from "../../lib";
import { isValidUrl } from "../../../const/utils";
import { addNewSite } from "../../../apis/sites";

export default function AddNewSiteModal() {
  const history = useHistory();
  const { register, formState, getValues, watch } = useForm({
    mode: "all",
  });

  const { mutate: submit, status } = useMutation(addNewSite, {
    throwOnError: true,
    onError: (err) => {
      window["modal"].setState({ show: false });
      $f.createNotification({
        message: "Something went wrong.Please try again later.",
        type: "danger",
      });
    },
    onSuccess: (res) => {
      window["modal"].setState({ show: false });
      history.push(`/site/${res.data.projectId}`);
    },
  });

  const createHandler = () => {
    const postData = {
      jobName: getValues("siteName"),
      domainName: getValues("url"),
      administratorId: $f.getUserProperty("userId"),
    };

    submit(postData);
  };

  const hasErrors = !$f.isObjectEmpty(formState.errors);
  const valuesIsEmptyObject = $f.isObjectEmpty(watch());

  const disabledBtn =
    hasErrors ||
    valuesIsEmptyObject ||
    watch("siteName").trim() === "" ||
    watch("url").trim() === "";

  return (
    <ModalDialog
      open={true}
      title="Add new site"
      buttons={[
        {
          title: "Cancel",
          loading: status === "loading",
          disabled: status === "loading",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm me-2",
        },
        {
          title: "Save",
          onClick: createHandler,
          className: "btn btn-primary btn-sm",
          loading: status === "loading",
          disabled: status === "loading" || disabledBtn,
        },
      ]}
    >
      <div>
        <div className="mb-4">
          <label className="form-label me-2">Site name </label>
          <input
            {...register("siteName", {
              required: "Site name is required",
            })}
            placeholder="ASEO"
            className={"form-control  form-control-md".concat(
              formState.errors["siteName"] ? " is-invalid" : ""
            )}
          />
          <small className="error text-danger">
            {formState?.errors?.siteName?.message}
          </small>
        </div>
        <div>
          <label className="form-label me-2">Site's url </label>
          <input
            {...register("url", {
              required: "Site's url is required",
              validate: (url) =>
                isValidUrl(url) || "The url's format must be https://...",
            })}
            placeholder="https://aseo.com"
            className={"form-control  form-control-md".concat(
              formState.errors["url"] ? " is-invalid" : ""
            )}
          />
          <small className="error text-danger">
            {formState?.errors?.url?.message}
          </small>
        </div>
      </div>
    </ModalDialog>
  );
}
