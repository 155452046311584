import React from "react";
import { useParams } from "react-router";
import Card from "../../../../components/Card";
import { useQuery } from "react-query";
import { fetchPageInfo, fetchSite } from "../../../../apis/sites";
import { fetchTagsInfo } from "../../../../apis/reports";
import Header from "../../../../components/Header";
import { Link } from "react-router-dom";
import Loading from "../../../../components/common/loading";
import $f from "../../../../components/lib";

export default function TagInfoDetails() {
  const { siteId, pageId, tagId, version } = useParams();

  const { data: site } = useQuery(["site", { siteId }], fetchSite);

  const { data: page } = useQuery(["pageInfo", { pageId }], fetchPageInfo, {
    enabled: pageId !== undefined,
  });

  const { data, isLoading, isError, error } = useQuery(
    ["fetchTagsInfo", { siteId, pageId, version }],
    fetchTagsInfo
  );

  const tagInfoData = data?.[0]?.report?.[tagId];

  const _nameFormatter = tagId.replace(/([A-Z])/g, " $1");
  const _name =
    _nameFormatter.charAt(0).toUpperCase() + _nameFormatter.slice(1);

  const dataIsArray = Array.isArray(tagInfoData);
  const _url = page?.pageURL?.slice(-50);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return "Something went wrong";
  }

  return (
    <>
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={
          <>
            <Link to={`/site/${siteId}/page/${pageId}/reports`}>
              <span style={{ color: "#4F50C7" }}>Go to reports </span>
            </Link>
            | Details for {_name} tag for {$f.removeTrailingSlash(_url)} |{" "}
            {version} version
          </>
        }
      />
      {/* <Header
        navigationLink={`/project/${projectId}/page/${pageId}#nav-reports`}
        title="Back to page reports"
        description="In this page you can see the meta tag info details"
        breadcrumbPath={[
          {
            label: project?.name,
            active: false,
            link: `/project/${project?.projectId}`,
          },
          {
            label: _url?.substring(0, _url.length - 1),
            link: `/project/${projectId}/page/${pageId}`,
            active: false,
          },
          {
            label: _name,
            active: true,
          },
        ]}
      /> */}

      {dataIsArray ? (
        tagInfoData?.map((item, index) => {
          return (
            <div className="accordion mb-3" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id={`panelsStayOpen-${index}`}>
                  <button
                    className={
                      index === 0
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-collapse${index}`}
                    aria-expanded={index === 0 ? "true" : "false"}
                    aria-controls={`panelsStayOpen-collapse${index}`}
                  >
                    {_name}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-collapse${index}`}
                  className={`accordion-collapse collapse ${
                    index === 0 ? "show" : ""
                  }`}
                  aria-labelledby={`panelsStayOpen-${index}`}
                >
                  <div className="accordion-body">
                    <div className="mb-4 mt-2">
                      <label className="form-label me-2">Articles Count</label>
                      <span className="d-block strong">
                        {item?.articlesCount}
                      </span>
                    </div>
                    <div className="mb-4 ">
                      <label className="form-label me-2">
                        Characters Count
                      </label>
                      <span className="d-block strong">
                        {item?.charactersCount}
                      </span>
                    </div>
                    <div className="mb-4 ">
                      <label className="form-label me-2">Words Count</label>
                      <span className="d-block strong">{item?.wordsCount}</span>
                    </div>
                    <div className="mb-4 ">
                      <label className="form-label me-2">Existence</label>
                      <span className="d-block strong">
                        {item?.existence ? "Yes" : "No"}
                      </span>
                    </div>
                    <div className="mb-4 ">
                      <label className="form-label me-2">Content</label>
                      <span className="d-block strong">{item?.content}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Card title={_name}>
          <div className="mb-4 mt-2">
            <label className="form-label me-2">Articles Count</label>
            <span className="d-block strong">{tagInfoData?.articlesCount}</span>
          </div>
          <div className="mb-4 ">
            <label className="form-label me-2">Characters Count</label>
            <span className="d-block strong">
              {tagInfoData?.charactersCount}
            </span>
          </div>
          <div className="mb-4 ">
            <label className="form-label me-2">Words Count</label>
            <span className="d-block strong">{tagInfoData?.wordsCount}</span>
          </div>
          <div className="mb-4 ">
            <label className="form-label me-2">Existence</label>
            <span className="d-block strong">
              {tagInfoData?.existence ? "Yes" : "No"}
            </span>
          </div>
          <div className="mb-4 ">
            <label className="form-label me-2">Content</label>
            <span className="d-block strong">{tagInfoData?.content}</span>
          </div>
        </Card>
      )}
    </>
  );
}
