import React from "react";
import Card from "../../../../components/Card";
import NoResults from "../../../../components/NoResults";
import { useHistory, useParams } from "react-router-dom";

export default function SitePages({ data, error, errorMessage, loading }) {
  const history = useHistory();
  const { siteId } = useParams();
  const totalPages = data?.totalPages || 0;

  const redirectTo = (url) => {
    history.push(url);
  };

  const canISeeImportBtn = () => {
    const allowedRoles = ["OWNER", "SITE_MANAGER"];
    if (allowedRoles.includes(data?.projectUserRole)) {
      return data?.sitemapMethod === null || data?.sitemapMethod === false;
    } else {
      return false;
    }
  };

  return (
    <Card
      error={error}
      loading={loading}
      errorMessage={errorMessage}
      style={{ minHeight: 200, maxHeight: 200, borderRadius: 15 }}
      title="Site pages"
      className="card ps-2 pe-2 pt-1 pb-2 h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
    >
      {totalPages > 0 ? (
        <div className="d-flex h-100 justify-content-center align-items-center flex-fill">
          <div>
            <p className="m-0 text-center">
              <strong>You have imported {totalPages} pages</strong>
            </p>
            <small>
              <div className="d-flex align-items-center justify-content-center gap-2 mt-2">
                {canISeeImportBtn() && (
                  <button
                    type="button"
                    style={{ borderRadius: 10 }}
                    className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-3 py-1"
                    onClick={() => redirectTo(`/import-pages/${siteId}`)}
                  >
                    Import
                  </button>
                )}
                <button
                  type="button"
                  style={{
                    borderRadius: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                  onClick={() => redirectTo(`/site/${siteId}/pages`)}
                  class="btn btn-primary py-1 btn-sm"
                >
                  View all
                </button>
              </div>
            </small>
          </div>
        </div>
      ) : (
        <NoResults
          title="You haven’t imported any pages yet"
          subtitle={
            <div className="d-flex align-items-center justify-content-center mt-2">
              {canISeeImportBtn() && (
                <button
                  type="button"
                  disabled={!data?.verified}
                  onClick={() => {
                    history.push(`/import-pages/${siteId}`);
                  }}
                  style={{ borderRadius: 10 }}
                  className="btn hoverable-lightgray text-grayblack text-grayblack border bg-white btn-sm  px-2 py-1"
                >
                  Import
                </button>
              )}
            </div>
          }
        />
      )}
    </Card>
  );
}
