import React from "react";
import Modal from "react-bootstrap/Modal";
import ErrorMessage from "../ErrorMessage";
import Button from "../Button";
import LoadingOverlayScreen from "../LoadingOverlayScreen";

export default function ModalDialog({
  open,
  title,
  content,
  buttons,
  size,
  containerStyle,
  children,
  onHide,
  loading,
  isError,
  errorMessage,
  centered = true,
  closeButton = false,
  modalNote = undefined,
  dialogClassName = "",
  contentClassName = "",
}) {
  return loading ? (
    <LoadingOverlayScreen />
  ) : (
    <Modal
      show={open}
      centered={centered}
      size={size}
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
      backdrop="static"
    >
      <div style={containerStyle}>
        <Modal.Header onHide={onHide} closeButton={closeButton}>
          <Modal.Title>
            <b>{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: "calc(100vh - 250px)", overflowY: "auto" }}
        >
          {isError ? (
            errorMessage ?? <ErrorMessage />
          ) : (
            <>
              {content}
              {children}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {modalNote && (
            <div className="d-flex flex-row flex-fill">{modalNote}</div>
          )}
          <div className="float-end">
            {buttons?.map((item, index) => {
              return (
                <Button
                  key={index}
                  loading={item.loading}
                  title={item.title}
                  className={item.className}
                  onClick={item.onClick}
                  disabled={item.disabled}
                />
              );
            })}
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
