import React from "react";

export default function LeftNavigation({ panel }) {
  return (
    <div className="col-md-2 bg-white left-nav-width">
      <div className="ms-2">
        <div className="display-lt-md-none">{panel()}</div>
      </div>
      <div className="display-lt-md-block">
        <div
          className="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasLabel">
              Menu
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">{panel()}</div>
        </div>
      </div>
    </div>
  );
}
