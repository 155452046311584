import React, { useState } from "react";
import ModalDialog from "../../ModalDialog";
import { useForm } from "react-hook-form";
import { fetcOwner, fetchUserInfo, updateUser } from "../../../apis/users";
import { useMutation, useQuery } from "react-query";
import $f from "../../lib";
import { useAuth } from "../../../providers/AuthProvider";

export default function Profile() {
  const [email, setEmail] = useState("");
  const { onUpdateUser } = useAuth();
  const { register, formState, setValue, getValues, watch } = useForm({
    mode: "all",
  });

  const { isLoading, isError } = useQuery(
    ["user", { userId: $f.getUserProperty("userId") }],
    fetchUserInfo,
    {
      onSuccess: (res) => {
        setEmail(res?.data?.email ?? "");
        setValue("name", res?.data?.name ?? "");
        setValue("surname", res?.data?.surname ?? "");
      },
    }
  );

  const { mutate: submit, status } = useMutation(updateUser, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: (res) => {
      $f.createNotification({
        message: "Update was successful!",
        type: "success",
      });
      onUpdateUser({ name: res?.data?.name, surname: res?.data?.surname });
      window["modal"].setState({ show: false });
    },
  });

  const hasErrors = !$f.isObjectEmpty(formState.errors);
  const valuesIsEmptyObject = $f.isObjectEmpty(watch());

  const disabledBtn =
    hasErrors ||
    valuesIsEmptyObject ||
    watch("name").trim() === "" ||
    watch("surname").trim() === "";

  const edit = () => {
    submit({
      userId: $f.getUserProperty("userId"),
      name: getValues("name"),
      surname: getValues("surname"),
    });
  };

  return (
    <ModalDialog
      loading={isLoading}
      open={true}
      title="Profile"
      closeButton={true}
      onHide={() => {
        window["modal"].setState({ show: false });
      }}
      modalNote={
        <small style={{ color: "#5E5E5E" }}>
          <b style={{ color: "black" }}>Email: </b>
          {email}
        </small>
      }
      buttons={[
        {
          title: "Save",
          onClick: edit,
          disabled: status == "loading" || disabledBtn,
          className: "btn btn-primary btn-sm me-2",
          loading: status == "loading",
        },
      ]}
    >
      <div className="d-flex flex-row gap-2">
        <div className="d-flex flex-fill flex-column">
          <label className="form-label">First name </label>
          <input
            placeholder="Type your name"
            className={"form-control  form-control-sm".concat(
              formState.errors["name"] ? " is-invalid" : ""
            )}
            {...register("name", {
              required: "First name is required",
            })}
          ></input>
          <small className="error text-danger">
            {formState?.errors?.name?.message}
          </small>
        </div>
        <div className="d-flex flex-fill flex-column">
          <label className="form-label ">Last name </label>
          <input
            {...register("surname", {
              required: "Last name is required",
            })}
            placeholder="Type your surname"
            className={"form-control  form-control-sm".concat(
              formState.errors["surname"] ? " is-invalid" : ""
            )}
          />
          <small className="error text-danger">
            {formState?.errors?.surname?.message}
          </small>
        </div>
      </div>
    </ModalDialog>
  );
}
