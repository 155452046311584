import React from "react";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import VerificationModal from "../../../components/WithModal/Verification";
import { useParams, useHistory } from "react-router-dom";

export default function FailedToAccessImportPages({ type, refetch }) {
  const { siteId } = useParams();
  const history = useHistory();
  const verify = () => {
    window["modal"].setState({
      show: true,
      body: (
        <VerificationModal
          refetch={refetch}
          method="REFETCH_DATA"
          siteId={siteId}
        />
      ),
    });
  };

  const config = {
    UNVERIFIED: {
      title: "Unverified",
      message: (
        <>
          <div>This site has not been verified.</div>
          <div>
            Click the <b>Verify</b> button to start the verification process.
          </div>
        </>
      ),
      img: "/images/exclamation.png",
      buttonTitle: "Verify",
      buttonAction: verify,
    },
    NOT_FOUND: {
      title: "Site not found",
      message: (
        <>
          <div>This site doesn't exist</div>
          <div>
            Please try adding a website before continuing with importing pages.
          </div>
        </>
      ),
      img: "/images/exclamation.png",
      buttonTitle: "Add new site",
      buttonAction: () => {
        history.push("/add-new-site");
      },
    },
    GENERAL_ERROR: {
      title: "Error",
      message: (
        <>
          <div>Something went wrong!</div>
          <div>Please try again later</div>
        </>
      ),
      img: "/images/exclamation.png",
      buttonTitle: "Go back",
      buttonAction: () => {
        history.goBack();
      },
    },
    SITEMAP_METHOD_IS_ENABLED: {
      title: "Sitemap method is enabled",
      message: (
        <>
          <div>You have selected the sitemap method, so you can not</div>
          <div>import pages with another option.</div>
        </>
      ),
      img: "/images/exclamation.png",
      buttonTitle: "Go back",
      buttonAction: () => {
        history.goBack();
      },
    },
    UNAUTHORIZED: {
      title: "Unauthorized",
      message: (
        <>
          <div>
            You don't have the correct role or you have selected the sitemap
            method,
          </div>
          <div>so you can not import pages with another option</div>
        </>
      ),
      img: "/images/exclamation.png",
      buttonTitle: "Go back",
      buttonAction: () => {
        history.goBack();
      },
    },
  };

  return (
    <div className="d-flex align-items-center justify-content-center flex-fill mx-3">
      <Card style={{ borderRadius: 10, marginTop: 15, marginBottom: 15 }}>
        <div>
          <img src="/images/purple.png" height={40} />
        </div>
        <div className="d-flex flex-row flex-wrap gap-4 px-4 py-5">
          <div className="d-flex failed-import-pages-container">
            <img
              src={config[type]["img"]}
              className="failed-registration-image"
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div>
              <div>
                <b className="title">{config[type]["title"]}</b>
              </div>
              <div
                className="d-flex flex-column mt-2"
                style={{ color: "#5E5E5E", lineHeight: 1.3, fontSize: 14 }}
              >
                {config[type]["message"]}
              </div>
              <div>
                <Button
                  onClick={config[type]["buttonAction"]}
                  title={config[type]["buttonTitle"]}
                  className="btn btn-primary btn-sm py-1 mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
