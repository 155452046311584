import React, { useState } from "react";
import ModalDialog from "../../ModalDialog";
import $f from "../../lib";
import { useMutation } from "react-query";
import { uploadGoogleJSON } from "../../../apis/sites";

const UploadGoogleJsonFile = ({ siteId, cb = () => {} }) => {
  const [file, setFile] = useState(null);

  const { mutate: submit, status } = useMutation(uploadGoogleJSON, {
    throwOnError: true,
    onError: () => {
      $f.createNotification({
        message: "Something went wrong!",
        type: "danger",
      });
      window["modal"].setState({ show: false });
    },
    onSuccess: () => {
      $f.createNotification({
        message: "Upload was successful!",
        type: "success",
      });
      window["modal"].setState({ show: false });
      cb();
    },
  });

  const handleImport = (e) => {
    if (e.target.files.length === 0) {
      setFile(null);
    } else {
      setFile(e.target.files);
    }
  };

  function submitFile() {
    submit({ file, siteId });
  }

  const isSubmitting = status === "loading";

  return (
    <ModalDialog
      open={true}
      title="Google index file"
      buttons={[
        {
          title: "Import",
          onClick: submitFile,
          className: "btn btn-primary btn-sm me-2",
          disabled: file === null,
          loading: isSubmitting,
        },
        {
          title: "Cancel",
          onClick: () => {
            window["modal"].setState({ show: false });
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      <div>
        <div className="mb-2 ">
          <label className="form-label me-2">Upload JSON file: </label>
          <input
            type="file"
            className="form-control"
            placeholder="Select csv file..."
            onChange={handleImport}
            filename={file}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ></input>
        </div>
      </div>
    </ModalDialog>
  );
};

export default UploadGoogleJsonFile;
