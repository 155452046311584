import React from "react";

export default function Button({
  title,
  onClick,
  className,
  loading = false,
  disabled = false,
  ...rest
}) {
  return (
    <button
      type="button"
      {...rest}
      className={className.concat(" button-custom")}
      onClick={onClick}
      disabled={loading ? true : disabled}
    >
      {loading ? "Loading..." : title}
    </button>
  );
}
