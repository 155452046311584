import React from "react";
import { Oval } from "react-loader-spinner";

export default function Loading({ style }) {
  const customStyle = style ? style : { height: "50vh" };
  return (
    <div
      style={customStyle}
      class="d-flex justify-content-center align-items-center"
    >
      <Oval
        height={80}
        width={80}
        color="#4f50ce"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#5f5fd9"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}
