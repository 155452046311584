import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { isValidUrl } from "../../../const/utils";

export default function CopyPasteUrls({ priceCalculation }) {
  const [text, setText] = useState();
  const countLinks = text?.split("\n") ?? [];
  const urls = countLinks.filter((x) => isValidUrl(x));
  const history = useHistory();
  const { siteId } = useParams();

  const cancel = () => {
    history.replace(window.location.pathname);
  };

  const calculateHandler = () => {
    priceCalculation({ method: "COPY_PASTE", projectId: siteId, urls });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <div>
          Urls must be of your domain with this format https://... If a url is
          found
        </div>
        <div>
          in an import file twice or more, we'll only add it once. Also, if you
          add
        </div>
        <div>
          a url that's already in your list, we'll prevent it to avoid
          duplication.
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-fill h-100 min-vw-100 bg-white">
      <div>
        <div className="text-center align-items-center d-flex flex-fill flex-column">
          <h2 className="m-0" style={{ color: "black", fontWeight: 700 }}>
            Copy and paste urls
          </h2>
          <div
            className="mt-3 px-3"
            style={{
              color: "#5E5E5E",
              fontSize: 15,
              letterSpacing: "-0.2px",
            }}
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span
                role="button"
                className="fas fa-info-circle text-primary me-1"
              ></span>
            </OverlayTrigger>

            <span>
              Copy and paste urls from your excel file into this field.
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center  align-items-center  mt-4 container w-sm-100 w-md-50">
        <div className="container d-flex flex-column w-sm-100 w-md-75">
          <div>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              placeholder="e.g. https://aseo.com/about us/"
              rows="8"
              onChange={(e) => {
                setText(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="d-flex justify-content-between  mt-3">
            <div>
              <span style={{ color: "#5E5E5E" }}>
                {urls.length} urls pasted
              </span>
            </div>
            <div className="d-flex gap-2">
              <button
                type="button"
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
                onClick={cancel}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={calculateHandler}
                disabled={urls.length === 0}
                class="btn btn-primary btn-sm button-custom"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
