import React, { useState, useEffect } from "react";
import Button from "../common/button";
import $f from "../lib";
function GoogleReindex() {
  const [page, setPage] = useState([]);

  useEffect(() => {
    $f.fetch("/api/page/".concat($f.getQueryPath(4)), "GET", (resp) => {
      setPage(resp.data[0]);
    });
  }, []);

  function reindex() {
    $f.fetch(
      "/api/google/index/".concat(page.id),
      "POST",
      (resp) => {
        if (resp.data) {
          if (resp.data.id) {
            $f.createNotification({
              message: "Reindex sent successfully!",
              type: "success",
            });
            window["modal"].setState({ show: false });
            return;
          }
        }
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      },
      JSON.stringify([])
    );
  }

  return (
    <div>
      <div className="p-3">
        This action will trigger Google reindex of the page.<br></br>
      </div>

      <div className="mt-2 float-end">
        <Button
          className="btn btn-sm me-2"
          title="Reindex"
          type="primary"
          onClick={reindex}
        />
        <Button
          className="btn btn-sm btn-link  "
          title="Cancel"
          onClick={() => {
            window["modal"].setState({ show: false });
          }}
        />
      </div>
    </div>
  );
}

export default GoogleReindex;
