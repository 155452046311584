import React, { useState, useEffect } from "react";
import Card from "../../../../components/Card";
import $f, { jwt } from "../../../../components/lib";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Summary({ data, loading, error, errorMessage }) {
  const { pageId } = useParams();
  const [image, setImage] = useState();

  useEffect(() => {
    fetch($f.baseURL("/api/page/screenshot/".concat(pageId)), {
      method: "GET",
      headers: {
        authorization: jwt(),
        Accept: "image/png",
        "Content-Type": "image/png",
      },
    })
      .then((response) => response.blob())
      .then((resp) => {
        try {
          const url = window.URL.createObjectURL(resp);
          setImage(url);
        } catch (e) {}
      });
  }, []);

  return (
    <Card
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      className="card ps-2 pe-2 pt-1 pb-2 h-100"
      style={{ height: 200, borderRadius: 15 }}
      title="Summary"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
    >
      <div className="d-flex flex-fill mt-2 justify-content-between flex-wrap gap-2">
        <div className="d-flex flex-fill">
          <ul className="list-group list-group-flush d-flex flex-fill">
            <li className="list-group-item ps-0 pe-0 py-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span>Page created: </span>
                  <strong>{$f.formatDate(data?.createdDate)}</strong>
                </div>
              </div>
            </li>
            <li className="list-group-item ps-0 pe-0 py-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span>Latest http status: </span>
                  <strong>{data?.httpStatus}</strong>
                </div>
              </div>
            </li>
            <li className="list-group-item ps-0 pe-0 py-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <span>DOM Content Load (indicative): </span>
                  <strong>{data?.vitals?.domLoadTime} (ms)</strong>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="d-flex flex-fill justify-content-end">
          <div style={{ height: 250 }}>
            <img src={image} className="w-100 h-100"></img>
          </div>
        </div>
      </div>
    </Card>
  );
}
