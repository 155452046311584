export const ogFields = [
  {
    name: "ogTagsPageAttributes.ogTitle",
    label: "OG Title",
    type: "input",
    placeholder: "Set page OG title...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "ogTagsPageAttributes.ogDescription",
    label: "OG Description",
    type: "textarea",
    placeholder: "Set page OG description...",
    rows: "5",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "ogTagsPageAttributes.ogImage",
    label: "OG Image",
    type: "input",
    placeholder: "Set page OG image...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "ogTagsPageAttributes.ogSiteName",
    label: "OG site name",
    type: "input",
    placeholder: "Set OG site name...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "ogTagsPageAttributes.ogType",
    label: "OG Page type",
    type: "input",
    placeholder: "Set page OG page type...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "ogTagsPageAttributes.ogUrl",
    label: "OG Page url",
    type: "input",
    placeholder: "Set page OG page url...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
];

export const basicMetaFields = [
  {
    name: "pageAttributes.metaTitle",
    label: "Title",
    type: "input",
    placeholder: "Set page title...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "pageAttributes.metaDescription",
    label: "Description",
    type: "textarea",
    placeholder: "Set page description...",
    rows: "5",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "pageAttributes.metaKeywords",
    label: "Keywords",
    type: "input",
    placeholder: "Set page keywords...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "pageAttributes.metaRobots",
    label: "Robots",
    type: "input",
    placeholder: "Set page robots...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "pageAttributes.canonical",
    label: "Canonical",
    type: "input",
    placeholder: "Set page canonical url...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
];

export const twitterFields = [
  {
    name: "twitterPageAttributes.twitterTitle",
    label: "Twitter Title",
    type: "input",
    placeholder: "Set page twiter title...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "twitterPageAttributes.twitterDescription",
    label: "Twitter Description",
    type: "textarea",
    placeholder: "Set page twitter description...",
    rows: "5",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "twitterPageAttributes.twitterSite",
    label: "Twitter site",
    type: "input",
    placeholder: "Set site...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "twitterPageAttributes.twitterImage",
    label: "Twitter Image",
    type: "input",
    placeholder: "Set page twitter image...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
  {
    name: "twitterPageAttributes.twitterCard",
    label: "Twitter card type",
    type: "input",
    placeholder: "Set page twitter card type...",
    className: "form-control",
    validations: {
      required: true,
    },
  },
];

export const customHtmlFieds = [
  {
    name: "customHeadHTMLCode",
    label: "Custom page HTML",
    type: "textarea",
    placeholder: "Set custom html for the page...",
    rows: "20",
    className: "form-control",
    validations: {
      required: true,
    },
  },
];
