import $f from "../components/lib";

export const registerUser = async (payload) => {
  return $f
    .httpApiCall({
      url: "/api/auth/register",
      method: "POST",
      body: JSON.stringify(payload),
    })

    .then((res) => res);
};

export const activateUser = async ({ queryKey }) => {
  const [_key, { token }] = queryKey;
  const url = "/api/auth/activate?key=".concat(token);
  return $f.httpApiCall({ url, method: "POST" }).then((res) => res.data);
};

export const resendLink = async (payload) => {
  const { token } = payload;
  const url = "/api/auth/resend-link?key=".concat(token);
  return $f
    .httpApiCall({
      url,
      method: "POST",
    })
    .then((res) => res);
};

export const resendEmail = async (payload) => {
  const url = "/api/auth/resend-email";
  return $f
    .httpApiCall({
      url,
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then((res) => res);
};
