import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import { useMutation } from "react-query";
import { registerUser, resendEmail } from "../../../apis/auth";
import Button from "../../../components/Button";
import $f from "../../../components/lib";
import LoadingOverlayScreen from "../../../components/LoadingOverlayScreen";
import { useForm } from "react-hook-form";
import { isRegisterPassValid, isValidEmail } from "../../../const/utils";

export default function Register() {
  const [tooglePass, setTooglePass] = useState(false);
  const [toogleConfirmPass, setToogleConfirmPass] = useState(false);
  const [checkInbox, setCheckInbox] = useState(false);
  const {
    register,
    formState,
    setValue,
    getValues,
    clearErrors,
    watch,
    setError,
  } = useForm({
    mode: "all",
  });

  const { mutate: submit, status } = useMutation(registerUser, {
    throwOnError: true,
    onError: (err) => {
      if (err.ERROR_TAG === "EMAIL_EXIST") {
        $f.createNotification({
          message: "There is already an account with this email.",
          type: "danger",
        });
      } else {
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      }
    },
    onSuccess: () => {
      setCheckInbox(true);
    },
  });

  const { mutate: resend, status: resendStatus } = useMutation(resendEmail, {
    throwOnError: true,
    onError: (err) => {
      if (err?.ERROR_TAG === "USER_IS_ACTIVATED") {
        $f.createNotification({
          message: "You are already registered to ASEO.",
          type: "danger",
        });
      } else {
        $f.createNotification({
          message: "Something went wrong!",
          type: "danger",
        });
      }
    },
    onSuccess: () => {
      $f.createNotification({
        message:
          "New link was sent successfully! Please check your email inbox.",
        type: "success",
      });
    },
  });

  const createAccount = () => {
    submit(getValues());
  };

  const resendEmailHandler = () => {
    resend({ email: getValues("email") });
  };

  const title = checkInbox ? "Check your inbox" : "Create your account";
  const message = checkInbox ? (
    <div className="lh-sm">
      Click on the link we sent to <b>{getValues("email")}</b> <br />
      to finish your account setup.
    </div>
  ) : (
    <div className="lh-sm">
      Password must contain at least 8 characters,
      <br /> 1 letter and 1 number.
    </div>
  );

  const hasErrors = !$f.isObjectEmpty(formState.errors);
  const valuesIsEmptyObject = $f.isObjectEmpty(watch());

  const disabledBtn =
    hasErrors ||
    valuesIsEmptyObject ||
    watch("name").trim() === "" ||
    watch("surname").trim() === "" ||
    watch("email").trim() === "" ||
    watch("password").trim() === "" ||
    watch("confirmPassword").trim() === "";

  const canISeePassEye =
    watch("password") !== undefined && watch("password")?.trim() !== "";

  const canISeeConfirmPassEye =
    watch("confirmPassword") !== undefined &&
    watch("confirmPassword")?.trim() !== "";

  useEffect(() => {
    if (!canISeeConfirmPassEye) {
      setToogleConfirmPass(false);
    }
    if (!canISeePassEye) {
      setTooglePass(false);
    }
  }, [canISeePassEye, canISeeConfirmPassEye]);

  return (
    <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100 py-2">
      <Card
        style={{ borderRadius: 10, width: 450 }}
        className="card p-2 h-100 "
        bodyClassName="my-2 pb-0"
      >
        <div className="h-75 d-flex align-items-center justify-content-center ">
          <div className="d-flex px-3 flex-fill flex-column">
            <div className="d-flex  align-items-center justify-content-center ">
              <img src="/images/purple.png" height={80} width={80} />
            </div>
            <div className="p-3 text-center">
              <h4 className="fw-bold mb-0 mb-2">{title}</h4>
              <small className="text-muted text-medium">{message}</small>
            </div>
            {!checkInbox && (
              <form className="d-flex flex-column mt-4">
                <div className="d-flex flex-row gap-2 mb-3">
                  <div className="d-flex flex-fill flex-column">
                    <label className="form-label">First name </label>
                    <input
                      placeholder="Type your name"
                      className={"form-control  form-control-sm".concat(
                        formState.errors["name"] ? " is-invalid" : ""
                      )}
                      {...register("name", {
                        required: "First name is required",
                      })}
                    ></input>
                    <small className="error text-danger">
                      {formState?.errors?.name?.message}
                    </small>
                  </div>
                  <div className="d-flex flex-fill flex-column">
                    <label className="form-label ">Last name </label>
                    <input
                      {...register("surname", {
                        required: "Last name is required",
                      })}
                      placeholder="Type your surname"
                      className={"form-control  form-control-sm".concat(
                        formState.errors["surname"] ? " is-invalid" : ""
                      )}
                    />
                    <small className="error text-danger">
                      {formState?.errors?.surname?.message}
                    </small>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label me-2">Email</label>
                  <input
                    {...register("email", {
                      required: "Email is required",
                      validate: (email) => isValidEmail(email) || "Wrong email",
                    })}
                    placeholder="Type your email"
                    className={"form-control  form-control-sm".concat(
                      formState.errors["email"] ? " is-invalid" : ""
                    )}
                  ></input>
                  <small className="error text-danger">
                    {formState?.errors?.email?.message}
                  </small>
                </div>
                <div className="mb-3">
                  <label className="form-label me-2">Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("password", {
                        onChange: (e) => {
                          if (watch("confirmPassword")) {
                            setError("confirmPassword", {
                              type: "manual",
                              message:
                                "Password and confirm password fields do not match",
                            });
                          }
                        },
                        required: "Password is required",
                        validate: (pass) =>
                          isRegisterPassValid(pass) || "Wrong password",
                      })}
                      autocomplete="new-password"
                      type={tooglePass ? "text" : "password"}
                      placeholder="Type your password"
                      className={"form-control  form-control-sm"
                        .concat(
                          formState.errors["password"] ? " is-invalid" : ""
                        )
                        .concat(canISeePassEye ? " padding-right-eye" : "")}
                    />
                    {canISeePassEye && (
                      <div
                        className="eye-icon"
                        onClick={() => {
                          setTooglePass(!tooglePass);
                        }}
                      >
                        {tooglePass ? (
                          <i className="fas fa-eye-slash" />
                        ) : (
                          <i className="fas fa-eye" />
                        )}
                      </div>
                    )}
                  </div>
                  <small className="error text-danger">
                    {formState?.errors?.password?.message}
                  </small>
                </div>
                <div className="mb-2 ">
                  <label className="form-label me-2">Confirm Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: (value) => {
                          const password = getValues("password");
                          return (
                            password === value ||
                            "Password and confirm password fields do not match"
                          );
                        },
                      })}
                      type={toogleConfirmPass ? "text" : "password"}
                      placeholder="Type your password"
                      className={"form-control  form-control-sm"
                        .concat(
                          formState.errors["confirmPassword"]
                            ? " is-invalid"
                            : ""
                        )
                        .concat(
                          canISeeConfirmPassEye ? " padding-right-eye" : ""
                        )}
                    />
                    {canISeeConfirmPassEye && (
                      <div
                        className="eye-icon"
                        onClick={() => {
                          setToogleConfirmPass(!toogleConfirmPass);
                        }}
                      >
                        {toogleConfirmPass ? (
                          <i className="fas fa-eye-slash" />
                        ) : (
                          <i className="fas fa-eye" />
                        )}
                      </div>
                    )}
                  </div>
                  <small className="error text-danger">
                    {formState?.errors?.confirmPassword?.message}
                  </small>
                </div>
                <Button
                  loading={status === "loading"}
                  title="Create account"
                  className="btn btn-primary btn-sm mt-2 "
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  onClick={createAccount}
                  disabled={status === "loading" || disabledBtn}
                />
              </form>
            )}
            {checkInbox ? (
              <>
                {resendStatus === "loading" && <LoadingOverlayScreen />}
                <div className="pt-5 d-flex flex-column text-center">
                  <small className="text-muted text-medium">
                    No email in your inbox or spam folder?
                    <span
                      onClick={resendEmailHandler}
                      style={{ color: "#4F50C7", cursor: "pointer" }}
                    >
                      {` Let’s Resend it`}
                    </span>
                  </small>
                  <small className="text-muted text-medium mt-2 mb-2">
                    Wrong email address?
                    <span style={{ color: "#4F50C7" }}>
                      <Link to="/login"> Go back</Link>
                    </span>
                  </small>
                </div>
              </>
            ) : (
              <div className="p-3 d-flex flex-column text-center text-medium">
                <small className="text-muted ">Already using ASEO?</small>
                <small style={{ color: "#4F50C7" }}>
                  <Link to="/login">Sign in to your account</Link>
                </small>
              </div>
            )}

            {!checkInbox && (
              <div className="text-muted d-flex flex-fill text-center flex-row justify-content-center  pt-5 text-medium ">
                <small>
                  © 2024 ASEO, Inc. All rights reserved. |{" "}
                  <span style={{ color: "#4F50C7" }}>
                    <a target="_blank" href="/policy.html">
                      Privacy
                    </a>
                  </span>
                </small>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
