import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import NoResults from "../../../components/NoResults";
import { _countriesCodes } from "../../../components/utils";
import Table from "../../../components/Table";

export default function VisitsByCountry({
  data,
  error,
  errorMessage,
  loading,
  hasGoogleApiKey,
}) {
  const { siteId, pageId } = useParams();

  const warning = "Google Api key is mandatory";

  const tableHeaders = [
    {
      label: "Country",
      id: "country",
      th: { className: "text-start" },
      td: { className: "text-start" },
      formatValue: (row) => {
        const _country = _countriesCodes?.find(
          (x) =>
            x?.code?.toLocaleLowerCase() === row?.country?.toLocaleLowerCase()
        );

        return (
          <div className="d-flex">
            <img
              width={35}
              height={35}
              style={{
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={_country?.flag}
            />
            <p className="ms-3">{_country?.name}</p>
          </div>
        );
      },
    },
    {
      label: "Impressions",
      id: "impression",
      th: { className: "text-center" },
      td: { className: "text-center" },
    },
  ];

  const redirectLink = pageId
    ? `/site/${siteId}/${pageId}/reports/visits`
    : window.location.pathname.concat("/visits");

  return (
    <Card
      style={{ maxHeight: 500, minHeight: 500, borderRadius: 15 }}
      error={error}
      errorMessage={errorMessage}
      loading={loading}
      title="Visits by Country"
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      subtitle={"Number of countries:".concat(` ${data?.length ?? 0}`)}
      actions={() => (
        <div className="text-primary">
          {data?.length > 0 ? (
            <Link className="hoverable-text-primary" to={redirectLink}>
              View all
            </Link>
          ) : null}
        </div>
      )}
    >
      {data?.length > 0 ? (
        <Table data={data?.slice(0, 10)} headers={tableHeaders} />
      ) : (
        <NoResults
          title="There are no results yet"
          subtitle={
            !hasGoogleApiKey
              ? warning
              : "Top visits by country will appear here"
          }
        />
      )}
    </Card>
  );
}
