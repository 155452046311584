import React from "react";
import Card from "../../../../components/Card";
import Button from "../../../../components/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function FailedRegistration({ type, resendLink, resendStatus }) {
  const getTitle = () => {
    if (type === "USER_IS_ACTIVATED") {
      return "User is activated";
    }

    if (type === "EXPIRED") {
      return "Link expired";
    }

    if (type === "NOT_FOUND") {
      return "Activation key is not valid";
    }

    if (type === "GENERAL_ERROR") {
      return "Error";
    }
  };

  const getMessage = () => {
    if (type === "USER_IS_ACTIVATED") {
      return (
        <>
          <div>It seems that with this activation key,</div>
          <div> there is already an activated user</div>
        </>
      );
    }
    if (type === "EXPIRED") {
      return (
        <>
          <div>We are sorry, the link expires after 24 hours.</div>
          <div> Click the button below to send you a new link.</div>
        </>
      );
    }
    if (type === "NOT_FOUND") {
      return (
        <>
          <div>
            This key doesn't exist or the email verification link you clicked is
            old.
          </div>
          <div>Make sure to open the most recent email.</div>
        </>
      );
    }
    if (type === "GENERAL_ERROR") {
      return (
        <>
          <div>Something went wrong!</div>
          <div>Please try to visit this page later!</div>
        </>
      );
    }
  };
  return (
    <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100  mx-3">
      <Card style={{ borderRadius: 10, marginTop: 15, marginBottom: 15 }}>
        <div>
          <img src="/images/purple.png" height={40} />
        </div>
        <div className="d-flex flex-row flex-wrap gap-4 px-4">
          <div className="d-flex failed-image-registration-container">
            <img
              src="/images/expired.png"
              className="failed-registration-image"
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div>
              <div>
                <b style={{ fontSize: 28, lineHeight: 1.3 }}>{getTitle()}</b>
              </div>
              <div
                className="d-flex flex-column mt-2"
                style={{ color: "#5E5E5E", lineHeight: 1.3, fontSize: 14 }}
              >
                {getMessage()}
              </div>
              {type === "EXPIRED" && (
                <div>
                  <Button
                    loading={resendStatus === "loading"}
                    title="Send me a new link"
                    className="btn btn-primary btn-sm py-1 mt-3"
                    onClick={resendLink}
                    style={{
                      borderRadius: 10,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                    disabled={resendStatus === "loading"}
                  />
                </div>
              )}
              {type === "USER_IS_ACTIVATED" && (
                <Link
                  className="btn btn-primary btn-sm py-1 mt-3"
                  to="/login"
                  style={{
                    borderRadius: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
