import React, { useState } from "react";
import Card from "../../../components/Card";
import { useHistory } from "react-router-dom";
import $f from "../../../components/lib";
import ImportSitemap from "../ImportSitemap";
import CopyPasteUrls from "../CopyPasteUrls";
import UploadFile from "../UploadFile";
import FailedToAccessImportPages from "../FailedToAccessImportPages";
import { usePrivileges } from "../../../hooks/usePrivileges";

const CardSelection = ({
  title,
  description,
  selected,
  id,
  onSelected,
  canISee,
}) => {
  return canISee ? (
    <Card
      className="card px-1 import-page-selection"
      style={{
        minHeight: 180,
        width: 300,
        borderRadius: 15,
        cursor: "pointer",
        backgroundColor: selected === id ? "#4F50C70D" : "",
        borderColor: selected === id ? "#4F50C7" : "",
      }}
      onClick={() => onSelected(id)}
    >
      <b>{title}</b>
      <span style={{ color: "#5E5E5E", fontSize: 14 }}>{description}</span>
    </Card>
  ) : null;
};

export default function MethodSelection({ site, priceCalculation }) {
  const history = useHistory();
  const [selected, setSelected] = useState(undefined);

  const onSelected = (id) => {
    setSelected(id);
  };

  const hasMethodQuery = $f.checkQueryParam("method");
  const method = $f.getParameterByName("method");

  const { canIImportPages } = usePrivileges();

  if (!canIImportPages(site)) {
    return <FailedToAccessImportPages type="UNAUTHORIZED" />;
  }

  if (hasMethodQuery) {
    if (method === "IMPORT_SITEMAP") {
      return site?.sitemapMethod === null ? (
        <ImportSitemap priceCalculation={priceCalculation} />
      ) : (
        <FailedToAccessImportPages type="UNAUTHORIZED" />
      );
    }

    if (method === "COPY_PASTE") {
      return <CopyPasteUrls priceCalculation={priceCalculation} />;
    }

    if (method === "UPLOAD_FILE") {
      return <UploadFile priceCalculation={priceCalculation} />;
    }
  }

  const canISeeNote = site?.sitemapMethod === null;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-fill bg-white">
      <div className="d-flex flex-column gap-3">
        <div className="text-center">
          <b className="m-0" style={{ color: "black", fontSize: 28 }}>
            Ιmport pages to {site?.name ?? "-"}
          </b>
          <div className="mt-1 text-medium text text-grayblack">
            Select a method to import your site’s urls
          </div>
        </div>

        <div className="d-flex mt-4 flex-fill align-items-center flex-column px-2">
          <div className="d-flex flex-column">
            <div className="d-flex  justify-content-center gap-3  flex-wrap ">
              <CardSelection
                id="IMPORT_SITEMAP"
                title="Import sitemap"
                onSelected={onSelected}
                selected={selected}
                description="Upload urls from a sitemap xml file."
                canISee={site?.sitemapMethod === null}
              />
              <CardSelection
                id="COPY_PASTE"
                title="Copy and paste"
                onSelected={onSelected}
                selected={selected}
                description="Directly paste in urls from a spreadsheet."
                canISee={true}
              />
              <CardSelection
                id="UPLOAD_FILE"
                title="Upload file"
                onSelected={onSelected}
                selected={selected}
                description="Import urls from a CSV or TXT file."
                canISee={true}
              />
            </div>
            <div className="d-flex justify-content-between gap-2 mt-3 flex-wrap">
              {canISeeNote && (
                <small className="align-self-end" style={{ color: "#5E5E5E" }}>
                  <b style={{ color: "black" }}>Note:</b> If you choose to
                  import your sitemap, you will only be able to use that method.
                </small>
              )}
              <div className="d-flex justify-content-end flex-fill  gap-2">
                <button
                  type="button"
                  className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={selected === undefined}
                  onClick={() => {
                    history.replace(
                      window.location.pathname
                        .concat("?method=")
                        .concat(selected)
                    );
                  }}
                  class="btn btn-primary btn-sm button-custom"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
