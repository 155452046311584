import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class ModalWidget extends Component {
  state = {
    data: [],
    loaded: false,
    title: [],
    body: [],
    footer: [],
    show: false,
    size: "",
  };

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.props.data, loaded: true });
  }

  close() {
    this.setState({
      title: [],
      body: [],
      footer: [],
      show: false,
      size: "",
    });
  }

  render() {
    // let output = (
    //   <Modal
    //     show={this.state.show}
    //     centered={true}
    //     backdrop="static"
    //     onHide={this.close}
    //     size={this.state.size}
    //   >
    //     {/* <Modal.Header className="pt-0 pb-0">
    //       <Modal.Title className="mb-0 w-100 pb-1">
    //         {this.state.title}
    //       </Modal.Title>
    //       <button
    //         type="button"
    //         className="close btn btn-link"
    //         data-dismiss="modal"
    //         aria-label="Close"
    //         onClick={this.close}
    //       >
    //         <span aria-hidden="true" style={{ fontSize: "30px" }}>
    //           &times;
    //         </span>
    //       </button>
    //     </Modal.Header> */}
    //     <Modal.Body
    //       style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
    //       className="mb-3"
    //     >
    //       <h4>{this.state.title}</h4>
    //       {this.state.body}
    //     </Modal.Body>
    //   </Modal>
    // );

    if (this.state.show) {
      return this.state.body;
    } else {
      return "";
    }
  }
}

export default ModalWidget;
