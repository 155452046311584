import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Checklist from "./Checklist";
import SitePages from "./SitePages";
import Team from "./Team";
import SiteCosts from "./SiteCosts";
import Reporting from "./Reporting";
import $f from "../../../components/lib";
import { fetchSite } from "../../../apis/sites";
import { useQuery } from "react-query";
import LoadingOverlayScreen from "../../../components/LoadingOverlayScreen";
import Invitation from "../../../components/WithModal/Invitation";
import { useActions } from "../../../hooks/useActions";
import VerificationModal from "../../../components/WithModal/Verification";
import ImportPagesButton from "../../../components/ImportPagesButton";
import { usePrivileges } from "../../../hooks/usePrivileges";

function Site() {
  const { siteId } = useParams();

  const {
    data: site,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(["site", { siteId }], fetchSite);

  const { siteActions, isDownloading } = useActions();
  const { canIImportPages, canISeeShareButton } = usePrivileges();

  if (isLoading) {
    return <LoadingOverlayScreen />;
  }

  // const canISeeBilling = site?.projectUserRole === "OWNER";
  return (
    <>
      {isDownloading && <LoadingOverlayScreen />}
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={
          <>
            <span style={{ color: "#4F50C7" }}>
              {$f.removeTrailingSlash(site?.domain) ?? "-"}
            </span>{" "}
            | UUID:
            {site?.uuid}
          </>
        }
        actions={() => (
          <div className="d-flex justify-content-end gap-2">
            {canISeeShareButton(site) && (
              <button
                type="button"
                style={{ borderRadius: 10 }}
                className="btn btn-primary btn-sm"
                onClick={() => {
                  window["modal"].setState({
                    show: true,
                    body: <Invitation siteId={siteId} siteName={site?.name} />,
                  });
                }}
              >
                <i class="fas fa-user-friends me-2"></i>
                <span>Share</span>
              </button>
            )}
            {site?.verified ? (
              <ImportPagesButton
                siteId={siteId}
                canISee={canIImportPages(site)}
              />
            ) : (
              <button
                type="button"
                style={{ borderRadius: 10 }}
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm"
                onClick={() => {
                  window["modal"].setState({
                    show: true,
                    body: (
                      <VerificationModal method="REDIRECT" siteId={siteId} />
                    ),
                  });
                }}
              >
                Verify
              </button>
            )}
            <>
              <button
                type="button"
                style={{ borderRadius: 10 }}
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm px-3 py-1"
              >
                <i className="fas fa-ellipsis-h"></i>
              </button>
              <ul
                className="dropdown-menu overflow-hidden pt-0 pb-0 mt-3 mb-3 "
                aria-labelledby="dropdownMenuButton1"
              >
                <ul className="nav flex-column ">
                  {siteActions.map(
                    (item) =>
                      item.canISee(site) && (
                        <li
                          key={item.id}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            ...item.style,
                          }}
                          className="nav-item d-block action-links  small dropdown-item"
                          role="button"
                          onClick={() =>
                            item.onClick({
                              name: site?.name,
                              projectId: siteId,
                            })
                          }
                        >
                          {item.label}
                        </li>
                      )
                  )}
                </ul>
              </ul>
            </>
          </div>
        )}
      />
      <div className="mt-4">
        <div className="row g-3">
          <div
            className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-start"
            style={{ alignSelf: "flex-start" }}
          >
            <Checklist refetch={refetch} data={site} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="row g-3">
              <div className="col-12">
                <Reporting />
              </div>
              <div className="col-12">
                <SitePages
                  data={site}
                  error={isError}
                  errorMessage={error?.error}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Site;
{
  /* <div className="col-md-6">
            <div className="row">
              <div className="col mb-3 ">
                <Checklist refetch={refetch} data={site} />
              </div>
            </div>
            <div className="row">
              <div className="col mb-3 mt-2">
                <SitePages
                  data={site}
                  error={isError}
                  errorMessage={error?.error}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 ps-lg-0 ps-md-0">
            <div className="row">
              <div className="col mb-3">
                <div className="row">
                  {/* {canISeeBilling && (
                    <div className="col-md-6 pe-lg-0 pe-md-0 mb-2">
                      <SiteCosts />
                    </div>
                  )} */
}
//         <div
//           // className={canISeeBilling ? "col-md-6" : "col-md-12 "}
//           className={"col-md-12 "}
//         >
//           <Reporting />
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="row">
//     <div className="col mb-3">
// <SitePages
//   data={site}
//   error={isError}
//   errorMessage={error?.error}
//   loading={isLoading}
// />
//     </div>
//   </div>
// </div> */}
