import React, { useState } from "react";
import $f from "../../../components/lib";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import Table from "../../../components/Table";
import NoResults from "../../../components/NoResults";
import {
  fetchMissingTagsAndSummaryReports,
  fetchVersions,
} from "../../../apis/reports";
import { useQuery } from "react-query";

export default function MissingTags() {
  const [version, setVersion] = useState();
  const [allVersions, setAllVersions] = useState([]);
  const { siteId, pageId } = useParams();

  const {
    isLoading: versionsLoading,
    isError: versionsError,
    error: versionsErrorMessage,
  } = useQuery(["versions", { siteId }], fetchVersions, {
    onSuccess: (response) => {
      const current = response?.versions.reduce((a, b) => {
        return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
      });
      setAllVersions(response?.versions);
      setVersion(current?.ingestVersion);
    },
  });

  const { data, isLoading, isError, error } = useQuery(
    ["missingTagsAndSummaryReports", { version, siteId }],
    fetchMissingTagsAndSummaryReports,
    {
      enabled: version !== undefined,
    }
  );

  const showLoader = isLoading || versionsLoading;
  const showError = isError || versionsError;
  const showErrorMessage = error || versionsErrorMessage;
  const summaryReports = data?.summaryReports ?? {};

  return (
    <Card
      style={{ minHeight: 500, maxHeight: 500, borderRadius: 15 }}
      title="Project Summary"
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
      errorMessage={showErrorMessage?.error}
      loading={showLoader}
      error={showError}
      actions={() => (
        <div>
          <select
            onChange={(e) => {
              setVersion(e.target.value);
            }}
            value={version}
            className="form-select"
          >
            {allVersions?.map((item) => {
              return (
                <option
                  key={item.ingestVersion}
                  value={item.ingestVersion}
                  className="form-control"
                >
                  {$f.formatDate(item.updatedOn)}
                </option>
              );
            })}
          </select>
        </div>
      )}
    >
      {$f.isObjectEmpty(summaryReports) ? (
        <NoResults title="There are no results for this ingest version" />
      ) : (
        <>
          <div className="mb-4 mt-4">
            <label className="form-label me-2">Total pages</label>
            <span className="d-block strong">{summaryReports?.totalPages}</span>
          </div>
          <div className="mb-4">
            <label className="form-label me-2">Total crawled pages</label>
            <span className="d-block strong">
              {summaryReports?.totalPagesCrawled}
            </span>
          </div>
          <div className="mb-4">
            <label className="form-label me-2">Total HTML syntax errors</label>
            <span className="d-block strong">
              {summaryReports?.totalErrors}
            </span>
          </div>
          <div className="mb-4">
            <label className="form-label me-2">
              Total HTML syntax warnings
            </label>
            <span className="d-block strong">
              {summaryReports?.totalWarnings}
            </span>
          </div>
          <div className="mb-4">
            <label className="form-label me-2">Total HTML syntax info</label>
            <span className="d-block strong">{summaryReports?.totalInfos}</span>
          </div>
        </>
      )}
    </Card>
  );
}
