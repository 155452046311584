import $f from "../components/lib";

export const fetchGoogleReportsForPage = async ({ queryKey }) => {
  const [_key, { siteId, pageId }] = queryKey;

  const googleReportsUrl = "/api/google/project/"
    .concat(siteId)
    .concat("/page/")
    .concat(pageId);

  return $f.httpApiCall({ url: googleReportsUrl }).then((res) => res.data);
};

export const fetchGoogleReportsForSite = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;

  const googleReportsUrl = `/api/google/project/${siteId}`;

  return $f.httpApiCall({ url: googleReportsUrl }).then((res) => res.data);
};

export const fetchVersions = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;
  const versionsApi = "/api/report/versions/project/".concat(siteId);

  return $f.httpApiCall({ url: versionsApi }).then((res) => res.data);
};

export const fetchTagsInfo = async ({ queryKey }) => {
  const [_key, { siteId, pageId, version }] = queryKey;
  const tagsInfoApi = `/api/report/project/${siteId}/page/${pageId}/${version}`;
  return $f.httpApiCall({ url: tagsInfoApi }).then((res) => res.data);
};

export const fetchMissingTagsAndSummaryReports = async ({ queryKey }) => {
  const [_key, { siteId, version }] = queryKey;

  await $f
    .httpApiCall({
      url: `/api/report/project/${siteId}/${version}`,
      method: "PUT",
    })
    .then((res) => res);

  const _data = await $f
    .httpApiCall({ url: `/api/report/project/${siteId}/${version}` })
    .then((resp) => {
      const _formatter =
        Object?.keys?.(resp?.data?.tags)?.map((item) => {
          const _nameFormatter = item.replace(/([A-Z])/g, " $1");
          const _name =
            _nameFormatter.charAt(0).toUpperCase() + _nameFormatter.slice(1);
          return {
            name: _name.replace("Existence", ""),
            exist: resp?.data?.tags[item],
            total: resp?.data?.totalNumberOfPages,
          };
        }) ?? [];

      return {
        tagsReports: _formatter,
        summaryReports: {
          totalPages: resp?.data?.totalNumberOfPages ?? "-",
          totalPagesCrawled: resp?.data?.totalCrawledPages ?? "-",
          totalErrors: resp?.data?.totalw3cErrors ?? "-",
          totalInfos: resp?.data?.totalw3cInfos ?? "-",
          totalWarnings: resp?.data?.totalHtmlWarnings ?? "-",
        },
      };
    });

  return _data;
};

export const fetchKeywords = async ({ queryKey }) => {
  const [_key, { siteId }] = queryKey;
  const url = `/api/keywords/${siteId}`;
  return $f.httpApiCall({ url }).then((res) => res);
};

export const fetchKeywordStats = async ({ queryKey }) => {
  const [_key, { keywordId }] = queryKey;
  const url = "/api/keywordstats/".concat(keywordId).concat("/").concat(7);
  return $f.httpApiCall({ url }).then((res) => res);
};

export const addNewKeyword = async (payload) => {
  return $f
    .httpApiCall({
      url: "/api/keyword",
      method: "POST",
      body: JSON.stringify(payload),
    })

    .then((res) => res.data);
};

export const deleteKeyword = async (payload) => {
  const { id } = payload;
  return $f
    .httpApiCall({
      url: "/api/keyword/".concat(id),
      method: "DELETE",
    })
    .then((res) => res.data);
};
