import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import $f from "../../../components/lib";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function UploadFile({ priceCalculation }) {
  const [file, setFile] = useState(undefined);
  const [fileIsCorrect, setFileIsCorrect] = useState(false);

  const history = useHistory();
  const cancel = () => {
    history.replace(window.location.pathname);
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
    document.getElementById("draggable-area").classList.add("hoverable");
  };

  const leaveDrop = (ev) => {
    ev.preventDefault();
    document.getElementById("draggable-area").classList.remove("hoverable");
  };

  const drop = (ev) => {
    ev.preventDefault();
    document.getElementById("draggable-area").classList.remove("hoverable");
    setFile(ev.dataTransfer.files[0]);
  };

  const openFileSelection = () => {
    document.getElementById("fileInput").click();
  };

  const onFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);
      e.target.value = null;
    }
  };

  const bytesToGB = (sizeInBytes) => {
    return sizeInBytes / 1024 ** 3;
  };

  const notAllowed = (message) => {
    setFileIsCorrect(false);
    $f.createNotification({
      message,
      type: "danger",
    });
  };

  useEffect(() => {
    if (file) {
      if (file.type === "text/csv" || file.type === "text/plain") {
        if (bytesToGB(file.size) <= 1) {
          setFileIsCorrect(true);
        } else {
          notAllowed("This file exceeds 1 GB");
        }
      } else {
        notAllowed("Wrong file extension");
      }
    }
  }, [file]);

  const canISeeFile = file && fileIsCorrect;

  const { siteId } = useParams();

  const calculateHandler = () => {
    priceCalculation({ file, method: "UPLOAD_FILE", projectId: siteId });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <div>
          Urls must be of your domain with this format https://... If a url is
          found
        </div>
        <div>
          in an import file twice or more, we'll only add it once. Also, if you
          add
        </div>
        <div>
          a url that's already in your list, we'll prevent it to avoid
          duplication.
        </div>
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center flex-fill h-100 min-vw-100 bg-white">
        <div>
          <div className="text-center align-items-center d-flex flex-fill flex-column">
            <h2 className="m-0" style={{ color: "black", fontWeight: 700 }}>
              Upload file
            </h2>
            <div
              className="mt-3 px-3"
              style={{
                color: "#5E5E5E",
                fontSize: 15,
                letterSpacing: "-0.2px",
              }}
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span
                  role="button"
                  className="fas fa-info-circle text-primary me-1"
                ></span>
              </OverlayTrigger>
              <span>Import urls from a CSV or TXT file.</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center  align-items-center  mt-4 container w-sm-100 w-md-50">
          <div className="container d-flex flex-column w-sm-100 w-md-75">
            <div
              id="draggable-area"
              className="d-flex flex-column flex-fill text-center py-5"
              onDrop={drop}
              onDragOver={allowDrop}
              onDragLeave={leaveDrop}
              style={{
                backgroundColor: "#F9F9F9",
                borderRadius: 10,
                border: "1px solid #E7E7E7",
              }}
            >
              <div>
                <b>
                  <input
                    id="fileInput"
                    type="file"
                    onChange={onFileChange}
                    hidden
                  />
                  <span
                    onClick={openFileSelection}
                    style={{ color: "#4E2C95", cursor: "pointer" }}
                  >
                    Click to upload
                  </span>
                  <span> or drag and drop</span>
                </b>
              </div>
              <div>CSV, TXT (max 1 GB) </div>
            </div>
            {canISeeFile && (
              <div className="card border-light-gray mt-2 p-3">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <i class="far fa-file-alt fa-lg"></i>
                    <div>
                      <strong>{file.name}</strong>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setFile(undefined);
                    }}
                  >
                    <i className="fas fa-trash-alt" role="button"></i>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end flex-fill mt-3 gap-2">
              <button
                type="button"
                className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
                onClick={cancel}
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={!canISeeFile}
                onClick={calculateHandler}
                class="btn btn-primary btn-sm button-custom"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
