import React, { useState } from "react";
import Card from "../../../../components/Card";
import { fetchPageInfo } from "../../../../apis/sites";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "react-query";
import UploadGoogleJsonFileModal from "../../../../components/WithModal/UploadGoogleJsonFile";
import EditPageModal from "../../../../components/WithModal/EditPage";

const _ = require("lodash");

export default function Checklist({ site, refetch }) {
  const [open, setOpen] = useState(false);
  const { pageId, siteId } = useParams();

  const { data, refetch: refechPageInfo } = useQuery(
    ["pageInfo", { pageId }],
    fetchPageInfo
  );

  const pageAttributes = {
    metaTitle: data?.pageAttributes?.metaTitle ?? "",
    metaDescription: data?.pageAttributes?.metaDescription ?? "",
    metaKeywords: data?.pageAttributes?.metaKeywords ?? "",
    metaRobots: data?.pageAttributes?.metaRobots ?? "",
    canonical: data?.pageAttributes?.canonical ?? "",
  };

  const twitterAttributes = {
    twitterTitle: data?.twitterPageAttributes?.twitterTitle ?? "",
    twitterDescription: data?.twitterPageAttributes?.twitterDescription ?? "",
    twitterSite: data?.twitterPageAttributes?.twitterSite ?? "",
    twitterImage: data?.twitterPageAttributes?.twitterImage ?? "",
    twitterCard: data?.twitterPageAttributes?.twitterCard ?? "",
  };

  const ogTagsPageAttributes = {
    ogTitle: data?.ogTagsPageAttributes?.ogTitle ?? "",
    ogDescription: data?.ogTagsPageAttributes?.ogDescription ?? "",
    ogImage: data?.ogTagsPageAttributes?.ogImage ?? "",
    ogSiteName: data?.ogTagsPageAttributes?.ogSiteName ?? "",
    ogType: data?.ogTagsPageAttributes?.ogType ?? "",
    ogUrl: data?.ogTagsPageAttributes?.ogUrl ?? "",
  };

  const customHeadHTMLCode = data?.customHeadHTMLCode ?? "";

  const basicMetadataHasError =
    Object?.keys(pageAttributes)?.some((p) => {
      return pageAttributes[p] === "";
    }) ?? false;

  const ogHasError =
    Object?.keys(ogTagsPageAttributes)?.some((p) => {
      return ogTagsPageAttributes[p] === "";
    }) ?? false;

  const twitterAttrHasError =
    Object?.keys(twitterAttributes)?.some((p) => {
      return twitterAttributes[p] === "";
    }) ?? false;

  const htmlHasError = customHeadHTMLCode === "" ?? false;

  const steps = [
    {
      label: "Basic metadata",
      completed: !basicMetadataHasError,
      action: () => {
        setOpen(true);
      },
      btnLabel: "Edit",
    },
    {
      label: "OG metadata ",
      completed: !ogHasError,
      action: () => {
        setOpen(true);
      },
      btnLabel: "Edit",
    },
    {
      label: "Twitter metadata",
      completed: !twitterAttrHasError,
      action: () => {
        setOpen(true);
      },
      btnLabel: "Edit",
    },
    {
      label: "Custom HTML",
      completed: !htmlHasError,
      action: () => {
        setOpen(true);
      },
      btnLabel: "Edit",
    },
    {
      label: "Google API is needed for the reporting",
      completed: site?.googleJsonFileName !== null,
      action: () => {
        window["modal"].setState({
          show: true,
          body: (
            <UploadGoogleJsonFileModal
              cb={() => {
                refetch();
              }}
              siteId={siteId}
            />
          ),
        });
      },
      btnLabel: "Let's go",
    },
  ];

  const closeModal = () => {
    setOpen(false);
    refechPageInfo();
  };

  return (
    <>
      <EditPageModal pageId={pageId} open={open} closeModal={closeModal} />
      <Card
        className="card ps-2 pe-2 pt-1 pb-2 h-100"
        style={{ borderRadius: 15 }}
        title="Checklist"
        bodyClassName="card-body d-flex flex-column flex-fill h-100"
      >
        <div className="d-flex flex-fill mt-2">
          <ul className="list-group list-group-flush d-flex flex-fill">
            {steps.map((item) => {
              const _classNameIcon = item.completed
                ? "fas fa-check-circle text-success"
                : "fas fa-exclamation-triangle text-warning";

              return (
                <li className="list-group-item ps-0 pe-0 py-3">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex" style={{ flex: 1 }}>
                      <i className={_classNameIcon} />
                      <span className="ms-2">{item.label}</span>
                      <span className="ms-3 ">{item.info}</span>
                    </div>
                    <div className="d-flex">
                      <button
                        type="button"
                        style={{ borderRadius: 15 }}
                        onClick={item.action}
                        className="btn hoverable-lightgray text-grayblack border bg-white btn-sm  py-1 px-3"
                      >
                        {item.btnLabel}
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Card>
    </>
  );
}
