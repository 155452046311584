import React from "react";
import Checklist from "./Checklist";
import Sites from "./Sites";
import Billing from "./Billing";
import Header from "../../components/Header";

function Home() {
  return (
    <>
      <Header title="Home" />
      <div className="mt-4">
        <div className="row g-3">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-start">
            <Sites />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 align-self-start">
            <Checklist />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col mb-3 ">
                <Sites />
              </div>
            </div>
            <div className="row">
              <div className="col mb-3 mt-2">
                <Checklist />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col mb-3">
                <Checklist />
                <Billing />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Home;
