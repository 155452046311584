import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { checkSitemap } from "../../../apis/sites";
import { useQuery } from "react-query";
import LoadingOverlayScreen from "../../../components/LoadingOverlayScreen";
import $f from "../../../components/lib";
import Card from "../../../components/Card";

export default function ImportSitemap({ priceCalculation }) {
  const [url, setUrl] = useState(undefined);
  const [sitemapData, setSitemapData] = useState();
  const [status, setStatus] = useState();
  const { siteId } = useParams();

  const { isLoading } = useQuery(["sitemap", { siteId }], checkSitemap, {
    onSuccess: (res) => {
      setSitemapData(res);
      setStatus("EXIST");
    },
    onError: (err) => {
      setStatus("ERROR");
    },
  });

  const history = useHistory();
  const cancel = () => {
    history.replace(window.location.pathname);
  };

  const sitemapExist = status == "EXIST";

  const calculateHandler = () => {
    priceCalculation({
      method: "IMPORT_SITEMAP",
      projectId: siteId,
      urls: sitemapExist ? sitemapData?.sitemapLinks : [url],
    });
  };

  if (isLoading) {
    return (
      <LoadingOverlayScreen label="We are trying to find sitemap.This might take a while..." />
    );
  }

  if (sitemapExist) {
    return (
      <div className=" d-flex flex-column justify-content-start align-items-center flex-fill h-100 bg-white">
        <div className="mx-4" style={{ position: "absolute", top: "20%" }}>
          <div className="text-center">
            <b className="m-0" style={{ color: "black", fontSize: 26 }}>
              Ιmport sitemap
            </b>
            <div className="mt-2" style={{ color: "#5E5E5E" }}>
              <div>
                We have discovered the following sitemap(s) , which contains{" "}
                {sitemapData?.count} urls:
              </div>
              <div className="d-flex flex-column mt-1">
                {sitemapData?.sitemapLinks.map((item) => {
                  return (
                    <div
                      className="d-flex flex-row flex-fill"
                      style={{ color: "black" }}
                    >
                      <div className="d-flex flex-row gap-2">
                        <div>•</div> <strong key={item}>{item}</strong>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={
              sitemapExist
                ? "d-flex justify-content-center gap-2 mt-4"
                : "d-flex justify-content-end gap-2 mt-3"
            }
          >
            <button
              type="button"
              className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
              onClick={cancel}
            >
              Cancel
            </button>
            <button
              type="button"
              // disabled={sitemapData?.sitemapLinks.length > 0 || url !== undefined}
              onClick={calculateHandler}
              class="btn btn-primary btn-sm button-custom"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-center flex-fill h-100  mx-3">
      <Card style={{ borderRadius: 10, marginTop: 15, marginBottom: 15 }}>
        <div>
          <img src="/images/purple.png" height={40} />
        </div>
        <div className="d-flex flex-row flex-wrap gap-5 px-4">
          <div className="d-flex failed-sitemap-container p-3">
            <img
              src="/images/sitemap.png"
              className="failed-registration-image"
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div>
              <div>
                <b style={{ fontSize: 28, lineHeight: 1.3 }}>Error</b>
              </div>
              <div
                className="d-flex flex-column mt-2"
                style={{ color: "#5E5E5E", lineHeight: 1.3, fontSize: 14 }}
              >
                <div>Something went wrong while retrieving the sitemap.</div>
                <div>Please try again later.</div>
              </div>

              <Link
                className="btn btn-primary btn-sm py-1 mt-3"
                to={window.location.pathname}
                style={{
                  borderRadius: 10,
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
              >
                Go back
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
  // return (
  //   <div className=" d-flex flex-column justify-content-start align-items-center flex-fill h-100 bg-white">
  //     <div className="mx-4" style={{ position: "absolute", top: "20%" }}>
  //       <div className="text-center">
  //         <b className="m-0" style={{ color: "black", fontSize: 26 }}>
  //           Ιmport sitemap
  //         </b>
  //         {sitemapExist ? (
  //           <div className="mt-2" style={{ color: "#5E5E5E" }}>
  //             <div>
  //               We have discovered the following sitemap(s) , which contains{" "}
  //               {sitemapData?.count} urls:
  //             </div>
  //             <div className="d-flex flex-column mt-1">
  //               {sitemapData?.sitemapLinks.map((item) => {
  //                 return (
  //                   <div
  //                     className="d-flex flex-row flex-fill"
  //                     style={{ color: "black" }}
  //                   >
  //                     <div className="d-flex flex-row gap-2">
  //                       <div>•</div> <strong key={item}>{item}</strong>
  //                     </div>
  //                   </div>
  //                 );
  //               })}
  //             </div>
  //           </div>
  //         ) : (
  //           <div className="mt-2 px-3" style={{ color: "#5E5E5E" }}>
  //             <div>
  //               We haven’t detected a sitemap. Instead, you can paste your
  //               sitemap here.
  //             </div>
  //             <div>
  //               Note that if you choose to import your sitemap, you will only be
  //               able to use that method for this site.
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //       {sitemapExist ? null : (
  //         <div className="mt-4">
  //           <input
  //             placeholder="e.g. https://aseo.com/sitemap.xml"
  //             className="form-control form-control-md "
  //             value={url}
  //             onChange={(e) => {
  //               setUrl(e.target.value);
  //             }}
  //           ></input>
  //         </div>
  //       )}
  //       <div
  //         className={
  //           sitemapExist
  //             ? "d-flex justify-content-center gap-2 mt-4"
  //             : "d-flex justify-content-end gap-2 mt-3"
  //         }
  //       >
  //         <button
  //           type="button"
  //           className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
  //           onClick={cancel}
  //         >
  //           Cancel
  //         </button>
  //         <button
  //           type="button"
  //           // disabled={sitemapData?.sitemapLinks.length > 0 || url !== undefined}
  //           onClick={calculateHandler}
  //           class="btn btn-primary btn-sm button-custom"
  //         >
  //           Continue
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );
}
