import React, { useContext, useEffect, useReducer } from "react";
import base64 from "base-64";
import $f from "../components/lib";

const Context = React.createContext();

export const useAuth = () => useContext(Context);

const INITIAL_STATE = {
  isAuth: false,
  authUser: undefined,
  initialized: false,
  loginError: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZED": {
      const { isAuth, authUser } = action.payload;
      return {
        ...state,
        isAuth,
        authUser,
        initialized: true,
      };
    }
    case "LOGIN": {
      const { authUser } = action.action;
      return {
        ...state,
        isAuth: true,
        authUser,
        initialized: true,
        loginError: null,
      };
    }
    case "FAILED_LOGIN": {
      const { loginError } = action.payload;
      return {
        ...state,
        isAuth: false,
        initialized: true,
        loginError,
      };
    }
    case "USER_UPDATE": {
      const { authUser } = action.payload;
      return {
        ...state,
        authUser,
      };
    }
    default: {
      return state;
    }
  }
};

export default function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, INITIAL_STATE);

  const onLogin = (payload) => {
    const { username, password } = payload;

    $f.httpApiCall({
      url: "/api/auth/login",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    })
      .then((resp) => {
        localStorage.setItem("jwt", resp.token);
        localStorage.setItem(
          "info",
          base64.encode(
            JSON.stringify({
              userId: resp.userId,
              username: resp.username,
              name: resp?.name,
              surname: resp?.surname,
              admin: resp?.admin,
            })
          )
        );

        dispatch({
          type: "INITIALIZED",
          payload: {
            authUser: {
              userId: resp.userId,
              username: resp.username,
              name: resp?.name,
              surname: resp?.surname,
              admin: resp?.admin,
            },
          },
        });

        if ($f.getParameterByName("redirect")) {
          window.location.replace($f.getParameterByName("redirect"));
        } else {
          window.location.replace("/");
        }
      })
      .catch((e) => {
        if (e?.ERROR_TAG === "WRONG_CREDS") {
          $f.createNotification({
            message: "Wrong email or password",
            type: "danger",
          });
        } else {
          $f.createNotification({
            message: "Something went wrong!",
            type: "danger",
          });
        }
      });
  };

  const onRegister = (payload) => {};

  const readFromStorage = () => {
    const isAuth = localStorage.getItem("jwt") ? true : false;
    const authUser = isAuth
      ? JSON.parse(base64.decode(localStorage.getItem("info")))
      : undefined;

    dispatch({
      type: "INITIALIZED",
      payload: { isAuth, authUser },
    });
  };

  useEffect(() => {
    readFromStorage();
  }, []);

  const onUpdateUser = ({ name, surname }) => {
    localStorage.setItem(
      "info",
      base64.encode(
        JSON.stringify({
          ...state.authUser,
          name,
          surname,
        })
      )
    );
    dispatch({
      type: "USER_UPDATE",
      payload: {
        authUser: {
          ...state.authUser,
          name,
          surname,
        },
      },
    });
  };

  const contextValue = { ...state, onLogin, onRegister, onUpdateUser };

  return state.initialized ? (
    <Context.Provider value={contextValue}>{children}</Context.Provider>
  ) : (
    ""
  );
}
