import React from "react";
import Card from "../../../../components/Card";
import { Link } from "react-router-dom";

export default function InboxEmail({ email, resendLink }) {
  return (
    <div className="d-flex align-items-center justify-content-center flex-fill min-vh-100 py-2">
      <Card
        style={{ borderRadius: 10 }}
        className="card p-2 h-100 "
        bodyClassName="my-2 pb-0"
      >
        <div className="h-75 d-flex align-items-center justify-content-center ">
          <div className="d-flex px-3 flex-fill flex-column">
            <div className="d-flex  align-items-center justify-content-center ">
              <img src="/images/purple.png" height={80} width={80} />
            </div>
            <div className="p-3 text-center">
              <h4 className="fw-bold mb-0 mb-2">Check your inbox</h4>
              <small className="text-muted">
                <div className="lh-sm">
                  Click on the link we sent to <b>{email}</b> <br />
                  to finish your account setup.
                </div>
              </small>
            </div>
            <div className="pt-5 d-flex flex-column text-center">
              <small className="text-muted">
                No email in your inbox or spam folder?
                <span
                  onClick={resendLink}
                  style={{ color: "#4F50C7", cursor: "pointer" }}
                >
                  {` Let’s Resend it`}
                </span>
              </small>
              <small className="text-muted mt-2 mb-2">
                Wrong email address?
                <span style={{ color: "#4F50C7" }}>
                  <Link to="/login"> Go to login page</Link>
                </span>
              </small>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
