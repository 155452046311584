import React from "react";
import Card from "../../components/Card";
import Header from "../../components/Header";
import $f from "../../components/lib";
import TagInfo from "./TagInfo";
import Queries from "./Queries";
import { useParams } from "react-router-dom";
import VisitsByCountry from "./VisitsByCountry";
import {
  fetchGoogleReportsForPage,
  fetchGoogleReportsForSite,
} from "../../apis/reports";
import { useQuery } from "react-query";
import { fetchPageInfo, fetchSite } from "../../apis/sites";
import MissingTags from "./MissingTags";
import ProjectSummary from "./ProjectSummary";
import Keywords from "./Keywords";
import { Link } from "react-router-dom";

const InfoCard = ({ title, value, error, loading, errorMessage }) => {
  return (
    <Card
      style={{
        borderRadius: 15,
        ...(loading ? { maxHeight: 150 } : {}),
      }}
      error={error}
      loading={loading}
      errorMessage={errorMessage}
      title={title}
      className="card h-100"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
    >
      <div className="d-flex flex-column justify-content-between flex-wrap flex-fill">
        <h1 className="pt-3">{value}</h1>
      </div>
    </Card>
  );
};

export default function Reports() {
  const { siteId, pageId } = useParams();

  const { data: site, isSuccess } = useQuery(["site", { siteId }], fetchSite);

  const hasGoogleApiKey = isSuccess && site?.googleJsonFileName !== null;

  const googlefetch = pageId
    ? fetchGoogleReportsForPage
    : fetchGoogleReportsForSite;

  const {
    data: googleData,
    isLoading,
    isError,
    error,
  } = useQuery(
    [pageId ? "googlePageReports" : "googleSiteReports", { siteId, pageId }],
    googlefetch,
    {
      enabled: hasGoogleApiKey,
    }
  );

  const {
    data: page,
    isLoading: isPageLoading,
    isError: isPageError,
    error: pageError,
  } = useQuery(["pageInfo", { pageId }], fetchPageInfo, {
    enabled: pageId !== undefined,
  });

  return (
    <>
      <Header
        style={{ marginBottom: 30 }}
        title={site?.name || "-"}
        description={
          <>
            <span style={{ color: "#4F50C7" }}>
              <Link to={`/site/${siteId}`}>Go to site</Link>
            </span>{" "}
            | Reports
          </>
        }
      />
      <div>
        <div className="row g-3">
          {pageId && (
            <>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <InfoCard
                  loading={isLoading}
                  error={isError}
                  errorMessage={error?.error}
                  title="Clicks"
                  value={googleData?.pageClicks}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <InfoCard
                  loading={isLoading}
                  error={isError}
                  errorMessage={error?.error}
                  title="Impressions"
                  value={googleData?.impressions}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <InfoCard
                  loading={isLoading}
                  error={isError}
                  errorMessage={error?.error}
                  title="CTR"
                  value={googleData?.pageCTR.toFixed(2).concat("%")}
                />
              </div>
            </>
          )}
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            {pageId ? <TagInfo /> : <MissingTags />}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <Queries
              data={googleData?.queries}
              loading={isLoading}
              error={isError}
              errorMessage={error?.error}
              hasGoogleApiKey={hasGoogleApiKey}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <VisitsByCountry
              data={googleData?.visits}
              loading={isLoading}
              error={isError}
              errorMessage={error?.error}
              hasGoogleApiKey={hasGoogleApiKey}
            />
          </div>
          {pageId ? (
            <div className="col-12">
              <Card
                title="Page Summary"
                style={{ borderRadius: 15 }}
                loading={isPageLoading}
                error={pageError?.error}
                errorMessage={isPageError}
                className="card h-100"
                bodyClassName="card-body d-flex flex-column flex-fill h-100"
              >
                {!isPageLoading && (
                  <>
                    <h4 className="display-5 mb-3 mt-4 strong">SEO Issues</h4>
                    <div className="mb-2">{$f.seoIssuesCard(page)}</div>
                    <hr></hr>
                    <h4 className="display-5 mb-3 strong">Basic Meta tags</h4>
                    <div className="mb-2">{$f.ogSummaryCard(page)}</div>
                    <hr></hr>
                    <h4 className="display-5 mb-3 strong">OG Meta tags</h4>
                    <div className="mb-2">{$f.ogSummaryCard(page)}</div>
                    <hr></hr>
                    <h4 className="display-5 mb-3 strong">Twitter Meta tags</h4>
                    <div className="mb-2">{$f.twitterSummaryCard(page)}</div>
                  </>
                )}
              </Card>
            </div>
          ) : (
            <>
              <div className="col-12">
                <Keywords />
              </div>
              <div className="col-12">
                <ProjectSummary />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
