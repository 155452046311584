import React, { useState } from "react";
import Card from "../../../components/Card";
import ModalDialog from "../../../components/ModalDialog";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  fetchSite,
  fetchSitePages,
  finalizeImportPages,
} from "../../../apis/sites";
import { useHistory } from "react-router-dom";
import $f from "../../../components/lib";
import LoadingOverlayScreen from "../../../components/LoadingOverlayScreen";

export default function FinalizeImport({ finalizedData, siteName, setStatus }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { siteId } = useParams();
  const { data: pages } = useQuery({
    queryKey: ["sitePages", { page: 0, siteId }],
    queryFn: fetchSitePages,
  });

  const { mutate: finalize, status: finalizeStatus } = useMutation(
    finalizeImportPages,
    {
      throwOnError: true,
      onError: (err) => {
        $f.createNotification({
          message: "Something went wrong.Please try again later.",
          type: "danger",
        });
      },
      onSuccess: (res) => {
        $f.createNotification({
          message:
            "Pages are uploading..it may take up to 24 hours to view your pages in your list.",
          type: "info",
        });
        history.push(`/site/${siteId}/pages`);
      },
    }
  );

  const currentPages = pages?.pagination?.total;
  const pagesAfterImport = currentPages + finalizedData?.totalPages;

  const cancel = () => {
    history.replace(window.location.pathname);
    setStatus("METHOD_SELECTION");
  };

  const importPages = () => {
    const method = $f.getParameterByName("method");
    finalize({
      siteId,
      sitemapMethod: method === "IMPORT_SITEMAP",
      urls: finalizedData?.pages,
    });
  };

  return (
    <>
      <ModalDialog
        onHide={() => {
          setOpen(false);
        }}
        open={open}
        size="lg"
        closeButton={true}
        title="Pages to be imported"
      >
        <div
          className="card light-stroke p-2 scrollable"
          style={{ height: 250, overflow: "auto" }}
        >
          {finalizedData?.pages?.map((p) => {
            return <div>{p}</div>;
          })}
        </div>
      </ModalDialog>
      {finalizeStatus === "loading" && <LoadingOverlayScreen />}
      <div className=" d-flex flex-column justify-content-center align-items-center flex-fill min-vh-100 w-100 bg-white ">
        <div>
          <div className="text-center">
            <b className="m-0" style={{ color: "black", fontSize: 26 }}>
              Finalize import to {siteName}
            </b>
          </div>
        </div>
        <div
          className="d-flex mt-4 flex-column justify-content-center"
          style={{ width: "75vw" }}
        >
          <Card style={{ borderRadius: 10 }}>
            <div className="d-flex flex-row flex-fill justify-content-between flex-wrap gap-4">
              <div className="d-flex flex-column">
                <div>
                  <div>
                    <b>Billing details</b>
                  </div>
                  <div style={{ fontSize: 14, color: "#5E5E5E" }}>
                    Your bill is determined by the number of rendered pages.
                    <span style={{ color: "#4F50C7", cursor: "pointer" }}>
                      {" "}
                      View how cost is calculated
                    </span>
                  </div>
                </div>
                <hr />
                <div>
                  <div style={{ display: "inline" }}>
                    <b style={{ fontSize: 22, color: "#5E5E5E" }}>
                      You are about to import {finalizedData?.totalPages}{" "}
                      page(s) in your site
                    </b>
                    <span
                      className="ms-2"
                      style={{ color: "#4F50C7", cursor: "pointer" }}
                      onClick={() => setOpen(true)}
                    >
                      View pages
                    </span>
                  </div>
                  <div
                    className="mt-2"
                    style={{ color: "#5E5E5E", fontSize: 15 }}
                  >
                    {`After the import you will reach ${pagesAfterImport} pages in total from ${currentPages} that you have now.`}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-fill align-items-end">
                <Card
                  className="card p-0 h-100 d-flex w-100 w-sm-75 w-lg-75 w-md-75 w-xl-75"
                  bodyClassName="card-body d-flex flex-column flex-fill h-100 m-0 p-0"
                  style={{ borderRadius: 10 }}
                >
                  <div
                    className="px-3 py-2"
                    style={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: 10,
                      borderBottom: "1px solid #E7E7E7",
                    }}
                  >
                    <div>
                      <b>Your montly estimated bill</b>
                    </div>
                    <div>
                      <b style={{ fontSize: 28 }}>
                        ${finalizedData?.totalEstimation}
                      </b>
                    </div>
                  </div>
                  <ul className="list-group list-group-flush px-4">
                    <li className="list-group-item px-0">
                      <div className="d-flex flex-column">
                        <div>
                          <b style={{ color: "#5E5E5E" }}>
                            What you pay today for:
                          </b>
                        </div>
                        <div
                          style={{ color: "#5E5E5E" }}
                          className="d-flex flex-row justify-content-between mt-1"
                        >
                          <div>${currentPages} rendered pages</div>
                          <div>
                            <b style={{ color: "black" }}>
                              ${finalizedData?.currentBilling}
                            </b>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item px-0">
                      <div className="d-flex flex-column">
                        <div>
                          <b style={{ color: "#5E5E5E" }}>
                            Expected bill after import for:
                          </b>
                        </div>
                        <div
                          style={{ color: "#5E5E5E" }}
                          className="d-flex flex-row justify-content-between mt-1"
                        >
                          <div>{pagesAfterImport} rendered pages</div>
                          <div>
                            <b style={{ color: "black" }}>
                              ${finalizedData?.billingAfterImport}
                            </b>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item px-0 pt-3 pb-4 mb-2">
                      <div
                        style={{ color: "black" }}
                        className="d-flex flex-row justify-content-between mt-1"
                      >
                        <div>
                          <b>Estimated total</b>
                        </div>
                        <div>
                          <b style={{ color: "black" }}>
                            ${finalizedData?.totalEstimation}
                          </b>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </div>
            </div>
          </Card>
          <div className="container d-flex justify-content-end gap-2 mt-4 mb-4 p-0  ">
            <button
              type="button"
              className="btn hoverable-lightgray text-grayblack border bg-white btn-sm button-custom"
              onClick={cancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={importPages}
              class="btn btn-primary btn-sm button-custom"
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
