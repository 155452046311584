import React from "react";
import $f from "../lib";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function Table({
  headers,
  data,
  footer,
  rowClassName = () => "",
}) {
  return (
    <PerfectScrollbar>
      <table className="table table-hover">
        <thead>
          {headers?.map((el) => {
            return (
              <th key={el.id} {...el.th}>
                {el.label}
              </th>
            );
          })}
        </thead>
        <tbody>
          {data?.map((row) => {
            return (
              <tr className={rowClassName(row)} key={$f.uuid()}>
                {headers?.map((cell) => {
                  return (
                    <td key={cell.id} {...cell.td}>
                      {cell.formatValue ? cell.formatValue(row) : row[cell.id]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {footer}
        </tbody>
      </table>
    </PerfectScrollbar>
  );
}
