import React, { useState, useEffect } from "react";
import ModalDialog from "../../ModalDialog";
import $f from "../../lib";
import { fetchSite } from "../../../apis/sites";
import { useQuery } from "react-query";

function GenerateScript({ siteId }) {
  const [project, setProject] = useState([]);

  const { isLoading, isError } = useQuery(["site", { siteId }], fetchSite, {
    onSuccess: (res) => {
      setProject(res);
    },
    onError: (err) => {
      $f.createNotification({
        message: "Something went wrong. Please try again later.",
        type: "danger",
      });
      hide();
    },
  });

  const hide = () => {
    window["modal"].setState({ show: false });
  };

  return (
    <ModalDialog
      open={true}
      title="Project scripts"
      size={"lg"}
      loading={isLoading}
      buttons={[
        {
          title: "Cancel",
          onClick: () => {
            hide();
          },
          className:
            "btn hoverable-lightgray text-grayblack border bg-white btn-sm",
        },
      ]}
    >
      <div className="pb-4">
        Please use one of the below methods to activate your subscription
      </div>
      <nav className="pb-2">
        <div className="nav nav-tabs">
          <span
            className="nav-link active"
            data-bs-toggle="tab"
            data-bs-target="#nav-htaccess"
            role="button"
          >
            .htaccess File
          </span>
          <span
            className="nav-link"
            data-bs-toggle="tab"
            data-bs-target="#nav-php"
            role="button"
          >
            PHP script
          </span>
          <span
            className="nav-link"
            data-bs-toggle="tab"
            data-bs-target="#nav-cloudflare"
            role="button"
          >
            Cloudflare
          </span>
        </div>
      </nav>
      <div className="tab-content mt-2">
        <div className="tab-pane fade show active" id="nav-htaccess">
          <div className="alert alert-warning border" role="alert">
            <i className="fas fa-exclamation-triangle me-2"></i>This method
            requires that your apache server has the{" "}
            <strong>SSLProxyEngine On</strong> and allow the <strong>P</strong>{" "}
            flag to be executed in the <strong>.htaccess</strong> file
          </div>
          <div className="alert alert-warning border" role="alert">
            <i className="fas fa-exclamation-triangle me-2"></i>The below code
            excpect that the default page of your website is{" "}
            <strong>index.html</strong> or <strong>index.php</strong> and all
            internal pages are redirected from these pages. If you don't know
            the default page please contact the support team.
          </div>
          <div className="alert alert-warning border" role="alert">
            <i className="fas fa-exclamation-triangle me-2"></i>In case you are
            using this method in your website that is constructed using web
            frameworks such as Wordpress, Drupal etc., keep in mind that you
            have to ensure that this code exists, anytime you update your
            framework.
          </div>
          <div className="alert alert-info border" role="alert">
            <i className="fas fa-info me-2"></i>Please add the below
            configuration inside your htaccess file as higher as possible. Keep
            in mind that if your htaccess file contain rules that redirect the
            page to the appropriate path (such as non-www to www version or http
            to https version e.x.), then the below script should be placed under
            those rules.{" "}
            <strong>
              Please ensure that your website is running correctly after you
              made the changes! It is strongly advised to take a backup of the
              specific file before you do any changes!
            </strong>
          </div>
          <div className="border p-2">
            <code>
              &lt;IfModule mod_rewrite.c&gt; <br></br>
              RewriteEngine On
              <br></br>
              &lt;IfModule mod_proxy_http.c&gt;<br></br>
              RewriteCond %&#123;REQUEST_URI%&#125;{" "}
              ^(?!.*?(\.js|\.css|\.xml|\.less|\.png|\.jpg|\.jpeg|\.gif|\.pdf|\.doc|\.txt|\.ico|\.rss|\.zip|\.mp3|\.rar|\.exe|\.wmv|\.doc|\.avi|\.ppt|\.mpg|\.mpeg|\.tif|\.wav|\.mov|\.psd|\.ai|\.xls|\.mp4|\.m4a|\.swf|\.dat|\.dmg|\.iso|\.flv|\.m4v|\.torrent|\.ttf|\.woff|\.svg))
              <br></br>
              RewriteCond %&#123;HTTP_USER_AGENT&#125;{" "}
              ".*(googlebot|google|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora|lighthouse).*"
              [NC] <br></br>
              RewriteRule ^(index\.html|index\.php)?(.*)
              https://api.12monkeys.io/public/utils/updatedUID?projectuid=
              {project.uuid}
              &pageURL=%&#123;REQUEST_SCHEME&#125;://%&#123;HTTP_HOST&#125;/$2
              [P,END] <br></br>&lt;/IfModule&gt;<br></br> &lt;/IfModule&gt;
            </code>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-php">
          <div className="alert alert-warning border" role="alert">
            <i className="fas fa-exclamation-triangle me-2"></i>Be careful the
            below code start and ends with the PHP tags{" "}
            <strong>"&lt;?php ... ?&gt;"</strong> and should be treated
            appropriately. Ask your website developer to add it.
          </div>
          <div className="alert alert-warning border" role="alert">
            <i className="fas fa-exclamation-triangle me-2"></i>Ensure that your
            php version allows and runs <strong>PHP curl (curl_init)</strong>{" "}
            and the <strong>pathinfo</strong>
          </div>
          <div className="alert alert-warning border" role="alert">
            <i className="fas fa-exclamation-triangle me-2"></i>In case you are
            using this method in your website that is constructed using web
            frameworks such as Wordpress, Drupal etc., keep in mind that you
            have to ensure that this code exists, anytime you update your
            framework.
          </div>
          <div className="alert alert-info border" role="alert">
            <i className="fas fa-info me-2"></i> This method is more appropriate
            for PHP websites (e.x. <strong>Wordpress</strong>,{" "}
            <strong>Drupal</strong>) in case you cannot use the htaccess file.
            Please add the below php code on the top of your index.php file.{" "}
            <strong>
              Please ensure that your website is running correctly after you
              made the changes! It is strongly advised to take a backup of the
              specific file before you do any changes!
            </strong>
          </div>
          <div className="border p-2">
            <code>
              &lt;?php<br></br>
              $urlApi =
              "https://api.12monkeys.io/public/utils/updatedUID?projectuid=
              {project.uuid}&pageURL=".(isset($_SERVER['HTTPS']) &&
              $_SERVER['HTTPS'] === 'on' ? "https" :
              "http")."://$_SERVER[HTTP_HOST]$_SERVER[REQUEST_URI]";
              <br></br>
              if
              ((preg_match('/googlebot|google|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora|lighthouse/i',
              $_SERVER['HTTP_USER_AGENT'])) &&
              (!preg_match('/\.js|\.css|\.xml|\.less|\.png|\.jpg|\.jpeg|\.gif|\.pdf|\.doc|\.txt|\.ico|\.rss|\.zip|\.mp3|\.rar|\.exe|\.wmv|\.doc|\.avi|\.ppt|\.mpg|\.mpeg|\.tif|\.wav|\.mov|\.psd|\.ai|\.xls|\.mp4|\.m4a|\.swf|\.dat|\.dmg|\.iso|\.flv|\.m4v|\.torrent|\.ttf|\.woff|\.svg/i',pathinfo(parse_url($urlApi,
              PHP_URL_PATH), PATHINFO_EXTENSION)))) &#123;<br></br>
              $ch = curl_init();<br></br>
              curl_setopt($ch, CURLOPT_URL, $urlApi);
              <br></br>
              curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
              <br></br>
              curl_setopt($ch, CURLOPT_TIMEOUT, 3);<br></br>
              curl_setopt($ch, CURLOPT_CONNECTTIMEOUT, 100);
              <br></br>
              curl_setopt($ch, CURLOPT_HEADER, 0);<br></br>
              $ch_resp = curl_exec($ch);<br></br>
              if(curl_getinfo($ch, CURLINFO_HTTP_CODE)=="200")&#123;
              <br></br>
              echo $ch_resp;<br></br>
              curl_close($ch);<br></br>
              exit();<br></br>
              &#125;else&#123;<br></br>
              curl_close($ch);<br></br>
              &#125;<br></br>
              &#125;<br></br>
              ?&gt;
            </code>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-cloudflare">
          <div className="alert alert-info border" role="alert">
            <h4>Cloudflare installation method</h4>
            <i className="fas fa-info me-2"></i> This method is used if you have
            a cloudflare service infront of your website.<br></br>
            <br></br>
            <strong>
              Please ensure that your website is running correctly after you
              made the changes!
            </strong>
            <br></br>
            <br></br>
            <strong>
              It is highly reccomended to request help from your dev team in
              order to apply the script
            </strong>
          </div>
          <div className="p-2">
            <div>
              1. Login to your cloudflare<br></br>
              <br></br>
            </div>

            <div>
              {`2. From the left menu go to Wokers --> Overview, to create a
              worker`}
            </div>
            <div>
              <img src="/images/cloudflare1.jpg" className="img-fluid"></img>
              <br></br>
              <br></br>
            </div>
            <div>3. Press Create a Service</div>
            <div>
              <img src="/images/cloudflare2.jpg" className="img-fluid"></img>
              <br></br>
              <br></br>
            </div>
            <div>
              4. Select HTTP router and press Create Service. You will navigate
              to the detail page of the worker
            </div>
            <div>
              <img src="/images/cloudflare3.jpg" className="img-fluid"></img>
              <br></br>
              <br></br>
            </div>
            <div>5. Click on Quick edit button</div>
            <div>
              <img src="/images/cloudflare4.jpg" className="img-fluid"></img>
              <br></br>
              <br></br>
            </div>
          </div>
          <div>
            6. Paste the code below on the left panel and then click Save and
            deploy
          </div>
          <div className="border p-2">
            <code>
              const CRAWL_AGENTS = &#91;<br></br>
              'googlebot',<br></br>
              'yahoo! slurp',<br></br>
              'bingbot',<br></br>
              'yandex',<br></br>
              'baiduspider',<br></br>
              'facebookexternalhit',<br></br>
              'twitterbot',<br></br>
              'rogerbot',<br></br>
              'linkedinbot',<br></br>
              'embedly',<br></br>
              'quora link preview',<br></br>
              'pinterest/0.',<br></br>
              'developers.google.com/+/web/snippet',<br></br>
              'google page speed',<br></br>
              'qwantify',<br></br>
              'pinterestbot',<br></br>
              'chrome-lighthouse',<br></br>
              'lighthouse',<br></br>
              'speed'<br></br>
              &#93;;
              <br></br>
              <br></br>
              const FILE_EXT = &#91;<br></br>
              '.js',<br></br>
              '.css',<br></br>
              '.xml',<br></br>
              '.less',<br></br>
              '.png',<br></br>
              '.jpg',<br></br>
              '.jpeg',<br></br>
              '.gif',<br></br>
              '.pdf',<br></br>
              '.doc',<br></br>
              '.txt',<br></br>
              '.ico',<br></br>
              '.rss',<br></br>
              '.zip',<br></br>
              '.mp3',<br></br>
              '.rar',<br></br>
              '.ppt',<br></br>
              '.mpg',<br></br>
              '.mpeg',<br></br>
              '.tif',<br></br>
              '.wav',<br></br>
              '.mov',<br></br>
              '.psd',<br></br>
              '.ai',<br></br>
              '.xls',<br></br>
              '.exe',<br></br>
              '.wmv',<br></br>
              '.doc',<br></br>
              '.avi',<br></br>
              '.mp4',<br></br>
              '.m4a',<br></br>
              '.swf',<br></br>
              '.dat',<br></br>
              '.dmg',<br></br>
              '.iso',<br></br>
              '.flv',<br></br>
              '.m4v',<br></br>
              '.torrent',<br></br>
              '.woff',<br></br>
              '.ttf',<br></br>
              '.svg',<br></br>
              '.webmanifest'<br></br>
              &#93;;
              <br></br>
              <br></br>
              addEventListener('fetch', event =&gt; &#123;<br></br>
              const &#123;<br></br>
              request<br></br>
              &#125; = event;<br></br>
              const url = new URL(request.url);<br></br>
              const &#123;<br></br>
              hostname<br></br>
              &#125; = url;<br></br>
              const requestUserAgent = (request.headers.get('User-Agent') ||
              '').toLowerCase();<br></br>
              const pathName = url.pathname.toLowerCase();<br></br>
              const ext = pathName.substring(pathName.lastIndexOf('.') ||
              pathName.length);<br></br>
              if (containsOneOfThem(CRAWL_AGENTS, requestUserAgent) &&
              !isOneOfThem(FILE_EXT, ext)) &#123;<br></br>
              <br></br>
              event.respondWith(fetchPage(request));<br></br>
              &#125;<br></br>
              <br></br>
              &#125;)<br></br>
              <br></br>
              <br></br>
              async function gatherResponse(response) &#123;<br></br>
              return response;<br></br>
              &#125;<br></br>
              <br></br>
              <br></br>
              function isOneOfThem(array, element) &#123;<br></br>
              return array.some(e =&gt; e === element);<br></br>
              &#125;<br></br>
              <br></br>
              <br></br>
              function containsOneOfThem(array, element) &#123;<br></br>
              return array.some(e =&gt; element.indexOf(e) !== -1);<br></br>
              &#125;<br></br>
              <br></br>
              <br></br>
              async function fetchPage(request) &#123;<br></br>
              const &#123;<br></br>
              url,<br></br>
              headers<br></br>
              &#125; = request;<br></br>
              const prerenderUrl =
              `https://api.12monkeys.io/public/utils/updatedUID?projectuid=
              {project.uuid}&pageURL=$&#123;url&#125;`;
              <br></br>
              const headersToSend = new Headers(headers);<br></br>
              <br></br>
              <br></br>
              const prerenderRequest = new Request(prerenderUrl, &#123;<br></br>
              headers: headersToSend,<br></br>
              redirect: 'manual',<br></br>
              &#125;);<br></br>
              <br></br>
              <br></br>
              const response = await fetch(prerenderRequest);<br></br>
              const results = await gatherResponse(response);<br></br>
              <br></br>
              <br></br>
              if (results.status === 200) &#123;<br></br>
              return response;<br></br>
              &#125; else &#123;<br></br>
              return fetch(request);<br></br>
              &#125;<br></br>
              &#125;<br></br>
              <br></br>
            </code>
          </div>
          <br></br>
          <br></br>
          <div>
            7. Go back to the cloudflare homepage of your account and press
            websites
          </div>
          <div>
            <img src="/images/cloudflare5.jpg" className="img-fluid"></img>
            <br></br>
            <br></br>
          </div>
          <div>
            8. Select the website you want to apply the worker<br></br>
            <br></br>
          </div>
          <div>
            9. Click Workers routes<br></br>
            <br></br>
          </div>
          <div>
            <img src="/images/cloudflare6.jpg" className="img-fluid"></img>
            <br></br>
            <br></br>
          </div>
          <div>
            10. Click Add route<br></br>
            <br></br>
          </div>
          <div>
            <img src="/images/cloudflare7.jpg" className="img-fluid"></img>
            <br></br>
            <br></br>
          </div>
          <div>
            11. In the route field type <br></br>
            <strong>*.&lt;your domain name&gt;/*</strong> <br></br>in the
            service select the service name of the previous worker <br></br>in
            the environment select production <br></br>
          </div>
          <div>
            <br></br>
            Your script has been deployed! Please ensure that your website works
            as expectd!<br></br>
            <br></br>
          </div>
          <div className="alert alert-info border" role="alert">
            <h4>Unistall cloudflare script</h4>
            <i className="fas fa-info me-2"></i> In order to unistall the
            cloudflare script please follow the below instructions<br></br>
            <br></br>
            <strong>
              Please ensure that your website is running correctly after you
              made the changes!
            </strong>
            <br></br>
            <br></br>
            <strong>
              It is highly reccomended to request help from your dev team in
              order to apply the script
            </strong>
            <br></br>
            <br></br>
          </div>
          <div>
            1. Login to your cloudflare account <br></br>
            <br></br>
          </div>
          <div>
            2. Select the website you want to unistall the script <br></br>
            <br></br>
          </div>
          <div>
            3. From the left menu click Worker Routes <br></br>
            <br></br>
          </div>
          <div>
            4. Click Edit on the route you have set the script <br></br>
            <br></br>
          </div>
          <div>
            <img src="/images/cloudflare8.jpg" className="img-fluid"></img>
            <br></br>
            <br></br>
          </div>
          <div>
            5. Press remove to remove the specific route <br></br>
            <br></br>
          </div>
          <div>
            <img src="/images/cloudflare9.jpg" className="img-fluid"></img>
            <br></br>
            <br></br>
          </div>
          <div>
            6. Confirm the removal <br></br>
            <br></br>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
}
export default GenerateScript;
