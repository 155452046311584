import React from "react";
import Card from "../../../components/Card";
import { useHistory } from "react-router-dom";
import AddNewSiteModal from "../../../components/WithModal/AddNewSite";
import { fetchOwnerSites } from "../../../apis/sites";
import { useQuery } from "react-query";

const isCompleted = (item) => {
  return (
    item.googleJsonFileName !== null &&
    item.verified === true &&
    item.totalPages > 0 &&
    item.scriptEnabled === true
  );
};

export default function Checklist() {
  const { data, isLoading, isError } = useQuery(
    ["ownerSites"],
    fetchOwnerSites
  );

  const history = useHistory();
  const more =
    data?.map((item) => {
      return {
        label: "Pending tasks in ".concat(item.name),
        buttonLabel: "View",
        canISeeButton: true,
        completed: isCompleted(item),
        action: () => {
          history.push(`/site/${item.projectId}`);
        },
      };
    }) ?? [];

  const list = [
    {
      label: "Add your first site",
      buttonLabel: "Let's go",
      canISeeButton: data?.length === 0,
      completed: data?.length > 0,
      action: () => {
        window["modal"].setState({
          show: true,
          body: <AddNewSiteModal />,
        });
      },
    },
    ...more.filter((item) => !item.completed),
  ];

  return (
    <Card
      error={isError}
      loading={isLoading}
      className="card ps-2 pe-2 pt-1 pb-2 h-100"
      style={{ maxHeight: 500, borderRadius: 15 }}
      title="Checklist"
      bodyClassName="card-body d-flex flex-column flex-fill h-100"
    >
      <div className="d-flex flex-fill mt-2">
        <ul className="list-group list-group-flush d-flex flex-fill">
          {list.map((item) => {
            const _classNameIcon = item.completed
              ? "fas fa-check-circle text-success"
              : "fas fa-exclamation-triangle text-warning";

            return (
              <li className="list-group-item ps-0 py-3">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div>
                    <i className={_classNameIcon} />
                    <span className="ms-2">{item.label}</span>
                  </div>
                  {item.canISeeButton && (
                    <div>
                      <button
                        type="button"
                        style={{ borderRadius: 15 }}
                        onClick={item.action}
                        className="btn hoverable-lightgray text-grayblack border bg-white btn-sm  py-1"
                      >
                        {item.buttonLabel}
                      </button>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Card>
  );
}
